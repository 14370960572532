import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Play,
  Square,
  RotateCcw,
  Plus,
  Trash2,
  BookOpen,
  Edit,
  Menu,
  X,
} from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import "./styles.css";
import { Midi } from "@tonejs/midi";
import { parseMidi } from "midi-file";
import { ChevronUp, ChevronDown } from "lucide-react";
import {
  practiceRoomPosts,
  getAllTags,
  getPostsByTag,
  getPostById,
  DEFAULT_POST_IMAGE,
} from "./data/practiceRoomPosts";
import flowFrameLogo from "./data/FlowFrame Logo.png";
import PracticeTrackerTab from "./PracticeTrackerTab";
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  useAuthState,
} from "firebase/auth";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  doc,
  addDoc,
  updateDoc,
  setDoc,
  serverTimestamp,
  getDoc,
  getDocs,
  collection,
  onSnapshot,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "./firebase";
import { app } from "./firebase";

const userData = {
  subscriptionTier: "free",
  createdAt: Date.now(),
};

const googleProvider = new GoogleAuthProvider();

function canSavePattern(currentPatterns, subscriptionTier) {
  if (subscriptionTier === "pro") {
    return true; // unlimited
  }
  if (subscriptionTier === "basic") {
    return currentPatterns.length < 10;
  }
  // free tier:
  return currentPatterns.length < 3;
}

export function SignUpForm({ onSignUpSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      // userCredential.user is the newly created user
      const user = userCredential.user;
      console.log("Signed up as:", user.uid);

      // 1) Create Firestore doc for this user
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        subscriptionTier: "free",
        createdAt: serverTimestamp(),
      });

      // 2) If you have a callback
      if (onSignUpSuccess) {
        onSignUpSuccess(user);
      }
    } catch (err) {
      console.error("Sign-up error:", err);
      setError(err.message);
    }
  }

  return (
    <form onSubmit={handleSubmit} style={{ maxWidth: 300, margin: "0 auto" }}>
      <h2>Sign Up</h2>

      <div style={{ marginBottom: "1rem" }}>
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            style={{
              display: "block",
              width: "100%",
              padding: "8px",
              marginTop: "4px",
              border: "1px solid #aaa",
              borderRadius: "4px",
            }}
          />
        </label>
      </div>

      <div style={{ marginBottom: "1rem" }}>
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            style={{
              display: "block",
              width: "100%",
              padding: "8px",
              marginTop: "4px",
              border: "1px solid #aaa",
              borderRadius: "4px",
            }}
          />
        </label>
      </div>

      {error && <p style={{ color: "red" }}>Error: {error}</p>}

      <button type="submit">Create Account</button>
    </form>
  );
}

const TUTORIAL_STEPS = [
  {
    id: "welcome",
    title: "Welcome to FlowFrame!",
    content:
      'Let\'s take a quick tour of the key features. Click "Next" to begin.',
    position: "center",
  },
  {
    id: "create-measure",
    title: "Creating Measures",
    content:
      'In the Pattern Overview box, click "+" to add a new measure. Each measure shows its time signature, which you can adjust using the +/- buttons above and below the numbers.',
    targetSelector: "#pattern-overview", // Change to use an ID
    position: "top",
  },
  {
    id: "ghost-measure",
    title: "Building Your Pattern",
    content:
      "Use the ghost measure to preview and add new measures to your pattern. Adjust the time signature using the +/- buttons, and click 'Add Measure' to append it to your pattern. This helps you build complex rhythmic sequences measure by measure.",
    targetSelector: "#ghost-measure",
    position: "top",
  },
  {
    id: "count-in",
    title: "Count-In Feature",
    content:
      "Use the count-in dropdown in the control panel to add preparation measures before your pattern starts.",
    targetSelector: "#count-in-select", // Change to use an ID
    position: "top",
  },
  {
    id: "start-measure",
    title: "Start Measure",
    content:
      "In the same control panel, set your starting measure number. This is useful when practicing specific sections of your piece.",
    targetSelector: "#startMeasure",
    position: "top",
  },
  {
    id: "bulk-editor",
    title: "Bulk Editor",
    content:
      "The Bulk Editor allows you to quickly modify multiple measures at once. You can set time signatures for entire sections of your pattern.",
    targetSelector:
      "button.px-2.py-1.bg-\\[\\#059DD9\\].text-white.rounded.shadow-md.hover\\:bg-\\[\\#346CBF\\]", // Target the blue Bulk Editor button
    position: "top",
  },
  {
    id: "set-bpm",
    title: "Setting Tempo",
    content:
      "Below the Bulk Editor, set your tempo (BPM) and choose which note value gets the beat.",
    targetSelector: "#tempo-controls", // Change to use an ID
    position: "top",
  },
  {
    id: "menu-options",
    title: "Additional Features",
    content:
      "Click the menu button to access more features like saving patterns, adjusting tuning, exporting MIDI, and logging in to save your work in the cloud.",
    targetSelector: ".p-2.bg-gray-200.border.rounded", // This targets the hamburger menu button
    position: "right",
  },
  {
    id: "tempo-changes",
    title: "Advanced Tempo Control",
    content:
      "Enable Tempo Changes to create sophisticated tempo progressions. You can set linear or exponential changes, create incremental steps, and even define specific measure ranges for tempo variations. Perfect for practicing accelerandos or building speed gradually.",
    targetSelector: "#tempochanges",
    position: "top",
  },
  {
    id: "modulate-loop",
    title: "Automatic Modulation",
    content:
      "With the 'Modulate on loop' feature (Pro), your pattern automatically transposes up or down by your chosen interval each time it loops. This is great for practicing in all keys or building range systematically.",
    targetSelector: "#modulateloop",
    position: "top",
  },
  {
    id: "subdivisions",
    title: "Beat Subdivisions",
    content:
      "Add subdivisions to any beat to create more complex rhythms. You can set the number of subdivisions and accent specific beats within each subdivision.",
    targetSelector: "#subdivisions", // Targets the subdivision count selector
    position: "top",
  },
  {
    id: "drones-harmony",
    title: "Drones and Harmony",
    content:
      "Add drones with just intonation, overtone tunings, and microtones to any beat. Enable partials and custom notes for rich harmonic textures.",
    targetSelector: "label[class='block mb-1 text-xs font-medium mt-2']", // Targets the Drone Pitch label
    position: "top",
  },
  {
    id: "metric-modulation",
    title: "Metric Modulation",
    content:
      "Create smooth tempo transitions between measures by specifying 'From' and 'To' subdivisions. This helps practice complex rhythmic relationships.",
    targetSelector: "#metricmodulation", // Targets the metric modulation controls
    position: "top",
  },
  {
    id: "tuplets",
    title: "Tuplets",
    content:
      "Add tuplets to create polyrhythms and complex rhythmic groupings. Specify which beats the tuplet spans and how many subdivisions it should have.",
    targetSelector: "#tuplets", // Targets the Tuplets heading
    position: "top",
  },
  {
    id: "finish",
    title: "Ready to Begin!",
    content:
      "You've learned the basics! Explore more features in the menu, or start practicing now.",
    position: "center",
  },
];

export function TutorialOverlay({ currentUser, onComplete }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [highlightedElement, setHighlightedElement] = useState(null);

  // Effect to handle highlighting the current step's element
  useEffect(() => {
    const currentTutorialStep = TUTORIAL_STEPS[currentStep];
    if (!currentTutorialStep || currentTutorialStep.position === "center") {
      if (highlightedElement) {
        highlightedElement.classList.remove("tutorial-highlight");
        setHighlightedElement(null);
      }
      return;
    }

    const targetElement = document.querySelector(
      currentTutorialStep.targetSelector
    );
    if (targetElement) {
      if (highlightedElement) {
        highlightedElement.classList.remove("tutorial-highlight");
      }
      targetElement.classList.add("tutorial-highlight");
      setHighlightedElement(targetElement);

      // Add smooth scrolling here, right after setting the highlighted element
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }

    return () => {
      if (highlightedElement) {
        highlightedElement.classList.remove("tutorial-highlight");
      }
    };
  }, [currentStep]);

  useEffect(() => {
    // Check if tutorial has been completed
    const checkTutorialStatus = async () => {
      if (currentUser) {
        // Check Firebase
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        if (userDoc.exists() && userDoc.data().tutorialCompleted) {
          setIsVisible(false);
        }
      } else {
        // Check localStorage
        const tutorialCompleted = localStorage.getItem("tutorialCompleted");
        if (tutorialCompleted === "true") {
          setIsVisible(false);
        }
      }
    };

    checkTutorialStatus();
  }, [currentUser]);

  const handleNext = () => {
    if (currentStep < TUTORIAL_STEPS.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      completeTutorial();
    }
  };

  const handleSkip = () => {
    completeTutorial();
  };

  const completeTutorial = async () => {
    // Remove any existing highlight before completing
    if (highlightedElement) {
      highlightedElement.classList.remove("tutorial-highlight");
    }

    if (currentUser) {
      // Save to Firebase
      await updateDoc(doc(db, "users", currentUser.uid), {
        tutorialCompleted: true,
      });
    } else {
      // Save to localStorage
      localStorage.setItem("tutorialCompleted", "true");
    }

    setIsVisible(false);
    if (onComplete) {
      onComplete();
    }
  };

  if (!isVisible) return null;

  const currentTutorialStep = TUTORIAL_STEPS[currentStep];

  // Calculate the position for the tutorial box
  const getBoxPosition = () => {
    if (currentTutorialStep.position === "center") {
      return "fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2";
    }

    const targetElement = document.querySelector(
      currentTutorialStep.targetSelector
    );
    if (!targetElement) {
      return "fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2";
    }

    const rect = targetElement.getBoundingClientRect();
    const buffer = 20; // Increased buffer for better spacing

    switch (currentTutorialStep.position) {
      case "top":
        return `fixed top-[20px]`;
      case "bottom":
        return `fixed bottom-[20px]`;
      case "left":
        return `fixed left-[20px] top-1/2 -translate-y-1/2`;
      case "right":
        return `fixed right-[20px] top-1/2 -translate-y-1/2`;
      default:
        return "fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2";
    }
  };

  return (
    <>
      {/* Semi-transparent overlay */}
      <div className="fixed inset-0 bg-black bg-opacity-10 z-[9999]" />

      {/* Tutorial box */}
      <div
        className={`
        ${getBoxPosition()}
        z-[10000]
        bg-white
        rounded-lg
        p-6
        max-w-md
        mx-auto
        shadow-xl
        border-2
        border-blue-500
      `}
      >
        {/* Progress indicator */}
        <div className="absolute top-0 left-0 right-0 h-1 bg-gray-200 rounded-t-lg overflow-hidden">
          <div
            className="h-full bg-blue-500 transition-all duration-300 ease-in-out"
            style={{
              width: `${((currentStep + 1) / TUTORIAL_STEPS.length) * 100}%`,
            }}
          />
        </div>

        <h3 className="text-xl font-bold mb-2 mt-2">
          {currentTutorialStep.title}
        </h3>
        <p className="text-gray-600 mb-4">{currentTutorialStep.content}</p>

        {/* Progress text */}
        <div className="text-sm text-gray-500 mb-4">
          Step {currentStep + 1} of {TUTORIAL_STEPS.length}
        </div>

        <div className="flex justify-between items-center">
          <button
            onClick={handleSkip}
            className="text-gray-500 hover:text-gray-700 transition-colors"
          >
            Skip Tutorial
          </button>
          <div className="space-x-2">
            {currentStep > 0 && (
              <button
                onClick={() => setCurrentStep(currentStep - 1)}
                className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
              >
                Back
              </button>
            )}
            <button
              onClick={handleNext}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            >
              {currentStep === TUTORIAL_STEPS.length - 1 ? "Finish" : "Next"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

function getPositionClasses(step) {
  if (step.position === "center") {
    return "relative";
  }

  const targetElement = document.querySelector(step.targetSelector);
  if (!targetElement) {
    return "relative"; // Fallback to center if element not found
  }

  const rect = targetElement.getBoundingClientRect();
  const buffer = 16; // 16px buffer

  // Calculate position based on the target element's position
  let positionClasses = "fixed ";

  switch (step.position) {
    case "top":
      positionClasses += `bottom-[${window.innerHeight - rect.top + buffer}px]`;
      break;
    case "bottom":
      positionClasses += `top-[${rect.bottom + buffer}px]`;
      break;
    case "left":
      positionClasses += `right-[${window.innerWidth - rect.left + buffer}px]`;
      break;
    case "right":
      positionClasses += `left-[${rect.right + buffer}px]`;
      break;
    default:
      return "relative";
  }

  return positionClasses;
}

/**
 * HamburgerActions: A small menu for Save/Load/Import/Export
 */
function HamburgerActions({
  patternName,
  deleteFolder,
  selectedFolderForDeletion,
  setSelectedFolderForDeletion,
  selectedFolder,
  setSelectedFolder,
  setPatternName,
  newFolderName,
  setNewFolderName,
  folderName,
  setFolderName,
  savedPatterns,
  onSavePattern,
  onRenamePattern,
  onLoadPattern,
  onDeletePattern,
  onImportMIDI,
  onExportMIDI,
  onImportMetronomeData, // Callback for importing JSON
  onExportMetronomeData, // Callback for exporting JSON
  aTuning,
  setATuning,
  stressOn,
  setStressOn,
  subscriptionTier,
  handleUpgrade,
  currentUser,
  setCurrentUser,
  handleGoogleSignIn,
  handleSignOut,
  setShowTutorial,
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const [loadSectionOpen, setLoadSectionOpen] = useState(false);
  const [deleteSectionOpen, setDeleteSectionOpen] = useState(false);
  const [importSectionOpen, setImportSectionOpen] = useState(false);
  const [exportSectionOpen, setExportSectionOpen] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showSignUpForm, setShowSignUpForm] = useState(false);
  const [renameSectionOpen, setRenameSectionOpen] = useState(false);
  const [selectedPatternForRename, setSelectedPatternForRename] = useState("");
  const [newPatternName, setNewPatternName] = useState("");
  const [newPatternFolder, setNewPatternFolder] = useState("");

  return (
    <div className="relative inline-block">
      {/* Hamburger Button */}
      <button
        onClick={toggleMenu}
        className="p-2 bg-gray-200 border rounded hover:bg-gray-300"
      >
        {menuOpen ? <X className="w-5 h-5" /> : <Menu className="w-5 h-5" />}
      </button>

      {menuOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-300 rounded shadow-lg z-50 text-sm">
          <div className="px-4 py-2 border-t">
            {!currentUser ? (
              <div className="space-y-2">
                {/* Toggle button: Login */}
                <button
                  onClick={() => setShowLoginForm(!showLoginForm)}
                  className="w-full flex items-center justify-between px-3 py-2 bg-gray-200 hover:bg-gray-300 rounded"
                >
                  <span className="font-medium">Login</span>
                  {showLoginForm ? "▲" : "▼"}
                </button>

                {/* If the user clicked "Login" */}
                {showLoginForm && (
                  <div className="mt-2 space-y-4 p-3 border border-gray-300 rounded bg-gray-50">
                    {/* 1) Email/Password login form */}
                    <LoginForm
                      onLoginSuccess={(user) => {
                        setCurrentUser(user);
                        setShowLoginForm(false);
                        setMenuOpen(false);
                      }}
                    />

                    {/* Small "Don’t have an account?" + Sign Up toggle */}
                    <div className="text-center text-sm text-gray-700">
                      Don’t have an account?{" "}
                      <button
                        onClick={() => setShowSignUpForm(!showSignUpForm)}
                        className="text-blue-600 underline"
                      >
                        {showSignUpForm ? "Hide Sign Up" : "Sign Up"}
                      </button>
                    </div>

                    {/* 2) Sign Up form (only if toggled) */}
                    {showSignUpForm && (
                      <SignUpForm
                        onSignUpSuccess={(user) => {
                          setCurrentUser(user);
                          setShowSignUpForm(false);
                        }}
                      />
                    )}

                    {/* Divider with "OR" before Google Sign-in */}
                    <div className="flex items-center my-2">
                      <div className="flex-1 border-b border-gray-300"></div>
                      <span className="px-2 text-xs text-gray-500">OR</span>
                      <div className="flex-1 border-b border-gray-300"></div>
                    </div>

                    {/* 3) Google sign-in */}
                    <button
                      onClick={handleGoogleSignIn}
                      className="w-full px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded"
                    >
                      Sign in with Google
                    </button>
                  </div>
                )}
              </div>
            ) : (
              /* If user is already logged in, show user info + Logout */
              <div className="flex flex-col items-start space-y-2">
                <p className="text-xs text-gray-700">
                  Logged in as:{" "}
                  <span className="font-semibold">{currentUser.email}</span>
                </p>
                <button
                  onClick={() => handleSignOut(setShowLoginForm)}
                  className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded"
                >
                  Sign Out
                </button>

                <div className="mt-4">
                  <p>
                    Your current tier: <strong>{subscriptionTier}</strong>
                  </p>

                  {subscriptionTier === "free" && (
                    <div className="space-y-2 mt-2">
                      <button onClick={() => handleUpgrade(currentUser)}>
                        Upgrade
                      </button>
                    </div>
                  )}

                  {subscriptionTier === "basic" && (
                    <div className="space-y-2 mt-2">
                      <p>
                        You’re on the Basic plan. Enjoy up to 10 cloud patterns!
                      </p>
                      <button
                        onClick={() => handleUpgrade(currentUser)}
                        className="px-3 py-1 bg-green-600 text-white rounded"
                      >
                        Upgrade to Pro
                      </button>
                    </div>
                  )}

                  {subscriptionTier === "pro" && (
                    <p>You’re on the Pro plan. Enjoy all premium features!</p>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* SAVE PROJECT SECTION */}
          <div className="p-2 border-b">
            <h2 className="font-semibold text-sm mb-2">Save Project</h2>

            {/* Pattern Name */}
            <label className="block font-medium text-xs mb-1">
              Project Name
            </label>
            <input
              type="text"
              value={patternName}
              onChange={(e) => setPatternName(e.target.value)}
              placeholder="Enter a project name..."
              className="border p-1 rounded w-full mb-2 text-sm"
            />

            {/* Folder */}
            <label className="block font-medium text-xs mb-1">Folder</label>
            <input
              type="text"
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
              placeholder="Enter folder name (optional)"
              className="border p-1 rounded w-full mb-2 text-sm"
            />

            {/* Save Button */}
            <button
              onClick={() => {
                onSavePattern();
                setMenuOpen(false);
              }}
              className="block w-full text-left px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded"
            >
              Save Project
            </button>
          </div>

          <div className="px-4 py-2 border-t">
            <button
              onClick={() => setRenameSectionOpen(!renameSectionOpen)}
              className="w-full flex items-center justify-between px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded"
            >
              <span className="font-medium">Rename Pattern</span>
              {renameSectionOpen ? "▲" : "▼"}
            </button>

            {renameSectionOpen && (
              <div className="mt-2">
                {/* Pattern Selection */}
                <select
                  value={selectedPatternForRename}
                  onChange={(e) => {
                    const pattern = savedPatterns.find(
                      (p) => p.id === e.target.value
                    );
                    setSelectedPatternForRename(e.target.value);
                    setNewPatternName(pattern ? pattern.name : "");
                    setNewPatternFolder(pattern ? pattern.folder : "");
                  }}
                  className="border p-1 rounded w-full mb-2"
                >
                  <option value="">Choose a pattern...</option>
                  {savedPatterns.map((sp) => (
                    <option key={sp.id} value={sp.id}>
                      {sp.name} (Folder: {sp.folder || "Uncategorized"})
                    </option>
                  ))}
                </select>

                {/* New Name Input */}
                {selectedPatternForRename && (
                  <>
                    <input
                      type="text"
                      value={newPatternName}
                      onChange={(e) => setNewPatternName(e.target.value)}
                      placeholder="New pattern name..."
                      className="border p-1 rounded w-full mb-2"
                    />

                    {/* Optional: Allow changing folder during rename */}
                    <input
                      type="text"
                      value={newPatternFolder}
                      onChange={(e) => setNewPatternFolder(e.target.value)}
                      placeholder="New folder (optional)..."
                      className="border p-1 rounded w-full mb-2"
                    />

                    <button
                      onClick={() => {
                        onRenamePattern(
                          selectedPatternForRename,
                          newPatternName,
                          newPatternFolder
                        );
                        setMenuOpen(false);
                      }}
                      className="bg-blue-500 text-white px-2 py-1 rounded w-full"
                    >
                      Rename Pattern
                    </button>
                  </>
                )}
              </div>
            )}
          </div>

          {/* LOAD SECTION */}
          {savedPatterns && savedPatterns.length > 0 && (
            <div className="px-4 py-2 border-t">
              <button
                onClick={() => setLoadSectionOpen(!loadSectionOpen)}
                className="w-full flex items-center justify-between px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded"
              >
                <span className="font-medium">Load Project</span>
                {loadSectionOpen ? "▲" : "▼"}
              </button>

              {loadSectionOpen && (
                <>
                  {(() => {
                    // Decide which patterns to display:
                    const displayed = selectedFolder
                      ? savedPatterns.filter(
                          (sp) => sp.folder === selectedFolder
                        )
                      : savedPatterns;

                    return (
                      <div className="mt-2">
                        {/* 1) Choose a project */}
                        <select
                          onChange={(e) => {
                            if (!e.target.value) return;
                            const { name, folder } = JSON.parse(e.target.value);
                            onLoadPattern(name, folder);
                            setMenuOpen(false);
                          }}
                          defaultValue=""
                          className="border p-1 rounded w-full"
                        >
                          <option value="" disabled>
                            Choose a project...
                          </option>

                          {displayed.map((sp) => {
                            // Build a small JSON object with sp’s name+folder
                            const valueObj = {
                              name: sp.name,
                              folder: sp.folder,
                            };
                            return (
                              <option
                                key={sp.id}
                                value={JSON.stringify(valueObj)}
                              >
                                {/* Show sp.name plus sp.folder for clarity */}
                                {sp.name} ({sp.folder})
                              </option>
                            );
                          })}
                        </select>

                        {/* 2) Choose/Create folder */}
                        <div className="mt-3">
                          <select
                            value={selectedFolder}
                            onChange={(e) => setSelectedFolder(e.target.value)}
                            className="border p-1 rounded w-full mb-2"
                          >
                            <option value="">All folders</option>
                            {Array.from(
                              new Set(
                                savedPatterns.map(
                                  (p) => p.folder || "Uncategorized"
                                )
                              )
                            ).map((folder) => (
                              <option key={folder} value={folder}>
                                {folder}
                              </option>
                            ))}
                          </select>

                          <input
                            type="text"
                            placeholder="Type a new folder..."
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                            className="border p-1 rounded mr-2 mb-2"
                          />
                          <button
                            onClick={() => {
                              const trimmed = newFolderName.trim();
                              if (!trimmed) return;
                              setSelectedFolder(trimmed);
                              setNewFolderName("");
                            }}
                            className="bg-blue-500 text-white px-2 py-1 rounded"
                          >
                            Create / Select
                          </button>
                        </div>
                      </div>
                    );
                  })()}
                </>
              )}
            </div>
          )}

          {/* DELETE SECTION */}
          <div className="px-4 py-2 border-t">
            <button
              onClick={() => setDeleteSectionOpen(!deleteSectionOpen)}
              className="w-full flex items-center justify-between px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded"
            >
              <span className="font-medium">Delete Options</span>
              {deleteSectionOpen ? "▲" : "▼"}
            </button>

            {deleteSectionOpen && (
              <div className="mt-2">
                {/* DELETE PROJECT (Pattern) */}
                {savedPatterns && savedPatterns.length > 0 && (
                  <>
                    <label className="font-medium block mb-1">
                      Delete Project
                    </label>
                    <select
                      onChange={(e) => {
                        const chosenId = e.target.value;
                        if (chosenId) {
                          onDeletePattern(chosenId);
                          setMenuOpen(false);
                        }
                      }}
                      defaultValue=""
                    >
                      <option value="">Choose a project...</option>
                      {savedPatterns.map((sp) => (
                        <option key={sp.id} value={sp.id}>
                          {/* Show the user name + folder for clarity */}
                          {sp.name} (Folder: {sp.folder || "Uncategorized"})
                        </option>
                      ))}
                    </select>
                  </>
                )}

                {/* DELETE FOLDER */}
                <div className="mt-3">
                  <label className="block font-medium">
                    Delete Entire Folder
                  </label>
                  <select
                    value={selectedFolderForDeletion}
                    onChange={(e) =>
                      setSelectedFolderForDeletion(e.target.value)
                    }
                    className="border p-1 rounded w-full mb-2"
                  >
                    <option value="">Choose a folder...</option>
                    {Array.from(
                      new Set(
                        savedPatterns.map((p) => p.folder || "Uncategorized")
                      )
                    ).map((folder) => (
                      <option key={folder} value={folder}>
                        {folder}
                      </option>
                    ))}
                  </select>

                  <button
                    onClick={() => {
                      if (!selectedFolderForDeletion) return;
                      const confirmed = window.confirm(
                        `Are you sure you want to delete all projects in folder "${selectedFolderForDeletion}"?`
                      );
                      if (confirmed) {
                        deleteFolder(selectedFolderForDeletion);
                        setMenuOpen(false);
                      }
                    }}
                    className="bg-red-500 text-white px-2 py-1 rounded"
                  >
                    Delete Folder
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* IMPORT SECTION */}
          <div className="px-4 py-2 border-t">
            <button
              onClick={() => setImportSectionOpen(!importSectionOpen)}
              className="w-full flex items-center justify-between px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded"
            >
              <span className="font-medium">Import Options</span>
              {importSectionOpen ? "▲" : "▼"}
            </button>

            {importSectionOpen && (
              <div className="mt-2">
                {/* Import Metronome Data (JSON) */}
                <label
                  htmlFor="importMetronomeData"
                  className="block px-4 py-2 hover:bg-gray-100 cursor-pointer border-t text-sm"
                >
                  Import Metronome Data
                </label>
                <input
                  id="importMetronomeData"
                  type="file"
                  accept=".ff,.json"
                  onChange={(e) => {
                    onImportMetronomeData(e);
                    setMenuOpen(false);
                  }}
                  className="hidden"
                />
              </div>
            )}
          </div>

          {/* EXPORT SECTION */}
          <div className="px-4 py-2 border-t">
            <button
              onClick={() => setExportSectionOpen(!exportSectionOpen)}
              className="w-full flex items-center justify-between px-2 py-1 bg-gray-200 hover:bg-gray-300 rounded"
            >
              <span className="font-medium">Export Options</span>
              {exportSectionOpen ? "▲" : "▼"}
            </button>

            {exportSectionOpen && (
              <div className="mt-2">
                {/* Export MIDI */}
                {/* Export MIDI => Pro Only */}
                {subscriptionTier === "pro" ? (
                  <button
                    onClick={() => {
                      onExportMIDI();
                      setMenuOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 hover:bg-gray-100 border-t text-sm"
                  >
                    Export MIDI
                  </button>
                ) : (
                  <button
                    disabled
                    className="block w-full text-left px-4 py-2 text-gray-400 border-t text-sm"
                    title="Pro feature"
                  >
                    Export MIDI (Pro only)
                  </button>
                )}

                {/* Export Metronome Data (JSON) */}
                <button
                  onClick={() => {
                    onExportMetronomeData();
                    setMenuOpen(false);
                  }}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100 border-t text-sm"
                >
                  Export Metronome Data
                </button>
              </div>
            )}
          </div>

          <div className="px-4 py-2 border-t">
            <button
              onClick={async () => {
                localStorage.removeItem("tutorialCompleted");

                // Update Firestore first if user is logged in
                if (currentUser) {
                  try {
                    const userDocRef = doc(db, "users", currentUser.uid);
                    await updateDoc(userDocRef, {
                      tutorialCompleted: false,
                    });
                  } catch (error) {
                    console.error("Error updating Firestore:", error);
                  }
                }

                // Only after Firestore is updated, update the UI state
                setTimeout(() => {
                  setShowTutorial(true);
                  setMenuOpen(false);
                }, 100);
              }}
              className="w-full flex items-center justify-between px-2 py-1 bg-blue-500 hover:bg-blue-600 text-white rounded"
            >
              <span className="font-medium">View Tutorial</span>
              <BookOpen className="w-4 h-4" />
            </button>
          </div>

          {/* STRESS & TUNING */}
          <div className="px-4 py-2 border-t">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={stressOn}
                onChange={(e) => setStressOn(e.target.checked)}
              />
              <span className="ml-1 text-sm">Stress Patterns</span>
            </label>
          </div>

          <div className="px-4 py-2 border-t">
            <label className="font-medium block mb-1">Tuning (A=? Hz)</label>
            <select
              value={aTuning}
              onChange={(e) => {
                setATuning(Number(e.target.value));
                setMenuOpen(false);
              }}
              className="border p-1 rounded w-full"
            >
              {[438, 439, 440, 441, 442].map((hz) => (
                <option key={hz} value={hz}>
                  {hz}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
}

/**
 * Redirect the user to the Stripe-hosted pricing table
 * so they can pick either Basic or Pro.
 */
const handleUpgrade = () => {
  const pricingTableContainer = document.createElement("div");
  document.body.appendChild(pricingTableContainer);

  const pricingTable = document.createElement("stripe-pricing-table");
  pricingTable.setAttribute(
    "pricing-table-id",
    "prctbl_1RAxlNJxm9txLODovxvSWZBN"
  );
  pricingTable.setAttribute(
    "publishable-key",
    "pk_live_51R8gLXJxm9txLODoduD4BBoIKjKwpoXZiqOTejyNH98JHY1wGMVfiv3CKdAAhtIc1RCTBJIa4qCcLdN7xqke944600Pm9rI5Nz"
  );

  // Add custom styles to the pricing table element to ensure horizontal layout
  pricingTable.style.maxWidth = "100%";
  pricingTable.style.overflow = "auto";

  // Add custom CSS to force horizontal layout
  const styleSheet = document.createElement("style");
  styleSheet.textContent = `
    stripe-pricing-table {
      --stripe-pricing-table-spacing-unit: 8px;
      --stripe-pricing-table-layout: columns !important;
      --stripe-pricing-table-group-padding: 0 !important;
      width: 100%;
    }
    stripe-pricing-table::part(prices) {
      display: flex !important;
      flex-direction: row !important;
      justify-content: center !important;
      gap: 20px !important;
    }
  `;
  document.head.appendChild(styleSheet);

  pricingTableContainer.appendChild(pricingTable);

  // Update container styling for better visibility and scrolling
  pricingTableContainer.style.position = "fixed";
  pricingTableContainer.style.top = "0";
  pricingTableContainer.style.left = "0";
  pricingTableContainer.style.width = "100%";
  pricingTableContainer.style.height = "100%";
  pricingTableContainer.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
  pricingTableContainer.style.zIndex = "1000";
  pricingTableContainer.style.display = "flex";
  pricingTableContainer.style.justifyContent = "center";
  pricingTableContainer.style.alignItems = "center";
  pricingTableContainer.style.padding = "40px"; // Increased padding
  pricingTableContainer.style.overflow = "auto"; // Enable scrolling if needed

  // Style the actual content container
  const contentWrapper = document.createElement("div");
  contentWrapper.style.backgroundColor = "white";
  contentWrapper.style.borderRadius = "8px";
  contentWrapper.style.padding = "20px";
  contentWrapper.style.maxHeight = "90vh";
  contentWrapper.style.overflowY = "auto";
  contentWrapper.style.width = "90%";
  contentWrapper.style.maxWidth = "1200px"; // Set a max-width for larger screens

  contentWrapper.appendChild(pricingTable);
  pricingTableContainer.appendChild(contentWrapper);

  // Update close button styling and position
  const closeButton = document.createElement("button");
  closeButton.textContent = "×";
  closeButton.style.position = "absolute";
  closeButton.style.top = "20px";
  closeButton.style.right = "20px";
  closeButton.style.fontSize = "32px";
  closeButton.style.color = "white";
  closeButton.style.background = "none";
  closeButton.style.border = "none";
  closeButton.style.cursor = "pointer";
  closeButton.style.width = "40px";
  closeButton.style.height = "40px";
  closeButton.style.display = "flex";
  closeButton.style.alignItems = "center";
  closeButton.style.justifyContent = "center";
  closeButton.style.zIndex = "1001";

  closeButton.onclick = () => {
    document.body.removeChild(pricingTableContainer);
    document.head.removeChild(styleSheet);
  };

  pricingTableContainer.appendChild(closeButton);
};

export function LoginForm({ onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  // State for toggling the password-reset form
  const [showResetForm, setShowResetForm] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setError(null);

    try {
      // Sign in with Firebase
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // If successful, call the onLoginSuccess callback
      onLoginSuccess?.(userCredential.user);
      console.log("Logged in as:", userCredential.user.uid);
    } catch (err) {
      console.error("Login error:", err);
      setError(err.message);
    }
  }

  // Handle password reset via Firebase
  async function handlePasswordReset(e) {
    e.preventDefault();
    setError(null);

    try {
      await sendPasswordResetEmail(auth, email);
      alert(
        "Password reset link has been sent to your email. Please check your inbox."
      );
      // Optionally hide the reset form after sending
      setShowResetForm(false);
    } catch (err) {
      console.error("Reset password error:", err);
      setError(err.message);
    }
  }

  return (
    <div style={{ maxWidth: 300, margin: "0 auto" }}>
      {/* If NOT showing reset form, show the normal Login form */}
      {!showResetForm && (
        <form onSubmit={handleSubmit}>
          <h2>Log In</h2>

          <div style={{ marginBottom: "1rem" }}>
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="block w-full mb-4 p-2 border border-gray-300 rounded"
              />
            </label>
          </div>

          <div style={{ marginBottom: "1rem" }}>
            <label>
              Password:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="block w-full mb-4 p-2 border border-gray-300 rounded"
              />
            </label>
          </div>

          {error && <p style={{ color: "red" }}>Error: {error}</p>}

          <button type="submit" style={{ marginRight: "1rem" }}>
            Login
          </button>

          {/* “Forgot password?” link => toggles the reset form */}
          <button
            type="button"
            onClick={() => {
              setShowResetForm(true);
              setError(null); // Clear any old errors
            }}
            className="text-blue-600 underline"
          >
            Forgot password?
          </button>
        </form>
      )}

      {/* If showResetForm is true, show the reset form instead */}
      {showResetForm && (
        <form onSubmit={handlePasswordReset}>
          <h2>Reset Password</h2>
          <p className="text-sm mb-2">
            Enter your email and we’ll send a link to reset your password.
          </p>

          <div style={{ marginBottom: "1rem" }}>
            <label>
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="block w-full mb-2 p-2 border border-gray-300 rounded"
              />
            </label>
          </div>

          {error && <p style={{ color: "red" }}>Error: {error}</p>}

          <button type="submit" style={{ marginRight: "1rem" }}>
            Send Reset Email
          </button>

          <button
            type="button"
            onClick={() => {
              setShowResetForm(false);
              setError(null); // Clear any old errors
            }}
            className="text-blue-600 underline"
          >
            Back to Login
          </button>
        </form>
      )}
    </div>
  );
}

export function PracticeRoomTab() {
  const [selectedPost, setSelectedPost] = React.useState(null);
  const [selectedTag, setSelectedTag] = React.useState(null);
  const [filtersCollapsed, setFiltersCollapsed] = React.useState(true);

  // Get all available tags and filtered posts
  const allTags = getAllTags();
  const filteredPosts = getPostsByTag(selectedTag);

  // Handle tag selection
  const handleTagSelect = (tag) => {
    setSelectedTag(tag === selectedTag ? null : tag);
  };

  // Handle post selection for viewing
  const handlePostSelect = (postId) => {
    const post = getPostById(postId);
    setSelectedPost(post);
  };

  // Go back to post list
  const handleBackToList = () => {
    setSelectedPost(null);
  };

  // Toggle filters visibility
  const toggleFilters = () => {
    setFiltersCollapsed(!filtersCollapsed);
  };

  // If a post is selected, show the full post view
  if (selectedPost) {
    return (
      <div className="p-4 max-w-4xl mx-auto">
        <button
          onClick={handleBackToList}
          className="mb-4 flex items-center text-blue-600 hover:text-blue-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-1"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          Back to all posts
        </button>

        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <img
            src={selectedPost.featuredImage || DEFAULT_POST_IMAGE}
            alt={selectedPost.title}
            className="w-full h-64 object-cover"
          />

          <div className="p-6">
            <h1 className="text-2xl font-bold mb-2">{selectedPost.title}</h1>
            <p className="text-gray-600 mb-4">By {selectedPost.author}</p>

            <div className="mb-4 flex flex-wrap gap-2">
              {selectedPost.tags.map((tag) => (
                <span
                  key={tag}
                  className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                >
                  {tag}
                </span>
              ))}
            </div>

            <div className="prose max-w-none">
              {/* Use markdown parser component in your actual implementation */}
              <div className="whitespace-pre-line">{selectedPost.content}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Otherwise show the tile layout with filters
  return (
    <div className="p-4">
      <div className="text-center mb-3">
        <img
          src={flowFrameLogo}
          alt="FlowFrame Logo"
          className="inline-block w-48"
        />
      </div>
      <h2 className="text-2 mb-4 text-center">Empowering Confident Artistry</h2>
      <h1 className="text-2xl font-bold mb-4">From the Practice Room</h1>

      {/* Tag filters with collapse toggle */}
      <div className="mb-6">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-lg font-medium">Filter by topic:</h3>
          <button
            onClick={toggleFilters}
            className="text-blue-600 hover:text-blue-800 focus:outline-none"
          >
            {filtersCollapsed ? "Show filters" : "Hide filters"}
          </button>
        </div>

        {!filtersCollapsed && (
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setSelectedTag(null)}
              className={`px-3 py-1 rounded-full text-sm border ${
                selectedTag === null
                  ? "bg-blue-600 text-white"
                  : "bg-white text-gray-700 hover:bg-gray-100"
              }`}
            >
              All
            </button>
            {allTags.map((tag) => (
              <button
                key={tag}
                onClick={() => handleTagSelect(tag)}
                className={`px-3 py-1 rounded-full text-sm border ${
                  selectedTag === tag
                    ? "bg-blue-600 text-white"
                    : "bg-white text-gray-700 hover:bg-gray-100"
                }`}
              >
                {tag}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Post tiles */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredPosts.map((post) => (
          <div
            key={post.id}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow cursor-pointer"
            onClick={() => handlePostSelect(post.id)}
          >
            <div className="h-48 overflow-hidden">
              <img
                src={post.featuredImage || DEFAULT_POST_IMAGE}
                alt={post.title}
                className="w-full h-full object-cover transition-transform hover:scale-105"
              />
            </div>

            <div className="p-4">
              <h3 className="font-bold text-lg mb-2">{post.title}</h3>

              <div className="mb-2 flex flex-wrap gap-1">
                {post.tags.slice(0, 3).map((tag) => (
                  <span
                    key={tag}
                    className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-xs"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleTagSelect(tag);
                    }}
                  >
                    {tag}
                  </span>
                ))}
                {post.tags.length > 3 && (
                  <span className="px-2 py-1 bg-gray-100 text-gray-800 rounded-full text-xs">
                    +{post.tags.length - 3} more
                  </span>
                )}
              </div>

              <p className="text-sm text-gray-600">{post.excerpt}</p>
            </div>
          </div>
        ))}
      </div>

      {filteredPosts.length === 0 && (
        <div className="text-center p-8 bg-gray-50 rounded-lg">
          <p className="text-gray-600">No posts found with the selected tag.</p>
          <button
            onClick={() => setSelectedTag(null)}
            className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            View all posts
          </button>
        </div>
      )}
    </div>
  );
}

// A set of allowed loopModes
const VALID_LOOP_MODES = new Set(["loop", "continue", "specific"]);

/** A small helper for playing the "click" sound. */
function playClick(
  audioCtx,
  masterGainNode,
  isAccent,
  soundObj,
  beatVolume,
  when
) {
  const startTime = Math.max(when, audioCtx.currentTime + 0.005);
  const osc = audioCtx.createOscillator();
  const gain = audioCtx.createGain();
  osc.connect(gain).connect(masterGainNode);

  // Check if we should use accent pitch (considering noPitchAccent property)
  const useAccentPitch = isAccent && !soundObj.noPitchAccent;

  if (soundObj.type === "clicker") {
    // A small click
    osc.type = "square";
    const freq = useAccentPitch ? 2000 : 1600;
    const vol = isAccent ? 1.2 : 0.6;
    gain.gain.setValueAtTime(vol * beatVolume, startTime);
    gain.gain.linearRampToValueAtTime(0.0001, startTime + 0.01);
    osc.frequency.setValueAtTime(freq, startTime);
    osc.start(startTime);
    osc.stop(startTime + 0.02);
    return;
  }

  // Otherwise, e.g. "sine"
  osc.type = soundObj.type || "sine";
  const freqVal = useAccentPitch
    ? soundObj.accentFrequency || 1000
    : soundObj.frequency || 800;
  osc.frequency.setValueAtTime(freqVal, startTime);

  const baseVolume = isAccent ? 1.2 : 0.7;
  gain.gain.setValueAtTime(baseVolume * beatVolume, startTime);
  gain.gain.exponentialRampToValueAtTime(0.0001, startTime + 0.07);

  osc.start(startTime);
  osc.stop(startTime + 0.08);
}

// For export:
// Map each FlowFrame "sound" to a MIDI note number and separate accent velocity.
const SOUND_MAP = {
  clicker: { note: 60, baseVelocity: 70 }, // Middle C
  click: { note: 62, baseVelocity: 70 },
  wood: { note: 64, baseVelocity: 70 },
  cowbell: { note: 66, baseVelocity: 70 },
  hihat: { note: 68, baseVelocity: 70 },
};

// jsmidgen's default is 128 ticks = 1 quarter note
const TICKS_PER_QUARTER = 128;
const DEFAULT_TEMPO = 120;

/** 1) Constants & Helper Data */
const INITIAL_TEMPO = 60;
const INITIAL_MUTE_RANDOM = false;
const INITIAL_MUTE_PERCENT = 50;
const INITIAL_LOOP_MODE = "loop"; // or "continue"

const DEFAULT_TEMPO_CHANGE = {
  type: "incremental",
  scope: undefined,
  useStepEvery: false,
  specificBars: "",
  stepSize: 5,
  endTempo: 120,
  stepEvery: 1,
  startMeasure: 1,
  endMeasure: 4,
  startTempo: 60,
  endTempo: 120,
};

/**
 * Subdivisions for metric modulation
 * e.g. from "Quarter note" → "Triplet quarter"
 */
const ALL_SUBDIVISIONS = [
  { name: "Whole note", value: 1 },
  { name: "Dotted half note", value: 1.5 },
  { name: "Half note", value: 2 },
  { name: "Quarter note", value: 4 },
  { name: "Dotted quarter note", value: 2.66667 },
  { name: "Quintuplet quarter note", value: 5 },
  { name: "Dotted eighth note", value: 5.33333 },
  { name: "Triplet quarter note", value: 6 },
  { name: "Eighth note", value: 8 },
  { name: "Quintuplet eighth note", value: 10 },
  { name: "Triplet eighth note", value: 12 },
  { name: "Sixteenth note", value: 16 },
  { name: "Quintuplet sixteenth note", value: 20 },
  { name: "Triplet sixteenth note", value: 24 },
];

/**
 * Equal-temperament chord definitions (intervals in semitones above the drone).
 * Each array does NOT include 0, because we add the fundamental ourselves.
 */
const CHORDS_ET = {
  Major: [4, 7], // Quarter steps: e.g. C + E + G
  Minor: [3, 7],
  Dim: [3, 6],
  "Aug.": [4, 8],
  "Dom⁷": [4, 7, 10],
  "HalfDim⁷": [3, 6, 10],
  "FullDim⁷": [3, 6, 9],
  "Maj⁶": [4, 7, 9],
  "Min⁶": [3, 7, 9],
  "Dim⁶": [3, 6, 8],
  "Maj⁷": [4, 7, 11],
  "Min⁷": [3, 7, 10],
  "Dom⁷♯5": [4, 8, 10],
  "Min⁷♭5": [3, 6, 10],
  "Dom⁷♭5": [4, 6, 10],
  "MinMaj⁷": [3, 7, 11],
  "Maj⁷♯5": [4, 8, 11],
  "DimMaj⁷": [3, 6, 11],
  "Dom⁷+9": [4, 7, 10, 15],
  "Dom⁷♭9": [4, 7, 10, 14],
  "Maj⁷+9": [4, 7, 11, 15],
};

/**
 * Just Intonation chord definitions (offsets in *cents* from the fundamental).
 * Example: a "Major" triad => +386 cents, +702 cents for the 3rd & 5th.
 */
const CHORDS_JI = {
  Major: [386, 702],
  Minor: [316, 702],
  Dim: [316, 568],
  "Aug.": [386, 816],
  "Dom⁷": [386, 702, 968],
  "HalfDim⁷": [316, 568, 786],
  "FullDim⁷": [316, 568, 814],
  "Maj⁶": [386, 702, 884],
  "Min⁶": [316, 702, 884],
  "Dim⁶": [316, 568, 772],
  "Maj⁷": [386, 702, 1088],
  "Min⁷": [316, 702, 968],
  "Dom⁷♯5": [386, 816, 968],
  "Min⁷♭5": [316, 568, 786],
  "Dom⁷♭5": [386, 566, 968],
  "MinMaj⁷": [332, 704, 1099],
  "Maj⁷♯5": [372, 812, 1114],
  "DimMaj⁷": [332, 551, 1104],
  "Dom⁷+9": [372, 704, 937, 1408],
  "Dom⁷♭9": [372, 704, 937, 1305],
  "Maj⁷+9": [372, 704, 1076, 1408],
};

/** Sound options for the "click" sounds */
const SOUND_OPTIONS = [
  {
    id: "clicker",
    name: "Clicker",
    type: "clicker",
    color: "gray-500",
  },
  {
    id: "click",
    name: "Click",
    frequency: 800,
    accentFrequency: 1000,
    type: "sine",
    color: "blue-500",
  },
  {
    id: "wood",
    name: "Wood Block",
    frequency: 1200,
    accentFrequency: 1800,
    type: "sine",
    color: "red-500",
  },
  {
    id: "cowbell",
    name: "Cowbell",
    frequency: 600,
    accentFrequency: 800,
    type: "triangle",
    color: "green-500",
  },
  {
    id: "hihat",
    name: "Hi-Hat",
    frequency: 2000,
    accentFrequency: 3000,
    type: "square",
    color: "yellow-500",
  },
];

/** Note options for drone pitch selection. */
const NOTE_OPTIONS = [
  { label: "Off", value: null },
  { label: "C", value: 0 },
  { label: "C#/Db", value: 1 },
  { label: "D", value: 2 },
  { label: "D#/Eb", value: 3 },
  { label: "E", value: 4 },
  { label: "F", value: 5 },
  { label: "F#/Gb", value: 6 },
  { label: "G", value: 7 },
  { label: "G#/Ab", value: 8 },
  { label: "A", value: 9 },
  { label: "A#/Bb", value: 10 },
  { label: "B", value: 11 },
];

/** Remove octaves 0–3, only allow 4–6. */
const OCTAVE_OPTIONS = [
  { label: "Octave 3", value: 3 },
  { label: "Octave 4", value: 4 },
  { label: "Octave 5", value: 5 },
  { label: "Octave 6", value: 6 },
];

const NOTE_OPTIONS_12 = [
  { label: "C", value: 0 },
  { label: "C#/Db", value: 1 },
  { label: "D", value: 2 },
  { label: "D#/Eb", value: 3 },
  { label: "E", value: 4 },
  { label: "F", value: 5 },
  { label: "F#/Gb", value: 6 },
  { label: "G", value: 7 },
  { label: "G#/Ab", value: 8 },
  { label: "A", value: 9 },
  { label: "A#/Bb", value: 10 },
  { label: "B", value: 11 },
];

const QUARTER_SHARP_OPTIONS = [
  { label: "C¼♯", value: 60.5 },
  { label: "D♭¼♯", value: 61.5 },
  { label: "D¼♯", value: 62.5 },
  { label: "E♭¼♯", value: 63.5 },
  { label: "E¼♯", value: 64.5 },
  { label: "F¼♯", value: 65.5 },
  { label: "G♭¼♯", value: 66.5 },
  { label: "G¼♯", value: 67.5 },
  { label: "A♭¼♯", value: 68.5 },
  { label: "A¼♯", value: 69.5 },
  { label: "B♭¼♯", value: 70.5 },
  { label: "B¼♯", value: 71.5 },
];

const DYNAMICS_MAP = {
  pp: 0.3, // softest
  p: 0.63,
  mp: 0.85,
  mf: 1.0,
  f: 1.5,
  ff: 2.0, // loudest
};

const NOTE_VALUE_OPTIONS = [
  { label: "Whole Note", factor: 4 },
  { label: "Dotted Half Note", factor: 3 },
  { label: "Half Note", factor: 2 },
  { label: "Dotted Quarter Note", factor: 1.5 },
  { label: "Quarter Note", factor: 1 },
  { label: "Dotted Eighth Note", factor: 0.75 },
  { label: "Eighth Note", factor: 0.5 },
  { label: "Dotted Sixteenth Note", factor: 0.375 },
  { label: "Sixteenth Note", factor: 0.25 },
  { label: "Dotted 32nd Note", factor: 0.1875 },
  { label: "32nd Note", factor: 0.125 },
];

const validDenominators = [1, 2, 4, 8, 16, 32];

// The preset stress volumes by top number (ignoring denominator)
// Because all denominators 1,2,4,8,16,32 share the same pattern
const STRESS_PATTERNS = {
  2: [1.0, 0.3],
  3: [1.0, 0.3, 0.3],
  4: [1.0, 0.3, 0.7, 0.3],
  6: [1.0, 0.3, 0.3, 0.7, 0.3, 0.3],
  8: [1.0, 0.3, 0.45, 0.3, 0.7, 0.3, 0.45, 0.3],
};

const DEFAULT_CRESC_FACTOR = 3.5; // e.g. ramp up 50%
const DEFAULT_DIM_FACTOR = 0.5; // e.g. ramp down 50%

function BulkEditorModal({ isOpen, onClose, onApplyChanges }) {
  const [inputText, setInputText] = useState("");

  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>Bulk Editor</h2>
        <p>Enter your measure definitions, one line per range:</p>
        <pre className="text-xs bg-gray-50 border p-1">
          E.g.: 1-8 =&gt; 4/4 9 =&gt; 3/4 10-12 =&gt; 5/16
        </pre>

        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          className="w-full p-2 border rounded h-40"
        />

        <div className="mt-4 flex space-x-2">
          <button
            id="bulkeditor"
            onClick={() => onApplyChanges(inputText)}
            className="px-3 py-1 bg-[#059DD9] text-white rounded hover:bg-[#307BBF]"
          >
            Apply Changes
          </button>
          <button
            onClick={onClose}
            className="px-3 py-1 bg-gray-400 rounded hover:bg-gray-500"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

function parseMeasureSignatureLine(line) {
  // Accept lines like:
  // "1-12 => 4/4" or "measure 13 to 20 are in 6/8" or "21 => 5/16"
  // We’ll do something simple like:
  //
  // 1) find a measure range:  e.g. "1-12" or just "21"
  // 2) find a time signature: e.g. "4/4", "6/8", "5/16", etc.

  // 1) Extract measure range using a quick regex:
  //    This tries to catch either "X-Y" or single "X"
  //    (If your user also types 'measure', 'to', 'are in', etc. you can either do a more robust parse or strip them out)
  const rangeRegex = /(\d+)(?:\s*\-\s*(\d+))?/;
  const matchRange = rangeRegex.exec(line);
  if (!matchRange) return null;

  const startStr = matchRange[1];
  const endStr = matchRange[2];

  const startMeasure = parseInt(startStr, 10);
  const endMeasure = endStr ? parseInt(endStr, 10) : startMeasure;

  // 2) Extract time sig (numerator/denominator) e.g. "4/4"
  const timeSigRegex = /(\d+)\/(\d+)/;
  const matchTS = timeSigRegex.exec(line);
  if (!matchTS) return null;

  const numerator = parseInt(matchTS[1], 10);
  const denominator = parseInt(matchTS[2], 10);

  // If you want to parse tempo from the same line, you can do so:
  // e.g. a pattern like "1-12 => 4/4 @ 120 BPM"
  // you can add a separate regex or parse out the BPM.

  return {
    startMeasure,
    endMeasure,
    numerator,
    denominator,
    // optional: tempo
  };
}

// This function applies the stress pattern (or default=1.0) to a *single* measure
function applyStressVolumesIfAny(measures, stressOn) {
  if (!stressOn) return measures;

  measures.forEach((m) => {
    const top = m.numerator;
    const bottom = m.denominator;
    const patternArr = STRESS_PATTERNS[top];

    if (patternArr && [1, 2, 4, 8, 16, 32].includes(bottom)) {
      m.beatSounds.forEach((beatObj, idx) => {
        beatObj.userVolume =
          idx < patternArr.length
            ? patternArr[idx]
            : patternArr[patternArr.length - 1]; // Fallback to last stress volume
      });
    } else {
      m.beatSounds.forEach((beatObj) => {
        beatObj.userVolume = 1.0;
      });
    }
  });

  return measures;
}

function clampToRange(freq) {
  while (freq < MIN_FREQ) {
    freq *= 2;
  }
  while (freq > MAX_FREQ) {
    freq /= 2;
  }
  return freq;
}

/**
 * Compute frequency from note, octave, and reference A.
 * We pass in "referenceA" for user-chosen A=... tuning.
 */
function getFrequency(noteValue, octave, referenceA = 440) {
  const noteNumber = octave * 12 + noteValue; // MIDI note index
  return referenceA * Math.pow(2, (noteNumber - 69) / 12);
}

/** Create a new beat with default "clicker" */
function createNewBeat() {
  return {
    id: SOUND_OPTIONS[0].id,
    name: SOUND_OPTIONS[0].name,
    type: SOUND_OPTIONS[0].type,
    color: SOUND_OPTIONS[0].color,
    userVolume: 1.0, // store the user’s chosen amplitude (the base)
    beatVolume: 1.0, // the “final” amplitude used for playback
    noPitchAccent: false,
    // subdivisions
    subdivisions: [
      {
        // By default, create one "entry" that says "no subdivision."
        // subCount = 1 means “no subdiv” for now.
        subCount: 1,
        accentedSubs: [],
        mutedSubs: [],
      },
      // You can push more objects here if you want multiple from the start,
      // or just start with one and add more later in the UI.
    ],

    // volumes
    beatVolume: 1.0,

    // drone/chord
    dronePitchNote: null, // "Off" by default
    dronePitchOctave: 4, // default to Octave 4
    droneVolume: 0.3,
    hasHarmony: false,
    selectedHarmony: "Major",
    justIntonation: false,
    copyDroneHarmonyPartialsToNext: false,

    // Show/hide partials
    usePartials: false,
    selectedPartials: [],
    useCustomNotes: false,
    selectedCustomNotes: [],

    // Quarter tones
    useQuarterTones: false,
    selectedQuarterTones: [],
    quarterToneOctave: 4,
  };
}

function BeatHighlighter({ audioCtx, scheduledBeatsRef, leadTimeMs = 20 }) {
  // The local state for "which beat index is active"
  const [activeIndex, setActiveIndex] = useState(-1);
  const rafRef = useRef(null);

  useEffect(() => {
    function loop() {
      const nowSec = audioCtx.currentTime;
      let newIndex = -1;

      // 1) Extract the actual array from scheduleRef.current
      const schedArr = scheduledBeatsRef.current;
      // Make sure schedArr is a real array
      if (!schedArr || !Array.isArray(schedArr)) {
        // If it's empty or not an array, just keep looping
        rafRef.current = requestAnimationFrame(loop);
        return;
      }

      // 2) Simple linear scan to find the "current" or "just-passed" event
      for (let i = 0; i < schedArr.length; i++) {
        const eventTime = schedArr[i].startTimeSec;
        // shift highlight a bit earlier if desired
        const highlightTime = eventTime - leadTimeMs / 1000;
        if (highlightTime > nowSec) {
          newIndex = i - 1;
          break;
        }
      }

      // If the loop never broke, we might be at or past the last event
      if (newIndex < 0) {
        newIndex = schedArr.length - 1;
      }

      // Clamp within valid array bounds
      if (newIndex < 0) newIndex = 0;
      if (newIndex >= schedArr.length) newIndex = schedArr.length - 1;

      // 3) If newIndex changed from the current `activeIndex`, update it
      if (newIndex !== activeIndex) {
        setActiveIndex(newIndex);
      }

      // Continue the loop
      rafRef.current = requestAnimationFrame(loop);
    }

    rafRef.current = requestAnimationFrame(loop);

    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [audioCtx, scheduledBeatsRef, leadTimeMs, activeIndex]);

  // Whenever activeIndex changes, highlight the correct <div>
  useEffect(() => {
    // Remove any old highlight
    document
      .querySelectorAll(".highlighted-beat")
      .forEach((el) => el.classList.remove("highlighted-beat"));

    const schedArr = scheduledBeatsRef.current;
    if (!schedArr || !schedArr[activeIndex]) return;

    // Grab measureIndex & beatIndex from the chosen event
    const { measureIndex, beatIndex } = schedArr[activeIndex];
    const beatId = `m${measureIndex}-b${beatIndex}`;

    // Find the correct DOM node and add a highlight class
    const el = document.querySelector(`[data-beat-id="${beatId}"]`);
    if (el) {
      el.classList.add("highlighted-beat");
    }
  }, [activeIndex, scheduledBeatsRef]);

  return null; // no visible output from this component
}

function createNewMeasure(stressOn, initialNumber = 1) {
  const numerator = 4; // Default, but will be changed later
  const denominator = 4;
  const numBeats = STRESS_PATTERNS[numerator]
    ? STRESS_PATTERNS[numerator].length
    : numerator;

  // 1) Build the measure object
  const measure = {
    id: uuidv4(),
    numerator: 4,
    denominator: 4,
    hasModulation: false,
    fromSubdivision: { name: "Quarter note", value: 4 },
    toSubdivision: { name: "Quarter note", value: 4 },
    applyBeatOneDroneToAllBeats: false,
    beatSounds: Array(numBeats)
      .fill(null)
      .map(() => createNewBeat()),
    onlyTuplets: false,
    tuplets: [],
    dynamicMarking: "",
    cresc: false,
    dim: false,
  };

  // 2) If stress is on, see if 4/4 is in our patterns. Then apply or do nothing
  if (stressOn && STRESS_PATTERNS[numerator]) {
    measure.beatSounds.forEach((beatObj, idx) => {
      beatObj.userVolume =
        idx < STRESS_PATTERNS[numerator].length
          ? STRESS_PATTERNS[numerator][idx]
          : 1.0;
    });
  }

  return measure;
}

function parseMeasureRanges(inputStr) {
  // Trim & split by comma
  const segments = inputStr.split(",").map((seg) => seg.trim());
  const results = [];

  segments.forEach((seg) => {
    if (seg.includes("-")) {
      // e.g. "4-5" => [4,5]
      const [start, end] = seg.split("-").map((x) => parseInt(x.trim(), 10));
      if (!isNaN(start) && !isNaN(end) && end >= start) {
        for (let i = start; i <= end; i++) {
          results.push(i);
        }
      }
    } else {
      // single number
      const val = parseInt(seg, 10);
      if (!isNaN(val)) {
        results.push(val);
      }
    }
  });

  // Return only unique, valid measure numbers (filter out zeros or negatives)
  return [...new Set(results)].filter((m) => m > 0);
}

function resetAllBeatVolumesToUserVolumes(measures) {
  for (const meas of measures) {
    for (const beat of meas.beatSounds) {
      beat.beatVolume = beat.userVolume;
    }
  }
}

/**
 * applyAllDynamicsToAllMeasures:
 *  - Loops over every measure i
 *  - Looks up measure i's dynamic marking => currentBaseVol
 *  - Looks up measure (i+1)'s dynamic marking => nextBaseVol (if it exists)
 *  - If measure i has cresc => only ramp *up*
 *  - If measure i has dim   => only ramp *down*
 *  - If neither => ramp from i's dynamic to (i+1)'s dynamic as-is.
 */
function applyAllDynamicsToAllMeasures(measures) {
  for (let i = 0; i < measures.length; i++) {
    const measure = measures[i];

    // 1) Lookup current measure’s dynamic marking
    const thisKey = (measure.dynamicMarking || "").trim().toLowerCase();
    const currentBaseVol = DYNAMICS_MAP[thisKey] || 1.0;

    // 2) Check if there’s a “next measure” dynamic
    let nextBaseVol = currentBaseVol;
    let hasNext = false;
    if (i + 1 < measures.length) {
      const nextKey = (measures[i + 1].dynamicMarking || "")
        .trim()
        .toLowerCase();
      if (nextKey) {
        hasNext = true;
        nextBaseVol = DYNAMICS_MAP[nextKey] || 1.0;
      }
    }

    // 3) Check if measure i is cresc or dim
    const isCresc = measure.cresc;
    const isDim = measure.dim;

    // 4) Decide how measure i “ends” – i.e., what volume we ramp to
    let endVol = currentBaseVol; // fallback if no changes
    if (isCresc) {
      // If next measure is louder => end at next measure’s dynamic
      if (hasNext && nextBaseVol > currentBaseVol) {
        endVol = nextBaseVol;
      }
      // Otherwise ramp up by a default factor
      else {
        endVol = currentBaseVol * DEFAULT_CRESC_FACTOR;
      }
    } else if (isDim) {
      // If next measure is softer => end at next measure’s dynamic
      if (hasNext && nextBaseVol < currentBaseVol) {
        endVol = nextBaseVol;
      }
      // Otherwise ramp down by default factor
      else {
        endVol = currentBaseVol * DEFAULT_DIM_FACTOR;
      }
    }
    // (If neither cresc nor dim is set, we leave endVol = currentBaseVol
    //  or optionally ramp from currentBaseVol -> nextBaseVol if you want a crossfade.)

    // 5) Apply your single‐measure ramp from currentBaseVol -> endVol
    const numBeats = measure.beatSounds.length;
    for (let b = 0; b < numBeats; b++) {
      const beat = measure.beatSounds[b];

      // (A) We read beat.userVolume as the “raw” volume the user set
      const userVolume = beat.userVolume;
      // e.g. 1.0, 0.3, etc. – never permanently changed by measure dynamics

      // fraction from 0..1 across this measure
      const fraction = numBeats > 1 ? b / (numBeats - 1) : 0;

      // (B) Exponential approach
      const ratio = endVol / Math.max(0.0001, currentBaseVol);
      const dynamicFactor = currentBaseVol * Math.pow(ratio, fraction);

      // (C) If you prefer linear, comment out above, then do:
      // const dynamicFactor =
      //   currentBaseVol + fraction * (endVol - currentBaseVol);

      // 6) Final beat volume = userVolume * measure-level factor
      beat.beatVolume = userVolume * dynamicFactor;
    }

    // When measure i ends, measure i+1 will do its own logic,
    // which can cause a sudden jump if measure i ended at a different vol
    // than measure i+1's base dynamic.
  }
}

function parseBarString(str) {
  // e.g. "2-3,6" => parse into [2,3,6]
  // If user typed them 1-based, subtract 1 for zero-based
  const bars = [];
  if (!str.trim()) return bars;

  const parts = str.split(",");
  for (let item of parts) {
    item = item.trim();
    if (!item) continue;

    if (item.includes("-")) {
      const [startRaw, endRaw] = item.split("-").map((x) => x.trim());
      const start = parseInt(startRaw, 10) - 1; // convert to zero-based
      const end = parseInt(endRaw, 10) - 1;
      if (!isNaN(start) && !isNaN(end) && end >= start) {
        for (let i = start; i <= end; i++) {
          bars.push(i);
        }
      }
    } else {
      const val = parseInt(item, 10) - 1;
      if (!isNaN(val)) bars.push(val);
    }
  }
  return Array.from(new Set(bars)).sort((a, b) => a - b);
}

function AccentInput({ defaultValue = "", onAccentsChange }) {
  const [localText, setLocalText] = React.useState(defaultValue);

  function handleChange(e) {
    setLocalText(e.target.value);
  }

  function handleBlur() {
    const parsed = parseAccentString(localText);
    if (onAccentsChange) {
      onAccentsChange(parsed);
    }
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.target.blur(); // triggers handleBlur
    }
  }

  return (
    // 1) Wrap input in a <div> that has a border & padding:
    <div
      style={{
        border: "1px solid #999",
        borderRadius: "4px",
        padding: "4px",
        display: "inline-block", // or "block" if you want 100% width
      }}
    >
      <input
        type="text"
        value={localText}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        placeholder='e.g. "1,3"'
        style={{
          // 2) Remove the default border so it doesn’t double up
          border: "none",
          outline: "none",
        }}
      />
    </div>
  );
}

function parseAccentString(str) {
  const trimmed = str.trim();
  if (!trimmed) return [];
  if (trimmed.includes(",") || trimmed.includes("-")) {
    return parseBeatsInput(trimmed);
  }
  // Auto-split single digits vs. two digits if >= 10:
  let resultString = "";
  let i = 0;
  while (i < trimmed.length) {
    if (i + 1 < trimmed.length) {
      const twoDigit = trimmed.slice(i, i + 2);
      const val = parseInt(twoDigit, 10);
      if (!isNaN(val) && val >= 10 && val <= 99) {
        if (resultString) resultString += ",";
        resultString += val;
        i += 2;
        continue;
      }
    }
    if (resultString) resultString += ",";
    resultString += trimmed[i];
    i += 1;
  }
  return parseBeatsInput(resultString);
}

function parseBeatsInput(str) {
  // Your original parse logic
  const beats = [];
  if (!str.trim()) return beats;
  const parts = str.split(",");
  for (let item of parts) {
    item = item.trim();
    if (!item) continue;

    // handle ranges like "2-4"
    if (item.includes("-")) {
      const [startRaw, endRaw] = item.split("-").map((s) => s.trim());
      const start = parseInt(startRaw, 10);
      const end = parseInt(endRaw, 10);
      if (!isNaN(start) && !isNaN(end) && end >= start) {
        for (let j = start; j <= end; j++) {
          beats.push(j);
        }
      }
    } else {
      // single number
      const n = parseInt(item, 10);
      if (!isNaN(n) && n > 0) {
        beats.push(n);
      }
    }
  }
  return Array.from(new Set(beats)).sort((a, b) => a - b);
}

/** Tempo changes */
function computeEffectiveTempo(
  globalBarCount,
  localBeat,
  measureNumerator,
  baseTempo,
  useTempoChanges,
  tempoChanges,
  loopCountRef
) {
  let effectiveTempo = baseTempo;
  if (!useTempoChanges || !tempoChanges.length) {
    return effectiveTempo;
  }

  const currentMeasureFloat = globalBarCount + localBeat / measureNumerator;
  const sortedChanges = [...tempoChanges].sort(
    (a, b) => a.startMeasure - b.startMeasure
  );

  // Loop over each tempo-change object:
  for (const tc of sortedChanges) {
    // 1) If "incremental" with scope="whole", apply the loopCountRef logic:
    if (tc.type === "incremental" && tc.scope === "whole") {
      const newTempo = tc.startTempo + loopCountRef.current * tc.stepSize;
      if (tc.startTempo < tc.endTempo) {
        effectiveTempo = Math.min(newTempo, tc.endTempo);
      } else {
        effectiveTempo = Math.max(newTempo, tc.endTempo);
      }
    }

    // 2) Now destructure the fields from tc so we can do measure-based logic:
    const {
      startMeasure,
      endMeasure,
      startTempo,
      endTempo,
      type,
      stepSize = 5,
      stepEvery = 1,
      // and so forth
    } = tc;

    const startIdx = startMeasure - 1;
    const endIdx = endMeasure - 1;

    if (type === "sudden" && globalBarCount >= startIdx) {
      effectiveTempo = endTempo;
    }

    // Are we within this change’s measure range?
    if (currentMeasureFloat >= startIdx) {
      if (currentMeasureFloat > endIdx) {
        // Past the end => fix effectiveTempo to endTempo
        effectiveTempo = endTempo;
      } else {
        // We are somewhere between startMeasure and endMeasure
        const fraction =
          endIdx - startIdx > 0
            ? (currentMeasureFloat - startIdx) / (endIdx - startIdx)
            : 0;

        switch (type) {
          case "linear":
            effectiveTempo = startTempo + fraction * (endTempo - startTempo);
            break;

          case "exponential":
            if (startTempo > 0 && endTempo > 0) {
              const ratio = endTempo / startTempo;
              effectiveTempo = startTempo * Math.pow(ratio, fraction);
            }
            break;

          case "incremental":
            // If scope === "specific," do localLoopCount logic
            if (tc.scope === "specific") {
              const localCount = tc.localLoopCount || 0;
              const newTempo = startTempo + localCount * stepSize;
              if (startTempo < endTempo) {
                effectiveTempo = Math.min(newTempo, endTempo);
              } else {
                effectiveTempo = Math.max(newTempo, endTempo);
              }
            }
            // If scope === "whole," we already handled it above
            break;

          case "sudden":
          default:
            effectiveTempo = endTempo;
            break;
        }
      }
    }
    // Because we want the *last* matching change to override earlier ones,
    // we do NOT “break” here; we let it keep looping.
  }

  return effectiveTempo;
}

const DRONE_MIN_FREQ = 15;
const HARMONY_MIN_FREQ = 250;
const MAX_FREQ = 1000;

function clampDrone(freq) {
  while (freq < DRONE_MIN_FREQ) {
    freq *= 2;
  }
  while (freq > MAX_FREQ) {
    freq /= 2;
  }
  return freq;
}

function clampHarmony(freq) {
  while (freq < HARMONY_MIN_FREQ) {
    freq *= 2;
  }
  while (freq > MAX_FREQ) {
    freq /= 2;
  }
  return freq;
}

function applyModulation(
  activeModRef,
  effectiveTempo,
  denominator,
  noteValueFactor
) {
  // final BPM for denominator notes:
  const finalBPM = effectiveTempo * noteValueFactor * (denominator / 4);

  let baseMs = 60_000 / finalBPM;

  if (!activeModRef.current.isActive) {
    return baseMs;
  }

  const { fromSubdivision, toSubdivision } = activeModRef.current;
  const fromVal = fromSubdivision ? fromSubdivision.value : 4;
  const toVal = toSubdivision ? toSubdivision.value : 4;

  // e.g. a ratio for subdiv changes
  const ratio = toVal / fromVal;
  return baseMs * ratio;
}

function scheduleDroneOrChordChange(
  audioCtx,
  masterGainNode,
  partialOscRefs,
  chordOscRefs,
  beatObj,
  startTimeSec,
  aTuning,
  activeChordRef,
  beatSec,
  totalMeasureDurationSec,
  muteDrones,
  loopPitchOffsetRef,
  modulateOnLoop,
  subscriptionTier,
  muteRandomDrones,
  muteRandomDronePercentage,
  currentMeasure
) {
  // Get the current values directly from the component's state
  const componentState = window.metronomeComponentState || {};

  // Use the actual current values (with fallbacks)
  const actualMuteRandomDrones =
    componentState.muteRandomDrones !== undefined
      ? componentState.muteRandomDrones
      : muteRandomDrones || false;

  const actualPercentage =
    componentState.muteRandomDronePercentage !== undefined
      ? componentState.muteRandomDronePercentage
      : muteRandomDronePercentage || 50;

  // Use the actual values in the calculation
  const fadeInFactor = Math.min(1, Math.max(0, (currentMeasure - 3) / 3));
  const effectivePercentage = actualPercentage * fadeInFactor;
  const randomValue = Math.random() * 100;

  const shouldMuteRandomly =
    actualMuteRandomDrones &&
    currentMeasure >= 3 &&
    randomValue <= effectivePercentage;

  // Rest of function...
  if (muteDrones || shouldMuteRandomly) {
    if (activeChordRef.current.signature !== "none") {
      [...partialOscRefs.current, ...chordOscRefs.current].forEach(
        ({ osc, gainNode }) => {
          try {
            osc.stop();
          } catch {}
          osc.disconnect();
          gainNode.disconnect();
        }
      );
      partialOscRefs.current = [];
      chordOscRefs.current = [];
      activeChordRef.current.signature = "none";
    }
    return;
  }

  // 2) Handle the “pitch offset” logic for loops (modulateOnLoop).
  const pitchOffset = modulateOnLoop ? loopPitchOffsetRef.current : 0;
  const originalNote = beatObj.dronePitchNote; // user's base semitone
  const rawFinal = (originalNote ?? 0) + pitchOffset; // if Off => treat as 0
  const minAllowed = (originalNote ?? 0) - 24; // 2 octaves down
  const maxAllowed = (originalNote ?? 0) + 24; // 2 octaves up

  // 3) Build a chord “signature” so we only rebuild if something changed
  const chordSignature = JSON.stringify({
    note: beatObj.dronePitchNote,
    pitchOffset,
    octave: beatObj.dronePitchOctave,
    hasHarmony: beatObj.hasHarmony,
    selectedHarmony: beatObj.selectedHarmony,
    justIntonation: beatObj.justIntonation,
    usePartials: beatObj.usePartials,
    selectedPartials: beatObj.selectedPartials,
    useCustomNotes: beatObj.useCustomNotes,
    selectedCustomNotes: beatObj.selectedCustomNotes,

    // quarter-tone usage also matters:
    useQuarterTones: beatObj.useQuarterTones,
    selectedQuarterTones: beatObj.selectedQuarterTones,
    // plus subscriptionTier if you want that to force a rebuild when user logs out, etc.
  });

  if (activeChordRef.current.signature === chordSignature) {
    // If no change from last time, skip re-initialization
    return;
  }

  // 4) Stop old chord if any
  if (activeChordRef.current.signature !== "none") {
    [...partialOscRefs.current, ...chordOscRefs.current].forEach(
      ({ osc, gainNode }) => {
        try {
          osc.stop();
        } catch {}
        osc.disconnect();
        gainNode.disconnect();
      }
    );
    partialOscRefs.current = [];
    chordOscRefs.current = [];
  }
  activeChordRef.current.signature = chordSignature;

  // 5) Possibly clamp the note (only if we have a real “dronePitchNote”).
  let clampedSemitones = rawFinal;
  if (originalNote !== null) {
    // Calculate range size (4 octaves total: 2 up, 2 down)
    const rangeSize = maxAllowed - minAllowed;

    // Apply wraparound logic when out of range
    while (clampedSemitones > maxAllowed) {
      clampedSemitones = minAllowed + (clampedSemitones - maxAllowed - 1);
    }

    while (clampedSemitones < minAllowed) {
      clampedSemitones = maxAllowed - (minAllowed - clampedSemitones - 1);
    }
  }

  // 6) Compute a fundamental frequency (only if dronePitchNote != null)
  let fundamentalFreq = null;
  if (originalNote !== null) {
    fundamentalFreq = getFrequency(
      clampedSemitones,
      beatObj.dronePitchOctave,
      aTuning
    );
    fundamentalFreq = clampDrone(fundamentalFreq);
  }

  // 7) Build up a chord frequency array if the user has a real drone pitch
  let chordFreqs = [];
  if (fundamentalFreq !== null) {
    // If “Any notes” is on:
    if (beatObj.useCustomNotes && beatObj.selectedCustomNotes?.length > 0) {
      chordFreqs = beatObj.selectedCustomNotes.map((semi) => {
        const midiNum = beatObj.dronePitchOctave * 12 + semi;
        let freq = aTuning * Math.pow(2, (midiNum - 69) / 12);
        freq = clampHarmony(freq);
        return freq;
      });
    } else {
      // Normal single fundamental
      chordFreqs = [fundamentalFreq];

      // Harmony
      if (beatObj.hasHarmony) {
        if (beatObj.justIntonation) {
          const offsetsInCents = CHORDS_JI[beatObj.selectedHarmony] || [];
          offsetsInCents.forEach((offsetCents) => {
            let freq = fundamentalFreq * Math.pow(2, offsetCents / 1200);
            freq = clampHarmony(freq);
            chordFreqs.push(freq);
          });
        } else {
          const intervals = CHORDS_ET[beatObj.selectedHarmony] || [];
          intervals.forEach((st) => {
            let freq = fundamentalFreq * Math.pow(2, st / 12);
            freq = clampHarmony(freq);
            chordFreqs.push(freq);
          });
        }
      }
    }
  }

  // 8) Create main chord oscillators if we have chordFreqs
  if (chordFreqs.length > 0) {
    chordFreqs.forEach((freq) => {
      const osc = audioCtx.createOscillator();
      osc.type = "sine";
      osc.frequency.setValueAtTime(freq, startTimeSec);

      const gain = audioCtx.createGain();
      gain.gain.setValueAtTime(
        beatObj.droneVolume / chordFreqs.length,
        startTimeSec
      );

      osc.connect(gain).connect(masterGainNode);
      osc.start(startTimeSec);
      // indefinite sustain => no osc.stop()

      chordOscRefs.current.push({ osc, gainNode: gain });
    });
  }

  // 9) If user wants partials (and we have a fundamentalFreq), create those
  if (
    fundamentalFreq !== null &&
    beatObj.usePartials &&
    beatObj.selectedPartials.length > 0
  ) {
    for (const partialIndex of beatObj.selectedPartials) {
      const osc = audioCtx.createOscillator();
      osc.type = "sine";

      let partialFreq = fundamentalFreq * (partialIndex / 2);
      partialFreq = clampHarmony(partialFreq);

      const gain = audioCtx.createGain();
      gain.gain.setValueAtTime(
        beatObj.droneVolume / (2 * beatObj.selectedPartials.length),
        startTimeSec
      );

      osc.frequency.setValueAtTime(partialFreq, startTimeSec);
      osc.connect(gain).connect(masterGainNode);
      osc.start(startTimeSec);

      partialOscRefs.current.push({ osc, gainNode: gain });
    }
  }

  // 10) Quarter tones => only if user is Pro AND user wants them
  if (beatObj.useQuarterTones && beatObj.selectedQuarterTones.length > 0) {
    // Quarter-tones do *not* require dronePitchNote
    // They each define their own absolute MIDI note like 60.5, 71.5, etc.
    for (const fractionalNote of beatObj.selectedQuarterTones) {
      // fractionalNote is already an absolute MIDI pitch (e.g. 60.5).
      // Subtract baseline 4 from the user’s chosen octave to get the offset:
      const octaveDiff = beatObj.quarterToneOctave - 4;

      // Shift the absolute MIDI note up or down by octaveDiff * 12
      const midiNumber = fractionalNote + 12 * octaveDiff;

      // Convert MIDI note → frequency
      let freq = aTuning * Math.pow(2, (midiNumber - 69) / 12);

      // Fix: clamp so we don't get extreme highs/lows
      // freq = clampHarmony(freq);

      const osc = audioCtx.createOscillator();
      osc.type = "sine";

      const gain = audioCtx.createGain();
      gain.gain.setValueAtTime(
        beatObj.droneVolume / beatObj.selectedQuarterTones.length,
        startTimeSec
      );

      osc.frequency.setValueAtTime(freq, startTimeSec);
      osc.connect(gain).connect(masterGainNode);
      osc.start(startTimeSec);

      partialOscRefs.current.push({ osc, gainNode: gain });
    }
  }
}

/**
 * scheduleTuplet: produce tup.subCount clicks from startSec → startSec + totalSec
 * Also handles nested tuplets and an optional chord/drone on the first tuplet beat.
 */
function scheduleTuplet(
  audioCtx,
  masterGainNode,
  partialOscRefs,
  chordOscRefs,
  tup,
  startSec,
  totalSec,
  firstBeatDrone,
  aTuning,
  activeChordRef,
  muteDrones,
  skipParentClick,
  muteRandomDrones,
  muteRandomDronePercentage,
  globalMeasureCountRef
) {
  if (!tup.subCount || tup.subCount < 1) return;

  const subIntervalSec = totalSec / tup.subCount;

  for (let i = 0; i < tup.subCount; i++) {
    let beepTime = startSec + i * subIntervalSec;
    if (beepTime < audioCtx.currentTime) {
      beepTime = audioCtx.currentTime + 0.01;
    }

    // Check if this subdivision should be muted
    const subdivisionNumber = i + 1; // 1-indexed like beats
    const isMuted = (tup.mutedSubdivisions || []).includes(subdivisionNumber);
    console.log(`Tuplet subdivision ${subdivisionNumber} muted:`, isMuted); //

    // Skip the parent beep if skipParentClick === true OR if this subdivision is muted
    if (!skipParentClick && !isMuted) {
      const soundObj =
        SOUND_OPTIONS.find((opt) => opt.id === tup.soundId) || SOUND_OPTIONS[0];
      if (soundObj) {
        playClick(
          audioCtx,
          masterGainNode,
          /* isAccent */ false,
          soundObj,
          1.0,
          beepTime
        );
      }
    }

    // ALWAYS check for nested tuplets, so they can play independently
    if (tup.nestedTuplets?.length) {
      tup.nestedTuplets.forEach((nested) => {
        // If the nested tuplet is muted, skip it:
        if (nested.muted) return;
        if (!nested.selectedBeats || nested.selectedBeats.length < 1) return;
        if (!nested.subCount || nested.subCount < 1) return;

        const nestedMin = Math.min(...nested.selectedBeats);
        const nestedMax = Math.max(...nested.selectedBeats);
        if (i + 1 === nestedMin) {
          const nestedSpanCount = nestedMax - nestedMin + 1;
          const nestedTotalSec = nestedSpanCount * subIntervalSec;

          scheduleNestedTuplet(
            audioCtx,
            masterGainNode,
            nested,
            beepTime,
            nestedTotalSec
          );
        }
      });
    }
  }

  // Optional chord/drone:
  if (firstBeatDrone && firstBeatDrone.dronePitch !== "off" && !muteDrones) {
    let chordTime = startSec;
    if (chordTime < audioCtx.currentTime) {
      chordTime = audioCtx.currentTime + 0.01;
    }
    scheduleDroneOrChordChange(
      audioCtx,
      masterGainNode,
      partialOscRefs,
      chordOscRefs,
      firstBeatDrone,
      chordTime,
      aTuning,
      activeChordRef,
      subIntervalSec,
      totalSec,
      muteDrones,
      loopPitchOffsetRef,
      modulateOnLoop,
      subscriptionTier,
      muteRandomDronesRef.current,
      muteRandomDronePercentageRef.current,
      globalMeasureCountRef.current
    );
  }
}

/**
 * scheduleNestedTuplet: produce nested.subCount clicks over nestedTotalSec
 * from beepTime → beepTime + nestedTotalSec
 */
function scheduleNestedTuplet(
  audioCtx,
  masterGainNode,
  nested,
  beepTime,
  nestedTotalSec
) {
  // If the nested tuplet is muted, skip it entirely.
  if (nested.muted) return;

  if (!nested.subCount || nested.subCount < 1) return;

  const nestedIntervalSec = nestedTotalSec / nested.subCount;
  for (let k = 0; k < nested.subCount; k++) {
    let nestTime = beepTime + k * nestedIntervalSec;
    if (nestTime < audioCtx.currentTime) {
      nestTime = audioCtx.currentTime + 0.01;
    }

    // Check if this subdivision should be muted
    const subdivisionNumber = k + 1; // 1-indexed like beats
    const isMuted = (nested.mutedSubdivisions || []).includes(
      subdivisionNumber
    );

    if (!isMuted) {
      // Example: find the nested sound
      const nestedSound = SOUND_OPTIONS.find(
        (opt) => opt.id === nested.soundId
      );
      if (nestedSound) {
        playClick(audioCtx, masterGainNode, false, nestedSound, 1.0, nestTime);
      }
    }
  }
}

/**
 * The scheduling hook (Alternative Approach):
 * We schedule as many beats as needed in a while‐loop,
 * storing each scheduled beat’s start/end times, and
 * highlight the currently playing measure/beat on each frame.
 */

export function useLookaheadScheduler(
  isPlaying,
  measures,
  tempo,
  useTempoChanges,
  enableAdditionalTempoChanges,
  tempoChanges,
  muteRandomBeats,
  mutePercentage,
  loopMode,
  masterGain,
  aTuning,
  activeModulationRef,
  setModulationDisplay,
  currentBarRef,
  currentBeatRef,
  setCurrentBar,
  setCurrentBeat,
  globalMeasureCountRef,
  partialOscRefs,
  chordOscRefs,
  audioContextRef,
  masterGainNodeRef,
  activeChordRef,
  loopCountRef,
  muteBeats,
  muteDrones,
  muteTuplets,
  muteSubs,
  stressOn,
  countInQueueRef,
  loopStartMeasure,
  loopEndMeasure,
  modulateOnLoop,
  modLoopInterval,
  modLoopDirection,
  loopPitchOffsetRef,
  subscriptionTier,
  selectedNoteValue,
  muteRandomDrones,
  muteRandomDronePercentage
) {
  // 1) Keep a reference to the old tempo for proportional scaling
  const oldTempoRef = useRef(tempo);

  // 2) Optionally scale all start/end/step tempos whenever the *main* tempo changes
  useEffect(() => {
    if (isPlaying && tempo !== oldTempoRef.current && oldTempoRef.current > 0) {
      const ratio = tempo / oldTempoRef.current;
      tempoChanges.forEach((tc) => {
        if (tc.startTempo != null) {
          tc.startTempo = Math.round(tc.startTempo * ratio);
        }
        if (tc.endTempo != null) {
          tc.endTempo = Math.round(tc.endTempo * ratio);
        }
        if (tc.stepSize != null) {
          tc.stepSize = Math.round(tc.stepSize * ratio);
        }
      });
    }
    oldTempoRef.current = tempo;
  }, [tempo, isPlaying, tempoChanges]);

  const scheduleAheadTime = 0.15; // 150ms
  const measureEndTimeRef = useRef(0);
  const schedulerIdRef = useRef(null);

  // Keep track of all scheduled beats for the child <BeatHighlighter />
  const scheduledBeatsRef = useRef([]);

  let lastBeatSecValue = 0;

  const schedulerLoop = useCallback(() => {
    if (!audioContextRef.current) return;
    const audioCtx = audioContextRef.current;
    const now = audioCtx.currentTime;

    // 1) Clean up old beats from scheduledBeatsRef that finished long ago
    scheduledBeatsRef.current = scheduledBeatsRef.current.filter(
      (b) => b.endTimeSec > now - 0.5
    );

    // 2) SCHEDULE NEW BEATS IF WE DON'T HAVE ENOUGH SCHEDULED
    while (measureEndTimeRef.current < now + scheduleAheadTime) {
      // -- Handle any queued COUNT-IN measures first --
      if (countInQueueRef.current.length > 0) {
        const ci = countInQueueRef.current[0];

        // e.g. use a default or override tempo for the count-in
        const barTempo = ci.overrideTempo ?? 60;

        const noteValueFactor = selectedNoteValue?.factor ?? 1;

        // Calculate the proper tempo that accounts for time signature denominator
        // This matches how regular measures are calculated
        const finalBPM = barTempo * (noteValueFactor * (ci.denominator / 4));

        // Then convert to ms/click
        const beatMs = 60000 / finalBPM;
        const beatSec = beatMs / 1000;

        const measureStartTime = measureEndTimeRef.current;

        // SCHEDULE each count-in beat
        for (let b = 0; b < ci.numerator; b++) {
          const beepTime = measureStartTime + b * beatSec;

          // Audio click:
          playClick(
            audioCtx,
            masterGainNodeRef.current,
            b === 0,
            { id: "countInClick", userVolume: 1.0, type: "sine" },
            1.0,
            beepTime
          );

          // We'll store in scheduledBeatsRef for highlight.
          scheduledBeatsRef.current.push({
            measureIndex: -9999, // or any placeholder
            beatIndex: b,
            startTimeSec: beepTime,
            endTimeSec: beepTime + beatSec,
          });
          console.log(
            "Scheduled beat:",
            currentBarRef.current,
            currentBeatRef.current
          );
        }

        measureEndTimeRef.current += ci.numerator * beatSec;
        countInQueueRef.current.shift();
        continue;
      }

      // Otherwise, schedule normal measures
      const measureIndex = currentBarRef.current % measures.length;
      const measureObj = measures[measureIndex];

      // 3) Compute effective tempo for this bar
      const scheduledBar = currentBarRef.current;
      const scheduledBeat = currentBeatRef.current;
      const effectiveTempo = computeEffectiveTempo(
        scheduledBar,
        scheduledBeat,
        measureObj.numerator,
        tempo,
        useTempoChanges,
        tempoChanges,
        loopCountRef
      );

      const noteValueFactor = selectedNoteValue?.factor ?? 1;

      // 1) 'effectiveTempo' is the BPM of the chosen note (base tempo or modded).
      // 2) measureObj.denominator is your time signature denominator (e.g. 8).
      // 3) noteValueFactor is how many quarter‐notes your chosen note is (e.g. 1.5 if dotted quarter).

      const finalBPM =
        effectiveTempo * (noteValueFactor * (measureObj.denominator / 4));

      // Then convert to ms/click
      let beatMs = 60000 / finalBPM;

      // If previous measure had a metric modulation
      if (
        scheduledBeat === 0 &&
        scheduledBar > 0 &&
        measures[(scheduledBar - 1) % measures.length].hasModulation
      ) {
        const prevMeas = measures[(scheduledBar - 1) % measures.length];
        activeModulationRef.current = {
          isActive: true,
          fromSubdivision: prevMeas.fromSubdivision,
          toSubdivision: prevMeas.toSubdivision,
          sourceTempoReference: effectiveTempo,
        };
        setModulationDisplay({
          isActive: true,
          fromName: prevMeas.fromSubdivision.name,
          toName: prevMeas.toSubdivision.name,
        });
      }

      // 4) apply metric modulation if active
      if (activeModulationRef.current.isActive) {
        // If a modulation is active, it overrides beatMs, e.g. to
        // match fromSubdivision -> toSubdivision:
        beatMs = applyModulation(
          activeModulationRef,
          effectiveTempo,
          measureObj.denominator,
          noteValueFactor
        );
        let secondsPerUserBeat = beatMs / 1000; // keep them in sync
      }

      const beatSec = beatMs / 1000;
      const measureDurationSec = measureObj.numerator * beatSec;

      let thisMeasureStartSec;
      if (currentBeatRef.current === 0) {
        thisMeasureStartSec = measureEndTimeRef.current;
      } else {
        thisMeasureStartSec =
          measureEndTimeRef.current - currentBeatRef.current * lastBeatSecValue;
      }

      const thisBeatStart = measureEndTimeRef.current;

      // Possibly random mutes
      const currentMeasure = globalMeasureCountRef.current;
      const transitionMeasures = 3;
      const startMeasure = 3; // When we start applying muting
      let effectiveMutePercentage = 0;

      if (currentMeasure >= startMeasure) {
        // Calculate transition percentage (0 to 1) over the transition period
        const transitionProgress = Math.min(
          1,
          (currentMeasure - startMeasure) / transitionMeasures
        );

        // Apply the transition to the target mute percentage
        effectiveMutePercentage = mutePercentage * transitionProgress;
      }

      const doPlay =
        !muteRandomBeats ||
        currentMeasure < startMeasure ||
        Math.random() * 100 > effectiveMutePercentage;

      if (scheduledBeat < measureObj.beatSounds.length) {
        if (!measureObj.onlyTuplets && doPlay) {
          const isMainAccent = scheduledBeat === 0;
          let beatSound = measureObj.beatSounds[scheduledBeat];

          // If "applyBeatOneDroneToAllBeats" is on
          if (
            measureObj.applyBeatOneDroneToAllBeats &&
            measureObj.beatSounds[0]
          ) {
            const firstDrone = measureObj.beatSounds[0];
            beatSound = {
              ...beatSound,
              dronePitchNote: firstDrone.dronePitchNote,
              dronePitchOctave: firstDrone.dronePitchOctave,
              droneVolume: firstDrone.droneVolume,
              hasHarmony: firstDrone.hasHarmony,
              selectedHarmony: firstDrone.selectedHarmony,
              justIntonation: firstDrone.justIntonation,
              usePartials: firstDrone.usePartials,
              selectedPartials: [...firstDrone.selectedPartials],
              useQuarterTones: firstDrone.useQuarterTones,
              selectedQuarterTones: [
                ...(firstDrone.selectedQuarterTones || []),
              ],
            };
          }

          // Subdivisions
          beatSound.subdivisions.forEach((sub, subIndex) => {
            const subCount = sub.subCount || 1;
            const subInterval = beatSec / subCount;

            for (let s = 0; s < subCount; s++) {
              const beepTime =
                audioCtx.currentTime +
                (thisBeatStart - audioCtx.currentTime) +
                s * subInterval;

              const isMainBeat = subIndex === 0 && s === 0;
              const isAccent =
                sub.accentedSubs.includes(s + 1) ||
                (isMainBeat && isMainAccent);

              const isMuted = sub.mutedSubs.includes(s + 1);
              if (isMuted) {
                continue; // skip playing
              }

              if (isMainBeat) {
                if (!muteBeats) {
                  playClick(
                    audioCtx,
                    masterGainNodeRef.current,
                    isAccent,
                    beatSound,
                    beatSound.beatVolume,
                    beepTime
                  );
                }
              } else {
                if (!muteSubs) {
                  playClick(
                    audioCtx,
                    masterGainNodeRef.current,
                    isAccent,
                    beatSound,
                    beatSound.beatVolume,
                    beepTime
                  );
                }
              }
            }
          });
        }
      }

      // Drone scheduling
      scheduleDroneOrChordChange(
        audioCtx,
        masterGainNodeRef.current,
        partialOscRefs,
        chordOscRefs,
        measureObj.beatSounds[scheduledBeat],
        thisBeatStart,
        aTuning,
        activeChordRef,
        beatSec,
        measureDurationSec,
        muteDrones,
        loopPitchOffsetRef,
        modulateOnLoop,
        subscriptionTier,
        muteRandomDrones, // Check this value is correctly passed
        muteRandomDronePercentage, // Check this value is correctly passed
        globalMeasureCountRef.current
      );

      // Tuplets
      if (doPlay && measureObj.tuplets?.length > 0 && !muteTuplets) {
        measureObj.tuplets.forEach((tup) => {
          if (!tup.selectedBeats || tup.selectedBeats.length === 0) return;
          const minBeat = Math.min(...tup.selectedBeats);
          if (scheduledBeat + 1 === minBeat) {
            const rangeBeats = Math.max(...tup.selectedBeats) - minBeat + 1;
            const totalSec = rangeBeats * beatSec;
            const startSec = thisMeasureStartSec + scheduledBeat * beatSec;
            const firstBeatDrone = measureObj.applyBeatOneDroneToAllBeats
              ? measureObj.beatSounds[0]
              : null;

            scheduleTuplet(
              audioCtx,
              masterGainNodeRef.current,
              partialOscRefs,
              chordOscRefs,
              tup,
              startSec,
              totalSec,
              firstBeatDrone,
              aTuning,
              activeChordRef,
              muteDrones,
              tup.muted
            );
          }
        });
      }

      // Collect the scheduled beat for highlight
      scheduledBeatsRef.current.push({
        measureIndex: currentBarRef.current,
        beatIndex: currentBeatRef.current,
        startTimeSec: thisBeatStart,
        endTimeSec: thisBeatStart + beatSec,
      });

      measureEndTimeRef.current += beatSec;
      currentBeatRef.current++;

      // If we finished the measure, reset and increment
      if (currentBeatRef.current >= measureObj.numerator) {
        currentBeatRef.current = 0;
        const oldBar = currentBarRef.current;
        currentBarRef.current++;
        globalMeasureCountRef.current++;

        // "specific" incremental
        for (let c = 0; c < tempoChanges.length; c++) {
          const change = tempoChanges[c];
          if (change.type === "incremental" && change.scope === "specific") {
            const barArr = parseBarString(change.specificBars || "");
            if (!barArr.length) continue;
            const minBar = Math.min(...barArr);
            const maxBar = Math.max(...barArr);

            if (oldBar === maxBar && currentBarRef.current === minBar) {
              if (!change.localLoopCount) {
                change.localLoopCount = 0;
              }
              change.localLoopCount++;
            }
          }
        }
        // possibly clamp bar index:
        for (let c = 0; c < tempoChanges.length; c++) {
          const change = tempoChanges[c];
          if (change.type === "incremental" && change.scope === "specific") {
            const barArr = parseBarString(change.specificBars || "");
            if (!barArr.length) continue;
            const minBar = Math.min(...barArr);
            const maxBar = Math.max(...barArr);

            if (currentBarRef.current > maxBar) {
              currentBarRef.current = minBar;
              if (!change.localLoopCount) {
                change.localLoopCount = 0;
              }
              change.localLoopCount++;
            }
          }
        }

        // Normal looping logic
        if (currentBarRef.current >= measures.length && loopMode === "loop") {
          currentBarRef.current = 0;
          activeModulationRef.current.isActive = false;
          setModulationDisplay({ isActive: false, fromName: "", toName: "" });
          activeChordRef.current.signature = "";

          // If incremental tempo changes are "whole" scope, increment counters
          for (let c = 0; c < tempoChanges.length; c++) {
            const change = tempoChanges[c];
            if (change.type === "incremental" && change.scope === "whole") {
              loopCountRef.current++;
            }
          }

          // If user wants pitch mod on each loop:
          if (modulateOnLoop) {
            const sign = modLoopDirection === "up" ? +1 : -1;
            loopPitchOffsetRef.current += sign * modLoopInterval;
          }
        } else if (
          currentBarRef.current >= measures.length &&
          loopMode === "continue"
        ) {
          currentBarRef.current = measures.length - 1;
        } else if (
          loopMode === "specific" &&
          currentBarRef.current >= loopEndMeasure
        ) {
          currentBarRef.current = loopStartMeasure - 1;
          activeModulationRef.current.isActive = false;
          setModulationDisplay({ isActive: false, fromName: "", toName: "" });
        }
      }

      lastBeatSecValue = beatSec;
    }

    // 7) Loop again
    schedulerIdRef.current = requestAnimationFrame(schedulerLoop);
  }, [
    isPlaying,
    measures,
    tempo,
    useTempoChanges,
    enableAdditionalTempoChanges,
    tempoChanges,
    muteRandomBeats,
    mutePercentage,
    loopMode,
    aTuning,
    currentBarRef,
    currentBeatRef,
    setCurrentBar,
    setCurrentBeat,
    globalMeasureCountRef,
    partialOscRefs,
    chordOscRefs,
    audioContextRef,
    masterGainNodeRef,
    activeModulationRef,
    setModulationDisplay,
    activeChordRef,
    muteBeats,
    muteDrones,
    muteTuplets,
    muteSubs,
    stressOn,
    countInQueueRef,
    loopStartMeasure,
    loopEndMeasure,
    loopCountRef,
    modulateOnLoop,
    modLoopInterval,
    modLoopDirection,
    loopPitchOffsetRef,
    selectedNoteValue,
    muteRandomDrones,
    muteRandomDronePercentage,
  ]);

  // Smoothly update the master gain
  useEffect(() => {
    if (audioContextRef.current && masterGainNodeRef.current) {
      const now = audioContextRef.current.currentTime;
      masterGainNodeRef.current.gain.cancelScheduledValues(now);
      masterGainNodeRef.current.gain.setValueAtTime(
        masterGainNodeRef.current.gain.value,
        now
      );
      masterGainNodeRef.current.gain.linearRampToValueAtTime(
        masterGain,
        now + 0.1
      );
    }
  }, [masterGain, audioContextRef, masterGainNodeRef]);

  // Start/stop scheduling
  useEffect(() => {
    if (!isPlaying) {
      // Reset pitch offset
      loopPitchOffsetRef.current = 0;
      // Cancel the scheduler
      if (schedulerIdRef.current) {
        cancelAnimationFrame(schedulerIdRef.current);
        schedulerIdRef.current = null;
      }
      return;
    }

    if (audioContextRef.current) {
      // Add a pre-scheduling buffer time to ensure first measure is accurate
      // This ensures we have enough time to schedule all beats properly
      const bufferTime = 0.2; // 200ms buffer before first beat
      measureEndTimeRef.current =
        audioContextRef.current.currentTime + bufferTime;
    }

    schedulerIdRef.current = requestAnimationFrame(schedulerLoop);

    return () => {
      if (schedulerIdRef.current) {
        cancelAnimationFrame(schedulerIdRef.current);
        schedulerIdRef.current = null;
      }
    };
  }, [isPlaying, schedulerLoop, audioContextRef, loopPitchOffsetRef]);

  return { measureEndTimeRef, schedulerIdRef, scheduledBeatsRef };
}

/** 3) Main Component */
export default function MetronomeComponent() {
  // Tab switch state
  const [activeTab, setActiveTab] = useState("metronome");

  const [showTutorial, setShowTutorial] = useState(true);
  const [tutorialCompleted, setTutorialCompleted] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [tempo, setTempo] = useState(INITIAL_TEMPO);
  const MAX_TAPS = 8;
  const [tapTimes, setTapTimes] = useState([]);
  const [muteRandomBeats, setMuteRandomBeats] = useState(INITIAL_MUTE_RANDOM);
  const [mutePercentage, setMutePercentage] = useState(INITIAL_MUTE_PERCENT);
  const [muteRandomDrones, setMuteRandomDrones] = useState(false);
  const [muteRandomDronePercentage, setMuteRandomDronePercentage] =
    useState(50);

  useEffect(() => {
    // Expose current state values for direct access
    window.metronomeComponentState = {
      muteRandomDrones,
      muteRandomDronePercentage,
    };
  }, [muteRandomDrones, muteRandomDronePercentage]);

  const muteRandomDronesRef = useRef(false);
  const muteRandomDronePercentageRef = useRef(50);

  // Then update your state setters to also update the refs
  const updateMuteRandomDrones = (value) => {
    setMuteRandomDrones(value);
    muteRandomDronesRef.current = value;

    if (!value) {
      globalMeasureCountRef.current = 0;
    }
  };

  const updateMuteRandomDronePercentage = (value) => {
    setMuteRandomDronePercentage(value);
    muteRandomDronePercentageRef.current = value;
  };

  const [loopMode, setLoopMode] = useState(INITIAL_LOOP_MODE);
  const [loopStartMeasure, setLoopStartMeasure] = useState(1);
  const [loopEndMeasure, setLoopEndMeasure] = useState(1);

  const [isOverviewCollapsed, setIsOverviewCollapsed] = useState(false);

  const [showInfo, setShowInfo] = useState(false);
  const [isTempoLocked, setIsTempoLocked] = useState(false);

  const [masterGain, setMasterGain] = useState(0.7);
  const [aTuning, setATuning] = useState(440);

  const [muteBeats, setMuteBeats] = useState(false);
  const [muteDrones, setMuteDrones] = useState(false);
  const [muteTuplets, setMuteTuplets] = useState(false);
  const [muteSubs, setMuteSubs] = useState(false);

  const [stressOn, setStressOn] = useState(false);

  const [highlightedMeasures, setHighlightedMeasures] = useState([]);
  const [highlightInput, setHighlightInput] = useState("");

  // let user label the first measure as #1, #12, etc.
  const [startMeasureNumber, setStartMeasureNumber] = useState(1);

  const [selectedNoteValue, setSelectedNoteValue] = useState({
    label: "Quarter Note",
    factor: 1,
  });

  // measures
  const [measures, setMeasures] = useState(() => {
    const first = createNewMeasure(stressOn); // or (stressOn) if you want
    first.measureNumber = 1;
    return [first];
  });
  const [currentBeat, setCurrentBeat] = useState(0);
  const [currentBar, setCurrentBar] = useState(0);
  const globalMeasureCountRef = useRef(0);

  // tempo changes
  const [useTempoChanges, setUseTempoChanges] = useState(false);
  const [enableAdditionalTempoChanges, setEnableAdditionalTempoChanges] =
    useState(false);
  const [tempoChanges, setTempoChanges] = useState([
    { ...DEFAULT_TEMPO_CHANGE },
  ]);
  const oldTempoRef = useRef(tempo);

  const [nestedTupletsUI, setNestedTupletsUI] = useState(null);

  const loopCountRef = useRef(0);
  const [countInMeasures, setCountInMeasures] = useState(0);
  const countInQueueRef = useRef([]);

  const activeModulationRef = useRef({
    isActive: false,
    fromSubdivision: null,
    toSubdivision: null,
    sourceTempoReference: tempo,
  });
  const [modulationDisplay, setModulationDisplay] = useState({
    isActive: false,
    fromName: "",
    toName: "",
  });

  const [editingMeasureIndex, setEditingMeasureIndex] = useState(null);
  const [tempNumber, setTempNumber] = useState("");
  const [ghostNumer, setGhostNumer] = useState(4);
  const [ghostDenom, setGhostDenom] = useState(4);

  // patterns
  const [patternName, setPatternName] = useState("");
  const [savedPatterns, setSavedPatterns] = useState([]);
  const [folderName, setFolderName] = useState("Uncategorized");
  const [selectedFolder, setSelectedFolder] = useState("");
  const [newFolderName, setNewFolderName] = useState("");
  const [selectedFolderForDeletion, setSelectedFolderForDeletion] =
    useState("");

  // audio references
  const audioContextRef = useRef(null);
  const masterGainNodeRef = useRef(null);
  const partialOscRefs = useRef([]);
  const chordOscRefs = useRef([]);

  // new: track the active chord so we can skip re-initializing if it hasn’t changed
  const activeChordRef = useRef({ signature: "" });

  // scheduling references
  const currentBarRef = useRef(0);
  const currentBeatRef = useRef(0);
  const loopPitchOffsetRef = useRef(0);

  const [collapsedBeats, setCollapsedBeats] = useState({});

  const [bulkEditorOpen, setBulkEditorOpen] = useState(false);
  const [bulkEditorResetCount, setBulkEditorResetCount] = useState(0);

  const measureDetailRefs = useRef([]); // array of references

  const patternOverviewRef = useRef(null);

  const [modulateOnLoop, setModulateOnLoop] = useState(false);
  const [modLoopInterval, setModLoopInterval] = useState(1); // will store semitones
  const [modLoopDirection, setModLoopDirection] = useState("up");

  const [currentUser, setCurrentUser] = useState(null);
  const [subscriptionTier, setSubscriptionTier] = useState("free");

  // Effect to check tutorial status on mount
  useEffect(() => {
    const checkTutorialStatus = () => {
      const completed = localStorage.getItem("tutorialCompleted") === "true";
      setTutorialCompleted(completed);
      setShowTutorial(!completed);
    };
    checkTutorialStatus();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      // If user is logged in, fetch their tier
      if (user) {
        fetchUserTier(user.uid);
      } else {
        setSubscriptionTier("free");
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!currentUser) {
      // If logged out, clear any previously loaded patterns
      setSavedPatterns([]);
      return;
    }

    // Listen to the current user’s patterns subcollection
    const patternsRef = collection(db, "users", currentUser.uid, "patterns");
    const unsubscribe = onSnapshot(patternsRef, (snapshot) => {
      const loaded = snapshot.docs.map((docSnap) => {
        return {
          // docSnap.id is the pattern’s unique ID in Firestore
          id: docSnap.id,
          // Spread all the fields from Firestore
          ...docSnap.data(),
        };
      });
      setSavedPatterns(loaded);
    });

    return () => unsubscribe();
  }, [currentUser]);

  async function handleGoogleSignIn() {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      console.log("Google sign-in success:", user.uid);

      // 1) Check if the user doc exists in Firestore
      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);

      if (!docSnap.exists()) {
        // 2) If no doc, create one
        await setDoc(userRef, {
          email: user.email,
          subscriptionTier: "free",
          createdAt: serverTimestamp(),
        });
      }

      // Now update your UI state as needed:
      setCurrentUser(user);
      setShowLoginForm(false);
      setMenuOpen(false);
    } catch (err) {
      console.error("Google sign-in error:", err);
    }
  }

  async function handleSignOut(setShowLoginForm) {
    try {
      await signOut(auth);
      setCurrentUser(null);
      console.log("User signed out");
      setShowLoginForm(false);
      setMenuOpen(false);
    } catch (err) {
      console.error("Sign-out error:", err);
    }
  }

  // The async function to fetch the user’s doc:
  async function fetchUserTier(uid) {
    try {
      const docRef = doc(db, "users", uid);
      const snap = await getDoc(docRef);
      if (snap.exists()) {
        const userData = snap.data();
        setSubscriptionTier(userData.subscriptionTier || "free");
      } else {
        setSubscriptionTier("free");
      }
    } catch (error) {
      console.error("Failed to fetch tier:", error);
      setSubscriptionTier("free");
    }
  }

  /** Toggles the “beats” section for the measure at `mIndex`. */
  function toggleBeatsCollapse(mIndex) {
    setCollapsedBeats((prev) => ({
      ...prev,
      [mIndex]: !prev[mIndex],
    }));
  }

  function returnToOverview() {
    if (patternOverviewRef.current) {
      patternOverviewRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }

  // We'll define our jump function (scroll + expand) shortly:
  function jumpToMeasure(mIndex) {
    // If you have a collapsed state, open it so the measure is visible:
    setCollapsedBeats((prev) => ({
      ...prev,
      [mIndex]: false,
    }));

    // Then scroll smoothly to the measure’s div:
    const targetEl = measureDetailRefs.current[mIndex];
    if (targetEl) {
      targetEl.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  function handleMeasureNumberChange(changedIndex, newNumValue) {
    const newNumber = parseInt(newNumValue, 10);
    if (isNaN(newNumber)) return;

    setMeasures((prev) => {
      const arr = [...prev];
      const oldNumber = arr[changedIndex].measureNumber;
      const diff = newNumber - oldNumber;

      // Shift *all* measures by the same difference
      for (let i = 0; i < arr.length; i++) {
        arr[i].measureNumber += diff;
      }

      // If you want to ensure no measure goes below 1, you can clamp:
      /*
      for (let i = 0; i < arr.length; i++) {
        arr[i].measureNumber = Math.max(1, arr[i].measureNumber);
      }
      */

      // Reapply volumes/dynamics if needed
      applyStressVolumesIfAny(arr, stressOn);
      resetAllBeatVolumesToUserVolumes(arr);
      applyAllDynamicsToAllMeasures(arr);

      return arr;
    });
  }

  function getNextDenominatorUp(current) {
    const idx = validDenominators.indexOf(current);
    return idx < validDenominators.length - 1
      ? validDenominators[idx + 1]
      : current;
  }

  function getNextDenominatorDown(current) {
    const idx = validDenominators.indexOf(current);
    return idx > 0 ? validDenominators[idx - 1] : current;
  }

  function scaleTempoChanges(ratio) {
    setTempoChanges((prev) =>
      prev.map((tc) => ({
        ...tc,
        // only scale if they exist
        startTempo: Math.round(tc.startTempo * ratio),
        endTempo: Math.round(tc.endTempo * ratio),
        stepSize: tc.stepSize != null ? tc.stepSize * ratio : undefined,
        // if you want to keep it integer:
        // stepSize: Math.round(tc.stepSize * ratio)
      }))
    );
  }

  useEffect(() => {
    // If the new tempo is different, scale the changes
    const oldTempo = oldTempoRef.current;
    if (oldTempo !== tempo && oldTempo > 0) {
      const ratio = tempo / oldTempo;
      scaleTempoChanges(ratio);
    }
    oldTempoRef.current = tempo;
  }, [tempo]);

  function handleDeleteMeasureInOverview(mIndex) {
    setMeasures((prev) => {
      // If you only want to allow deletion if there’s more than one measure:
      if (prev.length > 1) {
        const newArr = [...prev];
        newArr.splice(mIndex, 1);
        return newArr;
      }
      // Otherwise, do nothing or reset to a default:
      return prev;
    });
  }

  async function deleteFolder(folderNameToDelete) {
    if (!currentUser) return;

    const patternsRef = collection(db, "users", currentUser.uid, "patterns");
    const q = query(patternsRef, where("folder", "==", folderNameToDelete));

    const snapshot = await getDocs(q);
    if (snapshot.empty) {
      alert(`No patterns found in folder "${folderNameToDelete}"`);
      return;
    }

    const confirmed = window.confirm(
      `Are you sure you want to move all patterns in folder "${folderNameToDelete}" to "Uncategorized"?`
    );
    if (!confirmed) return;

    const updates = [];
    snapshot.forEach((docSnap) => {
      updates.push(
        updateDoc(docSnap.ref, {
          folder: "Uncategorized",
          updatedAt: serverTimestamp(),
        })
      );
    });

    await Promise.all(updates);
    alert(
      `Moved all patterns from "${folderNameToDelete}" to "Uncategorized".`
    );
  }

  function handleExportMIDI() {
    // You have `measures` and maybe `tempo`, `patternName` in this component’s state:
    const fileName = patternName ? `${patternName}.mid` : "FlowFrame.mid";
    exportMIDIImplementation(measures, tempo, fileName);
  }

  function handleGroupingCheckbox(measureIndex, groupingKey, isChecked) {
    setMeasures((prevMeasures) => {
      const newMeasures = [...prevMeasures];
      const m = newMeasures[measureIndex];
      if (!m) return prevMeasures;

      m[groupingKey] = isChecked;

      // If 5/4, 5/8, 5/16, or 5/32:
      if (m.numerator === 5 && [4, 8, 16, 32].includes(m.denominator)) {
        const active5Groupings = [];
        if (m.g2plus3) active5Groupings.push("2+3");
        if (m.g3plus2) active5Groupings.push("3+2");
        if (active5Groupings.length === 1) {
          // Pass `newMeasures` to the function:
          applyFiveGrouping(m, active5Groupings[0], newMeasures);
        } else {
          revertGroupingToDefault(m, newMeasures);
        }
      }

      // If 7/4, 7/8, 7/16, or 7/32:
      if (m.numerator === 7 && [4, 8, 16, 32].includes(m.denominator)) {
        const active7Groupings = [];
        if (m.g2p2p3) active7Groupings.push("2+2+3");
        if (m.g3p2p2) active7Groupings.push("3+2+2");
        if (m.g2p3p2) active7Groupings.push("2+3+2");
        if (active7Groupings.length === 1) {
          // Same idea:
          applySevenGrouping(m, active7Groupings[0], newMeasures);
        } else {
          revertGroupingToDefault(m, newMeasures);
        }
      }

      return newMeasures;
    });
  }

  // Now the grouping functions have a third param: `newMeasures`

  function applyFiveGrouping(measureObj, pattern, newMeasures) {
    if (!measureObj.beatSounds || measureObj.beatSounds.length < 5) return;

    if (pattern === "2+3") {
      measureObj.beatSounds[0].userVolume = 1.0;
      measureObj.beatSounds[1].userVolume = 0.3;
      measureObj.beatSounds[2].userVolume = 1.0;
      measureObj.beatSounds[3].userVolume = 0.3;
      measureObj.beatSounds[4].userVolume = 0.3;
    } else {
      measureObj.beatSounds[0].userVolume = 1.0;
      measureObj.beatSounds[1].userVolume = 0.3;
      measureObj.beatSounds[2].userVolume = 0.3;
      measureObj.beatSounds[3].userVolume = 1.0;
      measureObj.beatSounds[4].userVolume = 0.3;
    }

    resetAllBeatVolumesToUserVolumes(newMeasures);
    applyAllDynamicsToAllMeasures(newMeasures);
  }

  function applySevenGrouping(measureObj, pattern, newMeasures) {
    if (!measureObj.beatSounds || measureObj.beatSounds.length < 7) return;

    if (pattern === "2+2+3") {
      measureObj.beatSounds[0].userVolume = 1.0;
      measureObj.beatSounds[1].userVolume = 0.3;
      measureObj.beatSounds[2].userVolume = 1.0;
      measureObj.beatSounds[3].userVolume = 0.3;
      measureObj.beatSounds[4].userVolume = 1.0;
      measureObj.beatSounds[5].userVolume = 0.3;
      measureObj.beatSounds[6].userVolume = 0.3;
    } else if (pattern === "3+2+2") {
      measureObj.beatSounds[0].userVolume = 1.0;
      measureObj.beatSounds[1].userVolume = 0.3;
      measureObj.beatSounds[2].userVolume = 0.3;
      measureObj.beatSounds[3].userVolume = 1.0;
      measureObj.beatSounds[4].userVolume = 0.3;
      measureObj.beatSounds[5].userVolume = 1.0;
      measureObj.beatSounds[6].userVolume = 0.3;
    } else {
      // "2+3+2"
      measureObj.beatSounds[0].userVolume = 1.0;
      measureObj.beatSounds[1].userVolume = 0.3;
      measureObj.beatSounds[2].userVolume = 1.0;
      measureObj.beatSounds[3].userVolume = 0.3;
      measureObj.beatSounds[4].userVolume = 0.3;
      measureObj.beatSounds[5].userVolume = 1.0;
      measureObj.beatSounds[6].userVolume = 0.3;
    }

    resetAllBeatVolumesToUserVolumes(newMeasures);
    applyAllDynamicsToAllMeasures(newMeasures);
  }

  function revertGroupingToDefault(measureObj, newMeasures) {
    measureObj.beatSounds.forEach((beat) => {
      beat.userVolume = 1.0;
    });

    resetAllBeatVolumesToUserVolumes(newMeasures);
    applyAllDynamicsToAllMeasures(newMeasures);
  }

  function applyBulkMeasureText(inputText) {
    // Split into lines
    const lines = inputText
      .split("\n")
      .map((ln) => ln.trim())
      .filter((ln) => ln.length > 0);

    if (!lines.length) return;

    setMeasures((prevMeasures) => {
      let newMeasures = [...prevMeasures];

      lines.forEach((line) => {
        const parsed = parseMeasureSignatureLine(line);
        if (!parsed) {
          console.warn("Couldn’t parse line:", line);
          return; // skip
        }
        const { startMeasure, endMeasure, numerator, denominator } = parsed;
        const maxIndexNeeded = endMeasure - 1; // zero-based

        // 1) Expand measures array if needed
        if (maxIndexNeeded >= newMeasures.length) {
          const needed = maxIndexNeeded - newMeasures.length + 1;
          for (let i = 0; i < needed; i++) {
            newMeasures.push({
              ...createNewMeasure(stressOn),
              measureNumber: newMeasures.length + 1,
            });
          }
        }

        // 2) Update each measure in [startMeasure..endMeasure]
        for (let m = startMeasure - 1; m < endMeasure; m++) {
          const oldMeas = newMeasures[m];

          // Re-size beatSounds to match new numerator
          let updatedBeatSounds = [...oldMeas.beatSounds];

          const oldLength = updatedBeatSounds.length;
          const newLength = numerator; // e.g. "3" if user typed 3/4

          if (newLength > oldLength) {
            // expand
            const extraCount = newLength - oldLength;
            for (let k = 0; k < extraCount; k++) {
              updatedBeatSounds.push(createNewBeat());
            }
          } else if (newLength < oldLength) {
            // truncate
            updatedBeatSounds = updatedBeatSounds.slice(0, newLength);
          }

          newMeasures[m] = {
            ...oldMeas,
            measureNumber: m + 1,
            numerator,
            denominator,
            beatSounds: updatedBeatSounds,
          };
        }
      });

      // 3) Re-apply accent patterns, volumes, etc.
      applyStressVolumesIfAny(newMeasures, stressOn);
      resetAllBeatVolumesToUserVolumes(newMeasures);
      applyAllDynamicsToAllMeasures(newMeasures);

      return newMeasures;
    });
  }

  function handleAddHighlights() {
    const measuresToToggle = parseMeasureRanges(highlightInput);

    setHighlightedMeasures((prev) => {
      const newSet = new Set(prev);
      for (const m of measuresToToggle) {
        if (newSet.has(m)) {
          newSet.delete(m); // remove if existing
        } else {
          newSet.add(m); // else add
        }
      }
      return Array.from(newSet).sort((a, b) => a - b);
    });

    setHighlightInput("");
  }

  function handleApplyBulkEditorChanges(inputText) {
    applyBulkMeasureText(inputText);
    setBulkEditorOpen(false);
  }

  function removeSingleHighlight(measureNum) {
    setHighlightedMeasures((prev) => prev.filter((m) => m !== measureNum));
  }

  function addNewSubdivision(mIndex, bIndex) {
    setMeasures((prev) => {
      // 1) Make a shallow copy of the entire measures array
      const newMeasures = [...prev];

      // 2) Make a shallow copy of the targeted measure
      const measure = { ...newMeasures[mIndex] };
      newMeasures[mIndex] = measure;

      // 3) Make a shallow copy of the measure’s beatSounds array
      const newBeatSounds = [...measure.beatSounds];
      measure.beatSounds = newBeatSounds;

      // 4) Copy the specific beat object
      const oldBeat = newBeatSounds[bIndex];
      const newBeat = { ...oldBeat };
      newBeatSounds[bIndex] = newBeat;

      // 5) Now create a new subdivisions array, instead of mutating in place
      const newSubs = [...newBeat.subdivisions];

      if (newSubs.length < 5) {
        newSubs.push({
          subCount: 1,
          accentedSubs: [],
          mutedSubs: [],
        });
      }

      newBeat.subdivisions = newSubs;

      return newMeasures;
    });
  }

  function removeSubdivision(mIndex, bIndex, subIndex) {
    setMeasures((prev) => {
      const newMeasures = [...prev];
      const measure = newMeasures[mIndex];
      const beat = measure.beatSounds[bIndex];
      beat.subdivisions.splice(subIndex, 1);
      return newMeasures;
    });
  }

  function updateSubdivision(mIndex, bIndex, subIndex, partial) {
    // partial is { subCount: X } or { accentedSubs: [...] }, etc.
    setMeasures((prev) => {
      const newMeasures = [...prev];
      const measure = newMeasures[mIndex];
      const beat = measure.beatSounds[bIndex];
      const oldSub = beat.subdivisions[subIndex];

      beat.subdivisions[subIndex] = { ...oldSub, ...partial };
      return newMeasures;
    });
  }

  const { measureEndTimeRef, schedulerIdRef, scheduledBeatsRef } =
    useLookaheadScheduler(
      isPlaying,
      measures,
      tempo,
      useTempoChanges,
      enableAdditionalTempoChanges,
      tempoChanges,
      muteRandomBeats,
      mutePercentage,
      loopMode,
      masterGain,
      aTuning,
      activeModulationRef,
      setModulationDisplay,
      currentBarRef,
      currentBeatRef,
      setCurrentBar,
      setCurrentBeat,
      globalMeasureCountRef,
      partialOscRefs,
      chordOscRefs,
      audioContextRef,
      masterGainNodeRef,
      activeChordRef,
      loopCountRef,
      muteBeats,
      muteDrones,
      muteTuplets,
      muteSubs,
      stressOn,
      countInQueueRef,
      loopStartMeasure,
      loopEndMeasure,
      modulateOnLoop,
      modLoopInterval,
      modLoopDirection,
      loopPitchOffsetRef,
      subscriptionTier,
      selectedNoteValue
    );

  // Start/Stop
  const handleStartStop = useCallback(() => {
    if (isPlaying) {
      // -- STOPPING --
      setIsPlaying(false);
      setCurrentBeat(0);
      setCurrentBar(0);
      currentBarRef.current = 0;
      currentBeatRef.current = 0;
      globalMeasureCountRef.current = 0;
      activeChordRef.current.signature = "";
      return;
    }

    // -- STARTING --
    loopPitchOffsetRef.current = 0;
    loopCountRef.current = 0;

    let startMeas =
      loopMode === "specific" ? loopStartMeasure : startMeasureNumber;

    if (startMeas < 1) startMeas = 1;
    if (startMeas > measures.length) {
      startMeas = measures.length;
    }

    // Set bar/beat counters so the real pattern starts at user’s chosen measure
    currentBarRef.current = startMeas - 1;
    currentBeatRef.current = 0;
    globalMeasureCountRef.current = 0;
    activeChordRef.current.signature = "";

    // 2) Build one or two “virtual” count-in measures, if requested
    if (countInMeasures > 0) {
      // Identify which real measure we'll start on
      const measureObj = measures[currentBarRef.current];

      // Compute the tempo for that measure (to match the count-in tempo)
      const measureTempo = computeEffectiveTempo(
        currentBarRef.current,
        0,
        measureObj.numerator,
        tempo,
        useTempoChanges,
        tempoChanges,
        loopCountRef
      );

      // Create the count-in “virtual measure(s)”
      const virtualMeasures = [];
      for (let i = 0; i < countInMeasures; i++) {
        virtualMeasures.push({
          numerator: measureObj.numerator, // Ensure this matches the start measure
          denominator: measureObj.denominator, // Ensure this matches the start measure
          // Distinct beatSounds to differentiate the count-in clicks
          beatSounds: Array.from({ length: measureObj.numerator }, () => ({
            id: "countInClick",
            name: "Count-In",
            type: "sine",
            userVolume: 1.0,
            beatVolume: 1.0,
            dronePitchNote: null, // no chord/drone
            subdivisions: [{ subCount: 1, accentedSubs: [] }],
          })),
          isCountIn: true,
          overrideTempo: measureTempo,
        });
      }

      // Store them where your scheduler can see them
      countInQueueRef.current = virtualMeasures;
    } else {
      // No count-in, so empty the queue
      countInQueueRef.current = [];
    }

    // 3) Create AudioContext, Gains, and Oscillators (unchanged)
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    audioContextRef.current = audioCtx;

    const mainGain = audioCtx.createGain();
    mainGain.gain.value = masterGain;
    mainGain.connect(audioCtx.destination);
    masterGainNodeRef.current = mainGain;

    // Partials
    partialOscRefs.current = [];
    for (let i = 0; i < 15; i++) {
      const osc = audioCtx.createOscillator();
      osc.type = "sine";
      const g = audioCtx.createGain();
      g.gain.value = 0;
      osc.connect(g).connect(mainGain);
      osc.start();
      partialOscRefs.current.push({ osc, gainNode: g });
    }

    // Chord oscillators
    chordOscRefs.current = [];
    for (let i = 0; i < 4; i++) {
      const osc = audioCtx.createOscillator();
      osc.type = "sine";
      const g = audioCtx.createGain();
      g.gain.value = 0;
      osc.connect(g).connect(mainGain);
      osc.start();
      chordOscRefs.current.push({ osc, gainNode: g });
    }

    // 4) Reset UI states, as in your original code
    setCurrentBeat(0);
    setCurrentBar(currentBarRef.current);

    setIsPlaying(true);

    // Clear any active modulation
    activeModulationRef.current = {
      isActive: false,
      fromSubdivision: null,
      toSubdivision: null,
      sourceTempoReference: tempo,
    };
    setModulationDisplay({ isActive: false, fromName: "", toName: "" });
  }, [
    isPlaying,
    masterGain,
    tempo,
    startMeasureNumber,
    measures,
    countInMeasures,
    useTempoChanges,
    tempoChanges,
    loopMode,
    loopStartMeasure,
  ]);

  // Cleanup on STOP
  useEffect(() => {
    if (!isPlaying) {
      // 1) Remove leftover highlights
      document
        .querySelectorAll(".highlighted-beat")
        .forEach((el) => el.classList.remove("highlighted-beat"));

      // 2) Cancel any animations
      if (schedulerIdRef.current) {
        cancelAnimationFrame(schedulerIdRef.current);
        schedulerIdRef.current = null;
      }

      // 3) Close & null-out the audio context
      if (audioContextRef.current) {
        try {
          audioContextRef.current.close();
        } catch {}
        audioContextRef.current = null;
      }
      masterGainNodeRef.current = null;

      // 4) Stop and disconnect all partial oscillators
      partialOscRefs.current.forEach(({ osc, gainNode }) => {
        try {
          osc.stop();
        } catch {}
        osc.disconnect();
        gainNode.disconnect();
      });
      partialOscRefs.current = [];

      // 5) Stop and disconnect all chord oscillators
      chordOscRefs.current.forEach(({ osc, gainNode }) => {
        try {
          osc.stop();
        } catch {}
        osc.disconnect();
        gainNode.disconnect();
      });
      chordOscRefs.current = [];
    }
  }, [isPlaying, schedulerIdRef]);

  function handleAddCustomMeasure(numer, denom) {
    setMeasures((prev) => {
      const newNum =
        prev.length > 0 ? prev[prev.length - 1].measureNumber + 1 : 1;

      // Build the new measure object...
      const newMeasure = {
        ...createNewMeasure(stressOn),
        numerator: numer,
        denominator: denom,
        measureNumber: newNum,
      };

      // If createNewMeasure defaults to 4 beats, fix the size:
      const diff = newMeasure.numerator - newMeasure.beatSounds.length;
      if (diff > 0) {
        for (let i = 0; i < diff; i++) {
          newMeasure.beatSounds.push(createNewBeat());
        }
      } else if (diff < 0) {
        newMeasure.beatSounds = newMeasure.beatSounds.slice(
          0,
          newMeasure.numerator
        );
      }

      // Append it and apply your usual re-accent or dynamic logic
      const updated = [...prev, newMeasure];
      applyStressVolumesIfAny(updated, stressOn);
      resetAllBeatVolumesToUserVolumes(updated);
      applyAllDynamicsToAllMeasures(updated);

      return updated;
    });
  }

  // Reset everything
  const handleReset = useCallback(() => {
    // 2) Stop playback and reset basic playback counters
    setIsPlaying(false);
    setCurrentBeat(0);
    setCurrentBar(0);
    globalMeasureCountRef.current = 0;
    loopCountRef.current = 0;

    // 3) Reset tempo and tapping states
    setTempo(INITIAL_TEMPO);
    setTapTimes([]);

    // 4) Reset toggles for random mute, loop mode, and tempo changes
    setMuteRandomBeats(INITIAL_MUTE_RANDOM);
    setMutePercentage(INITIAL_MUTE_PERCENT);
    setLoopMode(INITIAL_LOOP_MODE);
    setUseTempoChanges(false);
    setEnableAdditionalTempoChanges(false);
    setTempoChanges([{ ...DEFAULT_TEMPO_CHANGE }]);

    // 5) Reset all other toggles: stress, beats, subs, tuplets, drones, etc.
    setStressOn(false);
    setMuteBeats(false);
    setMuteSubs(false);
    setMuteDrones(false);
    setMuteTuplets(false);

    // 6) Reset volume/tuning and collapse states
    setMasterGain(0.7);
    setATuning(440);
    setIsOverviewCollapsed(false);

    setSelectedNoteValue({
      label: "Quarter Note",
      factor: 1,
    });

    // 7) Clear measure‐editing states and references
    setEditingMeasureIndex(null);
    setTempNumber("");
    setBulkEditorResetCount((prev) => prev + 1);
    setBulkEditorOpen(false);
    setNestedTupletsUI(null);
    setEditingTupletUI(null);
    setCollapsedBeats({});
    measureDetailRefs.current = {};

    // 8) Reset highlighting states
    setHighlightedMeasures([]);
    setHighlightInput("");

    // 9) Reset pattern/folder UI
    setPatternName("");
    setFolderName("Uncategorized");
    setSelectedFolder("");
    setNewFolderName("");
    setSelectedFolderForDeletion("");

    // 10) Clear chord/partial references
    partialOscRefs.current = [];
    chordOscRefs.current = [];
    activeChordRef.current.signature = "";

    // 11) Build a fresh first measure (measureNumber = 1) and re-apply measure logic
    const firstMeasure = {
      ...createNewMeasure(false),
      measureNumber: 1,
    };
    const newMeasures = [firstMeasure];
    applyStressVolumesIfAny(newMeasures, false);
    resetAllBeatVolumesToUserVolumes(newMeasures);
    applyAllDynamicsToAllMeasures(newMeasures);

    // 12) Force measures state to rebuild (delayed to ensure a clean re-render)
    setMeasures([]);
    setTimeout(() => {
      setMeasures([...newMeasures]);
    }, 0);

    // 13) Reset modulation and measure numbering
    activeModulationRef.current = {
      isActive: false,
      fromSubdivision: null,
      toSubdivision: null,
      sourceTempoReference: INITIAL_TEMPO,
    };
    setModulationDisplay({ isActive: false, fromName: "", toName: "" });
    setStartMeasureNumber(1);
  }, [stressOn]);

  function removeUndefinedDeep(obj) {
    if (Array.isArray(obj)) {
      return obj.map(removeUndefinedDeep);
    } else if (obj && typeof obj === "object") {
      const copy = {};
      for (let [key, val] of Object.entries(obj)) {
        if (val !== undefined) {
          copy[key] = removeUndefinedDeep(val);
        }
      }
      return copy;
    }
    return obj;
  }

  const handleSavePattern = async () => {
    if (!currentUser) {
      alert("Please log in to save patterns.");
      return;
    }

    const nameTrimmed = patternName.trim();
    const folderTrimmed = folderName.trim() || "Uncategorized";

    // 1) Simple name check
    if (!nameTrimmed) {
      alert("Please enter a pattern name.");
      return;
    }

    // 2) Build the new pattern object
    const newPattern = {
      updatedAt: serverTimestamp(),
      name: nameTrimmed,
      folder: folderTrimmed,
      tempo,
      useTempoChanges: !!useTempoChanges,
      enableAdditionalTempoChanges: !!enableAdditionalTempoChanges,
      tempoChanges,
      aTuning,
      muteRandomBeats,
      mutePercentage,
      loopMode,
      measures,
      startMeasureNumber,
      stressOn,
      selectedNoteValue,
    };

    // 3) Remove any undefined fields
    const cleanedData = removeUndefinedDeep(newPattern);

    // 4) Does a pattern with this name & folder already exist?
    // Assuming `savedPatterns` is an array that includes doc IDs,
    // e.g. savedPatterns = [{ id, name, folder, ... }, ...]
    const existingPattern = savedPatterns.find(
      (p) => p.name === nameTrimmed && p.folder === folderTrimmed
    );

    const patternsRef = collection(db, "users", currentUser.uid, "patterns");

    // If there's an existing pattern, prompt to overwrite
    if (existingPattern) {
      const overwriteConfirmed = window.confirm(
        `A pattern named "${nameTrimmed}" already exists in ` +
          `folder "${folderTrimmed}". Do you want to overwrite it?`
      );
      if (!overwriteConfirmed) {
        return; // user canceled, do nothing
      }

      // Overwrite (update) the existing pattern
      try {
        const docRef = doc(
          db,
          "users",
          currentUser.uid,
          "patterns",
          existingPattern.id
        );
        // You can use setDoc with { merge: true }, or updateDoc.
        // setDoc completely replaces unless {merge: true} is used,
        // but for overwriting a pattern it’s often fine.
        await setDoc(docRef, cleanedData, { merge: true });

        alert(
          `Pattern "${nameTrimmed}" in folder "${folderTrimmed}" overwritten!`
        );
      } catch (err) {
        console.error("Error overwriting pattern:", err);
        alert("Failed to overwrite pattern. Check console for details.");
      }

      return; // end here after overwriting
    }

    // If no existing pattern with the same name & folder, proceed with normal logic

    // 5) Enforce tier-based pattern limits (only for new patterns)
    let allowedCount;
    if (subscriptionTier === "pro") {
      allowedCount = Infinity; // no limit
    } else if (subscriptionTier === "basic") {
      allowedCount = 10;
    } else {
      // default to "free"
      allowedCount = 3;
    }

    if (savedPatterns.length >= allowedCount) {
      alert(
        `You have reached the maximum number of saved patterns for your ` +
          `"${subscriptionTier}" plan. Please upgrade or delete old patterns to save a new one.`
      );
      return;
    }

    // 6) Write the new pattern to Firestore
    try {
      await addDoc(patternsRef, cleanedData);
      alert(`Pattern "${nameTrimmed}" saved to folder "${folderTrimmed}"!`);
    } catch (err) {
      console.error("Error saving pattern:", err);
      alert("Failed to save pattern. Check console for details.");
    }
  };

  async function handleRenamePattern(patternId, newName, newFolder = null) {
    if (!currentUser) return;

    // Find the pattern to rename
    const patternToRename = savedPatterns.find((p) => p.id === patternId);
    if (!patternToRename) {
      alert("Could not find pattern to rename.");
      return;
    }

    // Check if new name is empty
    const newNameTrimmed = newName.trim();
    if (!newNameTrimmed) {
      alert("Pattern name cannot be empty.");
      return;
    }

    // Check if a pattern with this name already exists in the same folder
    const folderToCheck = newFolder || patternToRename.folder;
    const patternExists = savedPatterns.some(
      (p) =>
        p.id !== patternId && // exclude the current pattern
        p.name === newNameTrimmed &&
        p.folder === folderToCheck
    );

    if (patternExists) {
      alert(
        `A pattern named "${newNameTrimmed}" already exists in folder "${folderToCheck}".`
      );
      return;
    }

    // Update the pattern in Firebase
    const docRef = doc(db, "users", currentUser.uid, "patterns", patternId);
    const updateData = {
      name: newNameTrimmed,
      updatedAt: serverTimestamp(),
    };

    if (newFolder) {
      updateData.folder = newFolder;
    }

    try {
      await updateDoc(docRef, updateData);
      alert(`Pattern renamed to "${newNameTrimmed}"!`);

      // If this is the currently loaded pattern, update the pattern name
      if (patternName === patternToRename.name) {
        setPatternName(newNameTrimmed);
        if (newFolder) {
          setFolderName(newFolder);
        }
      }
    } catch (err) {
      console.error("Error renaming pattern:", err);
      alert("Failed to rename pattern. Check console for details.");
    }
  }

  function handleLoadPattern(selectedName, folderName) {
    const p = savedPatterns.find(
      (pat) => pat.name === selectedName && pat.folder === folderName
    );
    if (!p) return;
    setPatternName(p.name);
    setFolderName(p.folder);
    setTempo(p.tempo);
    setUseTempoChanges(p.useTempoChanges);
    setEnableAdditionalTempoChanges(p.enableAdditionalTempoChanges);
    setTempoChanges(p.tempoChanges);
    setATuning(p.aTuning);
    setMuteRandomBeats(p.muteRandomBeats);
    setMutePercentage(p.mutePercentage);
    setLoopMode(p.loopMode);
    setMeasures(p.measures);
    setMeasures((prev) =>
      prev.map((m, i) => ({
        ...m,
        measureNumber: m.measureNumber ?? i + 1,
      }))
    );
    setStartMeasureNumber(p.startMeasureNumber || 1);
    setStressOn(p.stressOn ?? false);

    // Load selectedNoteValue if it exists, otherwise use default
    if (p.selectedNoteValue) {
      setSelectedNoteValue(p.selectedNoteValue);
    } else {
      setSelectedNoteValue({
        label: "Quarter Note",
        factor: 1,
      });
    }

    setIsPlaying(false);
    setCurrentBeat(0);
    setCurrentBar(0);
    alert(`Pattern "${selectedName}" from folder "${p.folder}" loaded!`);
  }

  async function handleDeletePattern(patternId) {
    if (!currentUser) return;

    // 1) Find the correct pattern in the local array (if needed)
    const patternToDelete = savedPatterns.find((p) => p.id === patternId);
    if (!patternToDelete) {
      alert("Could not find pattern in Firestore.");
      return;
    }

    // 2) Build a doc reference by ID
    const docRef = doc(db, "users", currentUser.uid, "patterns", patternId);

    // 3) Confirm with user or just delete
    const confirmed = window.confirm(
      `Are you sure you want to delete "${patternToDelete.name}" from folder "${patternToDelete.folder}"?`
    );
    if (!confirmed) return;

    await deleteDoc(docRef);

    alert(`Pattern "${patternToDelete.name}" deleted from Firestore.`);
  }

  // MIDI import/export placeholders
  const handleImportMIDI = () => {
    /* parseMidi logic here */
  };

  async function onImportMIDI(e) {
    const file = e.target.files?.[0];
    if (!file) return;

    const arrayBuf = await file.arrayBuffer();
    const midiData = new Uint8Array(arrayBuf);

    const parsed = parseMidi(midiData);
    // “parsed” is an object with .header, .tracks[], etc.

    // For simplicity, assume everything (tempo/time-sig) is in track[0].
    // If you have a type-1 MIDI with multiple separate tracks,
    // you may want to combine or look for the track with meta events.
    const track = parsed.tracks[0];

    // We'll keep a running “current numerator/denominator” and “current tempo”.
    let currentNumerator = 4;
    let currentDenominator = 4;
    let currentTempo = 120;

    // We'll accumulate measures in an array:
    const newMeasures = [];
    let currentMeasure = buildEmptyMeasure(
      currentNumerator,
      currentDenominator,
      currentTempo
    );

    // We'll keep track of how many ticks have elapsed since the last measure boundary.
    let measureTickCounter = 0;
    // jsmidgen default is 128 ticks per quarter.
    // If the file used a different PPQ, you get that in parsed.header.ticksPerBeat.
    // For now, let’s call it “ppq”.
    const ppq = parsed.header.ticksPerBeat; // e.g. 480, 960, or 128

    // A helper to compute total ticks in one measure, given the current time signature:
    function measureLengthInTicks(num, den) {
      // e.g. 4/4 => 4 quarters => 4 * ppq
      // 3/8 => 3 eighths => 3 * (ppq/2)
      const factor = den / 4;
      return Math.floor((num * ppq) / factor);
    }

    let currentMeasureMaxTicks = measureLengthInTicks(
      currentNumerator,
      currentDenominator
    );

    for (let i = 0; i < track.length; i++) {
      const event = track[i];

      // 1) Accumulate deltaTime in “measureTickCounter”
      measureTickCounter += event.deltaTime;

      // 2) If meta event is time signature => update currentNumerator, currentDenominator,
      //    then close out the current measure if we’re mid-measure, and start a new measure.
      if (event.meta && event.type === "timeSignature") {
        const [num, denom, clocks, bb] = event.timeSignature;
        // denom is 2^negative power. e.g. if denom=4, the raw byte is 2
        // But parseMidi usually decodes it properly as the real denominator.
        currentNumerator = num;
        currentDenominator = denom;

        // “seal” the old measure if measureTickCounter < currentMeasureMaxTicks,
        // or you might just forcibly end it.
        // Start a new measure, etc.
        if (measureTickCounter > 0) {
          // For simplicity, store the partial measure:
          newMeasures.push(currentMeasure);
        }
        currentMeasure = buildEmptyMeasure(
          currentNumerator,
          currentDenominator,
          currentTempo
        );

        measureTickCounter = 0;
        currentMeasureMaxTicks = measureLengthInTicks(num, denom);
        continue;
      }

      // 3) If meta event is setTempo => update currentTempo
      if (event.meta && event.type === "setTempo") {
        // event.setTempo is in microseconds per quarter note
        // BPM = 60,000,000 / microsecondsPerQuarter
        const microsecondsPerQuarter = event.setTempo;
        const bpm = Math.round(60000000 / microsecondsPerQuarter);
        currentTempo = bpm;
        // Optionally store a “tempo change” on the measure object or separate.
        currentMeasure.tempo = bpm;
        continue;
      }

      // 4) If this is a note-on or note-off, handle it
      if (event.type === "noteOn" || event.type === "noteOff") {
        const channel = event.channel;
        // We only care about channel 1 for the click
        if (channel !== 1) continue;

        const noteNumber = event.noteNumber; // e.g. 60, 62, etc.
        const velocity = event.velocity;

        // If noteOn with velocity>0 => that’s a click
        if (event.type === "noteOn" && velocity > 0) {
          // We know how far into the measure we are: measureTickCounter
          // fractionOfMeasure = measureTickCounter / currentMeasureMaxTicks
          // beatsInMeasure = currentNumerator
          // => beatIndex = fractionOfMeasure * currentNumerator (approx)

          const fraction = measureTickCounter / currentMeasureMaxTicks;
          const approxBeat = Math.floor(fraction * currentNumerator);

          // Safely clamp
          if (approxBeat >= currentNumerator) {
            // Means we’ve actually rolled over into the next measure.
            // So close out the current measure and open a new one.
            newMeasures.push(currentMeasure);
            currentMeasure = buildEmptyMeasure(
              currentNumerator,
              currentDenominator,
              currentTempo
            );
            measureTickCounter = measureTickCounter - currentMeasureMaxTicks;
          }

          // Insert a short "beatSounds" entry.
          // Decide if it’s accent:
          const isAccent = velocity >= ACCENT_THRESHOLD;
          // Map noteNumber -> sound ID if you want. E.g. if noteNumber=60 => "clicker", etc.
          const foundId = findSoundIdForNoteNumber(noteNumber) || "clicker";

          // Build or update the beatSound
          currentMeasure.beatSounds[approxBeat] = {
            id: foundId,
            userVolume: isAccent ? 1.2 : 1.0,
            // other fields as needed
          };
        }
      }

      // 5) If measureTickCounter >= currentMeasureMaxTicks,
      //    that means we've gone past this measure boundary => start a new measure
      while (measureTickCounter >= currentMeasureMaxTicks) {
        newMeasures.push(currentMeasure);

        // Subtract the measure length from measureTickCounter
        measureTickCounter -= currentMeasureMaxTicks;
        // Start next measure
        currentMeasure = buildEmptyMeasure(
          currentNumerator,
          currentDenominator,
          currentTempo
        );
        currentMeasureMaxTicks = measureLengthInTicks(
          currentNumerator,
          currentDenominator
        );
      }
    } // end track events

    // flush final measure
    if (measureTickCounter > 0) {
      newMeasures.push(currentMeasure);
    }

    // Finally, update your React state:
    setMeasures(newMeasures);
    setTempo(newMeasures[0]?.tempo || 120);
    // etc.

    alert(
      "Imported MIDI successfully! Measures, time signatures, and tempo loaded."
    );
  }

  function buildEmptyMeasure(num, den, bpm) {
    return {
      numerator: num,
      denominator: den,
      tempo: bpm,
      beatSounds: Array(num).fill(null),
    };
  }

  function findSoundIdForNoteNumber(n) {
    // Reverse-lookup from SOUND_TO_MIDI
    // e.g. if n=62 => "click".
    // This is purely up to you:
    for (const [soundId, obj] of Object.entries(SOUND_TO_MIDI)) {
      if (obj.note === n) return soundId;
    }
    return null;
  }

  function computeTempoForMeasure(measure, i) {
    // If you have no mid-measure changes, you can just do measure.tempo
    // Or if you store tempo in a global variable.
    // Replace with your logic as needed.
    return measure.tempo || 120;
  }

  function isPowerOfTwo(n) {
    return (n & (n - 1)) === 0 && n !== 0;
    // e.g. 1,2,4,8,16 => true; 6 => false
  }

  function exportMIDIImplementation(
    measures,
    defaultTempo = 120,
    fileName = "FlowFrame.mid"
  ) {
    // 1) Create a new MIDI container
    const midi = new Midi();

    // Instead of setting ppq directly, we'll use it as is (defaults to 480)
    const PPQ = midi.header.ppq;

    // 2) Create a single track (or multiple, if you prefer).
    const track = midi.addTrack();

    // We'll keep track of the running "currentTick" so we place events measure by measure.
    let currentTick = 0;

    measures.forEach((measure, idx) => {
      // Extract numerator, denominator, and tempo if provided; fallback to defaults
      const numerator = measure.numerator ?? 4;
      const denominator = measure.denominator ?? 4;
      const measureTempo = measure.tempo ?? defaultTempo;

      // 3) Insert a time signature event at the measure boundary
      //    ToneJS handles time signatures via midi.header.timeSignatures[] array
      midi.header.timeSignatures.push({
        ticks: currentTick, // place at measure boundary
        timeSignature: [numerator, denominator],
        measures: 0, // can remain 0; DAWs ignore 'measures'
      });

      // 4) Insert a tempo event at the measure boundary
      midi.header.tempos.push({
        ticks: currentTick,
        bpm: measureTempo,
      });

      // 5) Compute how many ticks this measure occupies
      //    measureQuarters = (numerator * (4 / denominator))
      //    total measure ticks = measureQuarters * ppq
      const measureQuarters = numerator * (4 / denominator);
      const measureTicks = Math.floor(measureQuarters * midi.header.ppq);

      // 6) Add a short placeholder note so the DAW sees actual events in the bar.
      //    Place it around halfway in, or at least 1 tick in if the measure is tiny.
      const half = measureTicks > 2 ? Math.floor(measureTicks / 2) : 1;

      track.addNote({
        // C4 = MIDI note #60
        midi: 60,
        velocity: 1,
        ticks: currentTick + half, // startTime in ticks
        durationTicks: 15, // a short note of 15 ticks
      });

      // 7) Advance currentTick to the next measure boundary
      currentTick += measureTicks;
    });

    // 8) Convert the @tonejs/midi data structure to a raw Uint8Array
    const out = midi.toArray();

    // 9) Trigger a download in the browser
    const blob = new Blob([out], { type: "audio/midi" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    console.log(`Exported MIDI with Tone.js: ${fileName}`);
  }

  function isClick(beatObj) {
    if (!beatObj) return false;
    // For example, if beatObj.id is "click", "wood", "cowbell", etc. => true
    // If it’s "drone" or "hasHarmony" => false
    // Adjust logic to match your data shape
    if (beatObj.hasHarmony || beatObj.dronePitchNote !== null) {
      return false;
    }
    return true;
  }

  // 1. Export Metronome Data (JSON)
  const handleExportMetronomeData = () => {
    // Gather everything you want to export
    // For example:
    const exportData = {
      version: 1, // optional
      patternName,
      measures,
      tempo,
      useTempoChanges,
      enableAdditionalTempoChanges,
      tempoChanges,
      aTuning,
      muteRandomBeats,
      mutePercentage,
      loopMode,
      startMeasureNumber,
      masterGain,
    };

    // Convert to JSON string
    const jsonStr = JSON.stringify(exportData, null, 2);

    // Trigger download
    const blob = new Blob([jsonStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = patternName
      ? `FlowFrame_${patternName}.ff`
      : "FlowFrame.ff";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // measure editing callbacks
  const updateMeasure = useCallback(
    (index, updates) => {
      setMeasures((prev) => {
        const newMeasures = prev.map((m, i) => {
          if (i !== index) return m; // Unchanged measure

          let updated = { ...m, ...updates };

          // **1) Handle numerator change (resize beatSounds)**
          if (
            typeof updates.numerator === "number" &&
            updates.numerator !== m.numerator
          ) {
            const newNumBeats = updates.numerator;

            if (newNumBeats > m.numerator) {
              // **Expand beatSounds while preserving existing values**
              updated.beatSounds = [
                ...m.beatSounds,
                ...Array(newNumBeats - m.numerator)
                  .fill(null)
                  .map(() => createNewBeat()),
              ];
            } else {
              // **Truncate beatSounds**
              updated.beatSounds = m.beatSounds.slice(0, newNumBeats);
            }
          }

          // **2) Handle applyBeatOneDroneToAllBeats**
          if (
            updates.applyBeatOneDroneToAllBeats === true &&
            updated.beatSounds.length > 0
          ) {
            const firstBeat = updated.beatSounds[0];
            updated.beatSounds = updated.beatSounds.map((beat, bIndex) => {
              if (bIndex === 0) return beat; // Keep first beat unchanged
              return {
                ...beat,
                dronePitchNote: firstBeat.dronePitchNote,
                dronePitchOctave: firstBeat.dronePitchOctave,
                droneVolume: firstBeat.droneVolume,
                hasHarmony: firstBeat.hasHarmony,
                selectedHarmony: firstBeat.selectedHarmony,
                justIntonation: firstBeat.justIntonation,
                usePartials: firstBeat.usePartials,
                selectedPartials: [...(firstBeat.selectedPartials || [])],
                useCustomNotes: firstBeat.useCustomNotes,
                selectedCustomNotes: [...(firstBeat.selectedCustomNotes || [])],
                useQuarterTones: firstBeat.useQuarterTones,
                selectedQuarterTones: [
                  ...(firstBeat.selectedQuarterTones || []),
                ],
              };
            });
          }

          return updated;
        });

        // **3) Reapply stress volumes, beat volumes, and dynamics**
        applyStressVolumesIfAny(newMeasures, stressOn);
        resetAllBeatVolumesToUserVolumes(newMeasures);
        applyAllDynamicsToAllMeasures(newMeasures);

        return newMeasures;
      });
    },
    [stressOn]
  );

  function toggleCustomNote(mIndex, bIndex, semitone) {
    setMeasures((prev) =>
      prev.map((measure, i) => {
        if (i !== mIndex) return measure;
        const oldBeat = measure.beatSounds[bIndex];
        if (!oldBeat) return measure;

        let newList = [...oldBeat.selectedCustomNotes];
        if (newList.includes(semitone)) {
          // remove it
          newList = newList.filter((x) => x !== semitone);
        } else {
          // add it
          newList.push(semitone);
        }

        const updatedBeat = {
          ...oldBeat,
          selectedCustomNotes: newList.sort((a, b) => a - b),
        };

        // now produce the updated measure
        const newBeatSounds = measure.beatSounds.map((bs, j) =>
          j === bIndex ? updatedBeat : bs
        );
        return { ...measure, beatSounds: newBeatSounds };
      })
    );
  }

  const updateBeatSound = useCallback(
    (mIndex, bIndex, newBeatObj) => {
      setMeasures((prev) => {
        const newMeasures = prev.map((measure, i) => {
          if (i !== mIndex) {
            return measure; // unchanged measure
          }

          // 1) Grab the old beat so we can track old pitch vs. new pitch, old useCustomNotes, etc.
          const oldBeat = measure.beatSounds[bIndex];

          // 2) Merge old beat with any newly provided fields
          let updatedBeat = {
            ...oldBeat,
            ...newBeatObj,
          };

          // ─────────────────────────────────────────────────────
          // A) “Any notes” logic: keep drone pitch in sync
          // ─────────────────────────────────────────────────────
          const oldWasOff = !oldBeat.useCustomNotes;
          const newIsOn = newBeatObj.useCustomNotes === true; // might be undefined
          const oldPitch = oldBeat.dronePitchNote;
          const newPitch = updatedBeat.dronePitchNote;

          // (A1) If user just turned on “Any notes,” add the current drone pitch to selectedCustomNotes
          if (oldWasOff && newIsOn && newPitch !== null) {
            if (!updatedBeat.selectedCustomNotes.includes(newPitch)) {
              updatedBeat = {
                ...updatedBeat,
                selectedCustomNotes: [
                  ...updatedBeat.selectedCustomNotes,
                  newPitch,
                ].sort((a, b) => a - b),
              };
            }
          }

          // (A2) If “Any notes” is ON and the drone pitch changed, remove the old pitch & add the new pitch
          const oldPitchHasChanged = oldPitch !== newPitch;
          if (updatedBeat.useCustomNotes && oldPitchHasChanged) {
            let newList = [...updatedBeat.selectedCustomNotes];

            // remove the old pitch if it wasn't null
            if (oldPitch !== null) {
              newList = newList.filter((x) => x !== oldPitch);
            }
            // add the new pitch if it's not null
            if (newPitch !== null && !newList.includes(newPitch)) {
              newList.push(newPitch);
            }

            updatedBeat = {
              ...updatedBeat,
              selectedCustomNotes: newList.sort((a, b) => a - b),
            };
          }

          // 3) Replace beatSounds[bIndex] with the newly updated beat
          let newBeatSounds = measure.beatSounds.map((snd, j) =>
            j === bIndex ? updatedBeat : snd
          );

          // 4) If "copy to next beat" is checked and there's a valid next beat
          if (
            updatedBeat.copyDroneHarmonyPartialsToNext &&
            bIndex < measure.numerator - 1
          ) {
            const clonedBeat = JSON.parse(JSON.stringify(updatedBeat));
            // Optionally disable the “copy” checkbox in the clone
            clonedBeat.copyDroneHarmonyPartialsToNext = false;
            newBeatSounds[bIndex + 1] = clonedBeat;
          }

          // 5) If editing beat #1 AND "applyBeatOneDroneToAllBeats" is on,
          //    sync the rest of the beats (including “any notes” fields)
          if (bIndex === 0 && measure.applyBeatOneDroneToAllBeats) {
            newBeatSounds = newBeatSounds.map((oldB, j) => {
              if (j === 0) return updatedBeat; // already updated
              return {
                ...oldB,
                dronePitchNote: updatedBeat.dronePitchNote,
                dronePitchOctave: updatedBeat.dronePitchOctave,
                droneVolume: updatedBeat.droneVolume,
                hasHarmony: updatedBeat.hasHarmony,
                selectedHarmony: updatedBeat.selectedHarmony,
                justIntonation: updatedBeat.justIntonation,
                usePartials: updatedBeat.usePartials,
                selectedPartials: [...(updatedBeat.selectedPartials || [])],

                // Now copy “any notes” fields too:
                useCustomNotes: updatedBeat.useCustomNotes,
                selectedCustomNotes: [
                  ...(updatedBeat.selectedCustomNotes || []),
                ],
                useQuarterTones: updatedBeat.useQuarterTones,
                selectedQuarterTones: [
                  ...(updatedBeat.selectedQuarterTones || []),
                ],
              };
            });
            console.log("measure.beatSounds after copy:", newBeatSounds);
          }

          // Return the updated measure object
          return {
            ...measure,
            beatSounds: newBeatSounds,
          };
        });

        // 6) Re-apply stress volumes, reset final volumes, and measure-level dynamics
        applyStressVolumesIfAny(newMeasures, stressOn);
        resetAllBeatVolumesToUserVolumes(newMeasures);
        applyAllDynamicsToAllMeasures(newMeasures);

        // 7) Done
        return newMeasures;
      });
    },
    [stressOn]
  );

  const insertMeasureBelow = useCallback(
    (mIndex) => {
      setMeasures((prev) => {
        const arr = [...prev];

        const oldNumber = arr[mIndex].measureNumber;
        const newNumber = oldNumber + 1;

        // 1) Create the new measure with correct measureNumber
        const newM = {
          ...createNewMeasure(stressOn),
          measureNumber: newNumber,
        };

        // 2) Insert it at the right position
        arr.splice(mIndex + 1, 0, newM);

        // 3) Adjust numbering for all measures AFTER the inserted one
        for (let i = mIndex + 1; i < arr.length; i++) {
          arr[i].measureNumber = oldNumber + (i - mIndex);
        }

        // 4) Reapply your processing logic
        applyStressVolumesIfAny(arr, stressOn);
        resetAllBeatVolumesToUserVolumes(arr);
        applyAllDynamicsToAllMeasures(arr);

        return arr;
      });
    },
    [stressOn]
  );

  const copyMeasureBelow = useCallback(
    (mIndex) => {
      setMeasures((prev) => {
        // Make a copy of the current measures
        const arr = [...prev];

        // 1) Copy the target measure and parse out its measureNumber
        const measureToCopy = JSON.parse(JSON.stringify(arr[mIndex]));
        const oldNumber = measureToCopy.measureNumber;
        const newNumber = oldNumber + 1;

        // 2) Give the copied measure the next measureNumber
        measureToCopy.measureNumber = newNumber;

        // 3) Insert it right below the original
        arr.splice(mIndex + 1, 0, measureToCopy);

        // 4) Renumber everything after that so measure numbers remain ascending
        for (let i = mIndex + 2; i < arr.length; i++) {
          arr[i].measureNumber++;
        }

        // Preserve your existing volume/dynamics logic
        applyStressVolumesIfAny(arr, stressOn);
        resetAllBeatVolumesToUserVolumes(arr);
        applyAllDynamicsToAllMeasures(arr);
        return arr;
      });
    },
    [stressOn]
  );

  const togglePartial = useCallback((mIndex, bIndex, partialNumber) => {
    setMeasures((prev) =>
      prev.map((mm, i) => {
        if (i !== mIndex) return mm;
        const oldBeat = mm.beatSounds[bIndex];
        if (!oldBeat) return mm;
        const already = oldBeat.selectedPartials.includes(partialNumber);
        let newSelected;
        if (already) {
          newSelected = oldBeat.selectedPartials.filter(
            (x) => x !== partialNumber
          );
        } else {
          newSelected = [...oldBeat.selectedPartials, partialNumber].sort(
            (a, b) => a - b
          );
        }
        const newBeat = { ...oldBeat, selectedPartials: newSelected };
        return {
          ...mm,
          beatSounds: mm.beatSounds.map((snd, j) =>
            j === bIndex ? newBeat : snd
          ),
        };
      })
    );
  }, []);

  function toggleQuarterTone(mIndex, bIndex, noteValue) {
    setMeasures((prev) =>
      prev.map((measure, i) => {
        if (i !== mIndex) return measure;
        const oldBeat = measure.beatSounds[bIndex];
        if (!oldBeat) return measure;

        let newList = [...oldBeat.selectedQuarterTones];
        if (newList.includes(noteValue)) {
          newList = newList.filter((x) => x !== noteValue);
        } else {
          newList.push(noteValue);
        }

        const updatedBeat = {
          ...oldBeat,
          selectedQuarterTones: newList,
        };

        const newBeatSounds = measure.beatSounds.map((bs, j) =>
          j === bIndex ? updatedBeat : bs
        );
        return { ...measure, beatSounds: newBeatSounds };
      })
    );
  }

  // TapTempo
  const handleTapTempo = useCallback(() => {
    const now = Date.now();

    setTapTimes((prevTimes) => {
      // If user waited too long between taps (>2 seconds), start fresh
      if (prevTimes.length && now - prevTimes[prevTimes.length - 1] > 2000) {
        return [now];
      }

      // Add new timestamp & keep only the last MAX_TAPS
      const newTimes = [...prevTimes, now].slice(-MAX_TAPS);

      // With two or more taps, compute intervals & average
      if (newTimes.length > 1) {
        const intervals = [];
        for (let i = 1; i < newTimes.length; i++) {
          intervals.push(newTimes[i] - newTimes[i - 1]);
        }

        // Average ms per tap
        const avgIntervalMs =
          intervals.reduce((a, b) => a + b) / intervals.length;

        // Convert ms per tap to BPM = 60,000 ms in a minute
        const newBPM = Math.round(60000 / avgIntervalMs);
        setTempo(newBPM);
      }

      return newTimes;
    });
  }, [setTempo]);

  const handleResetTap = useCallback(() => {
    setTapTimes([]);
  }, []);

  // ********** TUPLET EDITING **********
  const [tupletsUI, setTupletsUI] = useState(null);

  // This is new: we store measureIndex, tupleIndex, beatsInput, subCount, etc.
  const [editingTupletUI, setEditingTupletUI] = useState(null);
  const [editingNestedTupletUI, setEditingNestedTupletUI] = useState(null);

  /**
   * startEditTuplet loads the existing tuplet from measureObj, populates editingTupletUI
   */
  function startEditTuplet(mIndex, tupleIndex) {
    const measureObj = measures[mIndex];
    if (!measureObj) return;
    const oldTuplet = measureObj.tuplets[tupleIndex];
    if (!oldTuplet) return;

    // Build comma string from selectedBeats array
    const beatsStr = oldTuplet.selectedBeats.join(",");
    // Get mutedSubdivisions as comma string
    const mutedSubsStr = (oldTuplet.mutedSubdivisions || []).join(",");

    let nestedIsOn = false;
    let nestedArrStr = "";
    let nestedSubCount = "";
    let nestedSoundId = "clicker";
    let nestedMutedSubsStr = "";
    if (oldTuplet.nestedTuplets && oldTuplet.nestedTuplets.length > 0) {
      nestedIsOn = true;
      nestedArrStr = oldTuplet.nestedTuplets[0].selectedBeats.join(",");
      nestedSubCount = String(oldTuplet.nestedTuplets[0].subCount);
      nestedSoundId = oldTuplet.nestedTuplets[0].soundId;
      nestedMutedSubsStr = (
        oldTuplet.nestedTuplets[0].mutedSubdivisions || []
      ).join(",");
    }

    setEditingTupletUI({
      measureIndex: mIndex,
      tupleIndex,
      beatsInput: beatsStr,
      subCount: String(oldTuplet.subCount),
      soundId: oldTuplet.soundId,
      mutedSubdivisions: mutedSubsStr,
      addNested: nestedIsOn,
      nestedBeatsInput: nestedArrStr,
      nestedSubCount,
      nestedSoundId,
      nestedMutedSubdivisions: nestedMutedSubsStr,
    });
  }

  /**
   * saveEditedTuplet updates measureObj.tuplets[tupleIndex] with the new data
   */
  function saveEditedTuplet() {
    if (!editingTupletUI) return;

    const {
      measureIndex,
      tupleIndex,
      beatsInput,
      subCount,
      soundId,
      mutedSubdivisions, // Add this
      addNested,
      nestedBeatsInput,
      nestedSubCount,
      nestedSoundId,
      nestedMutedSubdivisions, // Add this
    } = editingTupletUI;

    console.log("Original mutedSubdivisions:", mutedSubdivisions);

    // parse
    const selectedBeats = parseBeatsInput(beatsInput);
    const sc = parseInt(subCount, 10) || 1;
    // Parse mutedSubdivisions if it's a string, otherwise use as-is
    const mutedSubs =
      typeof mutedSubdivisions === "string"
        ? parseAccentString(mutedSubdivisions)
        : mutedSubdivisions || [];

    console.log("Parsed mutedSubs:", mutedSubs);

    // build new tuplet
    const newTuplet = {
      selectedBeats,
      subCount: sc,
      soundId: soundId || "clicker",
      nestedTuplets: [],
      muted: false,
      mutedSubdivisions: mutedSubs, // Add the parsed array
    };

    console.log("New tuplet with mutedSubdivisions:", newTuplet);

    if (addNested) {
      const nestedBeats = parseBeatsInput(nestedBeatsInput);
      const nsc = parseInt(nestedSubCount, 10) || 1;
      // Parse nestedMutedSubdivisions if present
      const nestedMutedSubs =
        typeof nestedMutedSubdivisions === "string"
          ? parseAccentString(nestedMutedSubdivisions)
          : nestedMutedSubdivisions || [];

      if (nestedBeats.length > 0) {
        newTuplet.nestedTuplets.push({
          selectedBeats: nestedBeats,
          subCount: nsc,
          soundId: nestedSoundId || "clicker",
          nestedTuplets: [],
          mutedSubdivisions: nestedMutedSubs, // Add this
        });
      }
    }

    // now update the measure's tuplets array
    setMeasures((prev) =>
      prev.map((mm, idx) => {
        if (idx !== measureIndex) return mm;
        const newTuples = [...mm.tuplets];
        newTuples[tupleIndex] = newTuplet;
        return { ...mm, tuplets: newTuples };
      })
    );

    setEditingTupletUI(null);
  }

  useEffect(() => {
    // If the user toggles Stress ON, re-apply volumes to all existing measures.
    if (stressOn) {
      setMeasures((prev) => {
        // Make a fresh copy
        const newMeasures = [...prev];
        // 1) Overwrite userVolume with stress patterns if the measure matches
        applyStressVolumesIfAny(newMeasures, true);
        // 2) Copy userVolume => beatVolume
        resetAllBeatVolumesToUserVolumes(newMeasures);
        // 3) Then apply measure-level cresc/dim
        applyAllDynamicsToAllMeasures(newMeasures);
        return newMeasures;
      });
    }
  }, [stressOn]);

  function CurrentMeasureDisplay({
    measures,
    audioCtx,
    scheduledBeatsRef,
    isPlaying,
    startMeasureNumber,
  }) {
    const [currentMeasureIndex, setCurrentMeasureIndex] = useState(null);
    const [currentBeatIndex, setCurrentBeatIndex] = useState(null);

    const rafRef = useRef(null);

    useEffect(() => {
      // If we stop playing, reset to the start measure
      if (!isPlaying) {
        // The measureIndex is (startMeasureNumber - 1) if your array
        // is zero-based. The beat resets to 0 (i.e. the first beat).
        setCurrentMeasureIndex(startMeasureNumber - 1);
        setCurrentBeatIndex(0);
        // Cancel any pending animation frames
        if (rafRef.current) {
          cancelAnimationFrame(rafRef.current);
        }
        return;
      }

      // If we are playing, start the animation frame loop
      function loop() {
        const nowSec = audioCtx.currentTime;
        const schedArr = scheduledBeatsRef.current;

        if (!schedArr || !Array.isArray(schedArr) || schedArr.length === 0) {
          rafRef.current = requestAnimationFrame(loop);
          return;
        }

        // Find the scheduled event "just passed" or "at" nowSec
        let newIndex = -1;
        for (let i = 0; i < schedArr.length; i++) {
          const eventTime = schedArr[i].startTimeSec;
          if (eventTime > nowSec) {
            newIndex = i - 1;
            break;
          }
        }
        if (newIndex < 0) {
          // If we never broke from the loop, we might be at/past the last event
          newIndex = schedArr.length - 1;
        }

        // Update measure/beat state
        const { measureIndex, beatIndex } = schedArr[newIndex];
        setCurrentMeasureIndex(measureIndex);
        setCurrentBeatIndex(beatIndex);

        rafRef.current = requestAnimationFrame(loop);
      }

      rafRef.current = requestAnimationFrame(loop);
      return () => {
        if (rafRef.current) {
          cancelAnimationFrame(rafRef.current);
        }
      };
    }, [isPlaying, startMeasureNumber, audioCtx, scheduledBeatsRef]);

    if (currentMeasureIndex == null) return null;
    const measure = measures[currentMeasureIndex];
    if (!measure) return null;

    return (
      <div className="flex items-center text-xs space-x-2">
        {/* Display the measure number */}
        <div className="font-semibold">Measure {measure.measureNumber}</div>

        {/* Time signature */}
        <div className="flex items-center">
          <span>
            {measure.numerator}/{measure.denominator}
          </span>
        </div>

        {/* Small beat circles */}
        <div className="grid grid-cols-8 gap-1">
          {Array.from({ length: measure.numerator }).map((_, i) => {
            const isActive = i === currentBeatIndex;
            return (
              <div
                key={i}
                className={`
          w-3 h-3 rounded-full
          flex items-center justify-center
          ${isActive ? "bg-[#059dd9] text-white" : "bg-gray-300 text-gray-800"}
        `}
              />
            );
          })}
        </div>
      </div>
    );
  }

  // ---------------------------------------------------------
  //  startEditNestedTuplet: when user clicks "Edit" on a nested tuplet
  // ---------------------------------------------------------
  function startEditNestedTuplet(
    measureIndex,
    parentTupletIndex,
    nestedTupletIndex
  ) {
    // Grab the measure holding the parent tuplet
    const measureObj = measures[measureIndex];
    if (!measureObj) return;

    const parentTuplet = measureObj.tuplets[parentTupletIndex];
    if (!parentTuplet) return;

    const nested = parentTuplet.nestedTuplets[nestedTupletIndex];
    if (!nested) return;

    // Build a comma-separated string from the existing selectedBeats
    const beatsStr = nested.selectedBeats.join(",");
    // Get mutedSubdivisions as comma string
    const mutedSubsStr = (nested.mutedSubdivisions || []).join(",");

    // Populate the editing UI state
    setEditingNestedTupletUI({
      measureIndex,
      parentTupletIndex,
      nestedTupletIndex,
      beatsInput: beatsStr,
      subCount: String(nested.subCount),
      soundId: nested.soundId,
      mutedSubdivisions: mutedSubsStr,
    });
  }

  // ---------------------------------------------------------
  //  saveEditedNestedTuplet: invoked when user presses "Save"
  // ---------------------------------------------------------
  function saveEditedNestedTuplet() {
    if (!editingNestedTupletUI) return;

    const {
      measureIndex,
      parentTupletIndex,
      nestedTupletIndex,
      beatsInput,
      subCount,
      soundId,
      mutedSubdivisions, // Add this
    } = editingNestedTupletUI;

    // Parse the user's beats input into an array of integers, e.g. [2,3,4]
    const parsedBeats = parseBeatsInput(beatsInput);
    if (!parsedBeats.length) {
      // If your code wants to handle blank input, do so here. For now, we'll just bail:
      setEditingNestedTupletUI(null);
      return;
    }

    const finalSubCount = parseInt(subCount, 10) || 1;
    // Parse mutedSubdivisions if it's a string, otherwise use as-is
    const mutedSubs =
      typeof mutedSubdivisions === "string"
        ? parseAccentString(mutedSubdivisions)
        : mutedSubdivisions || [];

    // Now update measures in state
    setMeasures((prevMeasures) =>
      prevMeasures.map((measure, mIdx) => {
        if (mIdx !== measureIndex) return measure; // not the right measure

        // Update only the correct measure
        const updatedTuplets = measure.tuplets.map((oldTuplet, pIdx) => {
          if (pIdx !== parentTupletIndex) return oldTuplet; // not the parent tuplet we need

          // Update only the correct nested tuplet
          const newNestedArr = oldTuplet.nestedTuplets.map(
            (oldNested, nIdx) => {
              if (nIdx !== nestedTupletIndex) return oldNested;
              // Overwrite fields with user's new inputs
              return {
                ...oldNested,
                selectedBeats: parsedBeats,
                subCount: finalSubCount,
                soundId: soundId,
                mutedSubdivisions: mutedSubs, // Add the parsed array
              };
            }
          );

          // Return the updated parent tuplet
          return {
            ...oldTuplet,
            nestedTuplets: newNestedArr,
          };
        });

        // Return the updated measure
        return {
          ...measure,
          tuplets: updatedTuplets,
        };
      })
    );

    // Finally, clear the UI so we don't keep editing
    setEditingNestedTupletUI(null);
  }

  async function handleImportMetronomeData(event) {
    const file = event.target.files?.[0];
    if (!file) return; // user canceled

    try {
      // 1) Read file as text and parse JSON
      const fileText = await file.text();
      const imported = JSON.parse(fileText);

      // 2) Basic shape/type checks (tweak as needed)

      // measures (required array)
      if (!Array.isArray(imported.measures)) {
        throw new Error(
          "Invalid file: 'measures' must be an array of measure objects."
        );
      }

      // patternName (optional string)
      if (
        "patternName" in imported &&
        typeof imported.patternName !== "string"
      ) {
        throw new Error("'patternName' must be a string if provided.");
      }

      // tempo (optional number)
      if ("tempo" in imported && typeof imported.tempo !== "number") {
        throw new Error("'tempo' must be a number if provided.");
      }

      // useTempoChanges (optional boolean)
      if (
        "useTempoChanges" in imported &&
        typeof imported.useTempoChanges !== "boolean"
      ) {
        throw new Error("'useTempoChanges' must be a boolean if provided.");
      }

      // enableAdditionalTempoChanges (optional boolean)
      if (
        "enableAdditionalTempoChanges" in imported &&
        typeof imported.enableAdditionalTempoChanges !== "boolean"
      ) {
        throw new Error(
          "'enableAdditionalTempoChanges' must be a boolean if provided."
        );
      }

      // tempoChanges (optional array)
      if ("tempoChanges" in imported && !Array.isArray(imported.tempoChanges)) {
        throw new Error("'tempoChanges' must be an array if provided.");
      }

      // aTuning (optional number)
      if ("aTuning" in imported && typeof imported.aTuning !== "number") {
        throw new Error("'aTuning' must be a number if provided.");
      }

      // muteRandomBeats (optional boolean)
      if (
        "muteRandomBeats" in imported &&
        typeof imported.muteRandomBeats !== "boolean"
      ) {
        throw new Error("'muteRandomBeats' must be a boolean if provided.");
      }

      // mutePercentage (optional number)
      if (
        "mutePercentage" in imported &&
        typeof imported.mutePercentage !== "number"
      ) {
        throw new Error("'mutePercentage' must be a number if provided.");
      }

      // loopMode (optional string)
      if ("loopMode" in imported && typeof imported.loopMode !== "string") {
        throw new Error("'loopMode' must be a string if provided.");
      }

      // startMeasureNumber (optional number)
      if (
        "startMeasureNumber" in imported &&
        typeof imported.startMeasureNumber !== "number"
      ) {
        throw new Error("'startMeasureNumber' must be a number if provided.");
      }

      // masterGain (optional number)
      if ("masterGain" in imported && typeof imported.masterGain !== "number") {
        throw new Error("'masterGain' must be a number if provided.");
      }

      // 3) Everything passed => fill in defaults if missing
      const safePatternName = imported.patternName || "Imported Pattern";
      const safeMeasures = imported.measures;
      const safeTempo = imported.tempo ?? 60;
      const safeUseTempoChanges = imported.useTempoChanges ?? false;
      const safeEnableAdditionalTempoChanges =
        imported.enableAdditionalTempoChanges ?? false;
      const safeTempoChanges = imported.tempoChanges ?? [];
      const safeATuning = imported.aTuning ?? 440;
      const safeMuteRandomBeats = imported.muteRandomBeats ?? false;
      const safeMutePercentage = imported.mutePercentage ?? 50;
      const safeLoopMode = imported.loopMode ?? "loop";
      const safeStartMeasure = imported.startMeasureNumber ?? 1;
      const safeMasterGain = imported.masterGain ?? 0.7;

      // 4) If user is NOT logged in, warn that unsaved work will be lost
      if (!currentUser) {
        const proceed = window.confirm(
          "Warning: You are not logged in. Any imported data will NOT be saved to the cloud. " +
            "You may lose unsaved changes. Do you wish to continue?"
        );
        if (!proceed) {
          return; // user canceled the import
        }
        // If they continue, just replace what's on screen locally
        setPatternName(safePatternName);
        setMeasures(safeMeasures);
        setTempo(safeTempo);
        setUseTempoChanges(safeUseTempoChanges);
        setEnableAdditionalTempoChanges(safeEnableAdditionalTempoChanges);
        setTempoChanges(safeTempoChanges);
        setATuning(safeATuning);
        setMuteRandomBeats(safeMuteRandomBeats);
        setMutePercentage(safeMutePercentage);
        setLoopMode(safeLoopMode);
        setStartMeasureNumber(safeStartMeasure);
        setMasterGain(safeMasterGain);

        alert(
          "File imported (local only). You are not logged in, so this was not saved online."
        );
        return;
      }

      // 5) If user IS logged in, replace what's on screen and also save to Firestore
      setPatternName(safePatternName);
      setMeasures(safeMeasures);
      setTempo(safeTempo);
      setUseTempoChanges(safeUseTempoChanges);
      setEnableAdditionalTempoChanges(safeEnableAdditionalTempoChanges);
      setTempoChanges(safeTempoChanges);
      setATuning(safeATuning);
      setMuteRandomBeats(safeMuteRandomBeats);
      setMutePercentage(safeMutePercentage);
      setLoopMode(safeLoopMode);
      setStartMeasureNumber(safeStartMeasure);
      setMasterGain(safeMasterGain);

      // Example Firestore save:
      // (Replace this logic with your own 'patterns' collection structure.)
      const folderForImported = imported.folderName || "Uncategorized";
      const patternsRef = collection(db, "users", currentUser.uid, "patterns");
      await addDoc(patternsRef, {
        name: safePatternName,
        folder: folderForImported,
        measures: safeMeasures,
        tempo: safeTempo,
        useTempoChanges: safeUseTempoChanges,
        enableAdditionalTempoChanges: safeEnableAdditionalTempoChanges,
        tempoChanges: safeTempoChanges,
        aTuning: safeATuning,
        muteRandomBeats: safeMuteRandomBeats,
        mutePercentage: safeMutePercentage,
        loopMode: safeLoopMode,
        startMeasureNumber: safeStartMeasure,
        masterGain: safeMasterGain,
        createdAt: serverTimestamp(),
      });

      alert("Metronome data imported and saved to your account!");
    } catch (err) {
      console.error("Import error:", err);
      alert("Could not import file: " + err.message);
    }
  }

  // Render
  return (
    <div className="p-6 w-full bg-[#D9D9D9] rounded-xl shadow-md">
      {/* Add the TutorialOverlay */}
      {showTutorial && (
        <TutorialOverlay
          currentUser={currentUser}
          onComplete={() => {
            setShowTutorial(false);
            setTutorialCompleted(true);
          }}
        />
      )}
      {/* 2) The tab buttons */}
      <div className="flex space-x-4 mb-6">
        <button
          onClick={() => setActiveTab("metronome")}
          className={`px-3 py-1 rounded ${
            activeTab === "metronome"
              ? "bg-[#B71E4B] text-white shadow-md"
              : "bg-gray-200 hover:bg-[#B71E4B] hover:text-white shadow-md"
          }`}
        >
          FlowFrame
        </button>

        <button
          onClick={() => setActiveTab("about")}
          className={`px-3 py-1 rounded ${
            activeTab === "about"
              ? "bg-[#B71E4B] text-white shadow-md"
              : "bg-gray-200 hover:bg-[#B71E4B] hover:text-white shadow-md"
          }`}
        >
          About
        </button>
        <button
          onClick={() => setActiveTab("practiceTracker")}
          className={`px-3 py-1 rounded ${
            activeTab === "practiceTracker"
              ? "bg-[#B71E4B] text-white shadow-md"
              : "bg-gray-200 hover:bg-[#B71E4B] hover:text-white shadow-md"
          }`}
        >
          Practice Tracker
        </button>
        <button
          onClick={() => setActiveTab("practiceRoom")}
          className={`px-3 py-1 rounded ${
            activeTab === "practiceRoom"
              ? "bg-[#B71E4B] text-white shadow-md"
              : "bg-gray-200 hover:bg-[#B71E4B] hover:text-white shadow-md"
          }`}
        >
          From the Practice Room
        </button>
      </div>

      {/* 3) Show Metronome or About */}
      {activeTab === "metronome" && (
        <>
          <div className="text-center mb-3">
            <img
              src={flowFrameLogo}
              alt="FlowFrame Logo"
              className="inline-block w-48"
            />
          </div>

          <h2 className="text-2 mb-4 text-center">
            Empowering Confident Artistry
          </h2>

          {/* Top bar: Start/Stop, Reset, Hamburger */}
          <div className="sticky top-0 bg-[#eaeaea] z-50 p-2 flex flex-col mb-4 rounded-xl">
            {/* Top row: Start/Stop, Reset, and Hamburger */}
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                {/* Start/Stop button */}
                <button
                  onClick={handleStartStop}
                  className="px-3 py-1 bg-[#059dd9] text-white rounded hover:bg-[#346CBF] flex items-center shadow-md"
                >
                  {isPlaying ? (
                    <>
                      <Square className="w-4 h-4 mr-1" />
                      Stop
                    </>
                  ) : (
                    <>
                      <Play className="w-4 h-4 mr-1" />
                      Start
                    </>
                  )}
                </button>

                {/* Reset button */}
                <button
                  onClick={handleReset}
                  className="px-3 py-1 bg-gray-500 text-white rounded hover:bg-gray-600 flex items-center shadow-md"
                >
                  <RotateCcw className="w-4 h-4 mr-1" />
                  Reset
                </button>

                <CurrentMeasureDisplay
                  measures={measures}
                  audioCtx={audioContextRef.current}
                  scheduledBeatsRef={scheduledBeatsRef}
                  isPlaying={isPlaying}
                  startMeasureNumber={startMeasureNumber}
                />
              </div>

              <HamburgerActions
                patternName={patternName}
                deleteFolder={deleteFolder}
                selectedFolderForDeletion={selectedFolderForDeletion}
                setSelectedFolderForDeletion={setSelectedFolderForDeletion}
                selectedFolder={selectedFolder}
                setSelectedFolder={setSelectedFolder}
                setPatternName={setPatternName}
                newFolderName={newFolderName}
                setNewFolderName={setNewFolderName}
                folderName={folderName}
                setFolderName={setFolderName}
                savedPatterns={savedPatterns}
                onSavePattern={handleSavePattern}
                onRenamePattern={handleRenamePattern}
                onLoadPattern={handleLoadPattern}
                onDeletePattern={handleDeletePattern}
                onImportMIDI={onImportMIDI}
                onExportMIDI={handleExportMIDI}
                onImportMetronomeData={handleImportMetronomeData}
                onExportMetronomeData={handleExportMetronomeData}
                aTuning={aTuning}
                setATuning={setATuning}
                stressOn={stressOn}
                setStressOn={setStressOn}
                subscriptionTier={subscriptionTier}
                handleUpgrade={handleUpgrade}
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                handleGoogleSignIn={handleGoogleSignIn}
                handleSignOut={handleSignOut}
                setShowTutorial={setShowTutorial}
              />
            </div>

            {/* Second row: Start measure / Count-In */}
            <div className="flex items-center space-x-2 mt-2">
              <label htmlFor="startMeasure" className="font-medium">
                Start measure:
              </label>
              <input
                id="startMeasure"
                type="number"
                min="1"
                value={startMeasureNumber}
                onChange={(e) =>
                  setStartMeasureNumber(Number(e.target.value) || 1)
                }
                disabled={isPlaying || loopMode === "specific"}
                placeholder="1"
                className="px-3 py-1 border rounded w-20"
              />

              <label>Count-In:</label>
              <select
                id="count-in-select"
                value={countInMeasures}
                onChange={(e) => setCountInMeasures(Number(e.target.value))}
                disabled={isPlaying}
              >
                <option value={0}>None</option>
                <option value={1}>1 measure</option>
                <option value={2}>2 measures</option>
              </select>
            </div>
          </div>

          {/* Pattern Overview */}
          <div
            ref={patternOverviewRef}
            className="pattern-overview border p-4 rounded-lg bg-gray-50 mb-6"
          >
            {/* Heading and Collapse Button */}
            <div className="flex justify-between items-center mb-4">
              <div className="font-bold">Overview</div>
              <button
                type="button"
                onClick={() => setIsOverviewCollapsed(!isOverviewCollapsed)}
                className="text-sm font-bold"
              >
                {isOverviewCollapsed ? (
                  <ChevronDown className="w-4 h-4" />
                ) : (
                  <ChevronUp className="w-4 h-4" />
                )}
              </button>
            </div>

            {/* Filename */}
            <div className="text-center font-bold text-[#059dd9] mb-2">
              {patternName ? patternName : "FlowFrame - Project."}
            </div>

            {/* Only render these elements if not collapsed */}
            {!isOverviewCollapsed && (
              <>
                {/* Container for all measures + the ghost measure */}
                <div className="flex flex-wrap justify-center items-start gap-1">
                  {measures.map((measure, measureIndex) => {
                    const measureNum = measureIndex + 1;
                    const isHighlighted =
                      highlightedMeasures.includes(measureNum);

                    return (
                      <div
                        key={measure.id}
                        className={`
                w-1/2 md:w-auto max-w-xs p-2 border border-gray-300 rounded bg-white 
                flex flex-col items-center shadow-sm
                ${isHighlighted ? "border-4 border-red-500 bg-red-50" : ""}
              `}
                      >
                        {/* Measure heading / number */}
                        <div className="text-xs flex font-bold mb-1">
                          {editingMeasureIndex === measureIndex ? (
                            <input
                              type="number"
                              value={tempNumber}
                              onChange={(e) => setTempNumber(e.target.value)}
                              onBlur={() =>
                                handleMeasureNumberChange(
                                  measureIndex,
                                  tempNumber
                                )
                              }
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleMeasureNumberChange(
                                    measureIndex,
                                    tempNumber
                                  );
                                }
                              }}
                              className="border p-1 w-16 text-xs"
                              autoFocus
                            />
                          ) : (
                            <span
                              className="cursor-pointer underline"
                              onClick={() => {
                                setEditingMeasureIndex(measureIndex);
                                setTempNumber(measure.measureNumber);
                              }}
                            >
                              Measure {measure.measureNumber}
                            </span>
                          )}
                          <button
                            type="button"
                            onClick={() => jumpToMeasure(measureIndex)}
                            className="text-[#059dd9] hover:text-blue-700 ml-2 mr-2"
                          >
                            {/* Jump icon */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M13 5l7 7-7 7M5 5l7 7-7 7"
                              />
                            </svg>
                          </button>
                          <button
                            type="button"
                            onClick={() =>
                              handleDeleteMeasureInOverview(measureIndex)
                            }
                            className="text-red-500 hover:text-red-700"
                          >
                            <Trash2 className="w-4 h-4" />
                          </button>
                        </div>

                        {/* Time signature display */}
                        <div
                          id="pattern-overview"
                          className="flex items-center"
                        >
                          <div className="flex flex-col items-center justify-center mr-1 text-xs font-medium bg-gray-100 border border-gray-300 px-2 py-1 rounded">
                            {/* Numerator row with +/- */}
                            <div className="flex items-center space-x-1">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateMeasure(measureIndex, {
                                    numerator: Math.max(
                                      1,
                                      measure.numerator - 1
                                    ),
                                  });
                                }}
                                className="px-1 bg-gray-200 rounded text-[10px]"
                              >
                                –
                              </button>
                              <span>{measure.numerator}</span>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateMeasure(measureIndex, {
                                    numerator: Math.min(
                                      32,
                                      measure.numerator + 1
                                    ),
                                  });
                                }}
                                className="px-1 bg-gray-200 rounded text-[10px]"
                              >
                                +
                              </button>
                            </div>

                            {/* Denominator row with +/- */}
                            <span className="border-t border-gray-400 w-full text-center mt-1 pt-1">
                              <div className="flex items-center justify-center space-x-1">
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateMeasure(measureIndex, {
                                      denominator: getNextDenominatorDown(
                                        measure.denominator
                                      ),
                                    });
                                  }}
                                  className="px-1 bg-gray-200 rounded text-[10px]"
                                >
                                  –
                                </button>
                                <span>{measure.denominator}</span>
                                <button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    updateMeasure(measureIndex, {
                                      denominator: getNextDenominatorUp(
                                        measure.denominator
                                      ),
                                    });
                                  }}
                                  className="px-1 bg-gray-200 rounded text-[10px]"
                                >
                                  +
                                </button>
                              </div>
                            </span>
                          </div>

                          {/* Beat circles */}
                          <div className="flex flex-wrap">
                            {Array.from({ length: measure.numerator }).map(
                              (_, bIdx) => {
                                const beatId = `m${measureIndex}-b${bIdx}`;
                                return (
                                  <div
                                    key={bIdx}
                                    data-beat-id={beatId}
                                    className="
                  w-5 h-5 mx-0.5 rounded-full
                  flex items-center justify-center text-xs
                  bg-gray-200 text-gray-700
                "
                                  >
                                    {bIdx + 1}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>

                        {/* Dynamic marking, cresc/dim */}
                        <div className="mt-2 flex text-center">
                          <input
                            type="text"
                            value={measure.dynamicMarking}
                            onChange={(e) => {
                              updateMeasure(measureIndex, {
                                dynamicMarking: e.target.value,
                              });
                            }}
                            placeholder="dyn"
                            className="w-10 border rounded text-center mr-2 text-xs"
                          />

                          <label className="mr-2 inline-flex items-center space-x-1 text-xs">
                            <input
                              type="checkbox"
                              checked={measure.cresc}
                              onChange={(e) =>
                                updateMeasure(measureIndex, {
                                  cresc: e.target.checked,
                                })
                              }
                            />
                            <span className="ml-1">cresc</span>
                          </label>

                          <label className="inline-flex items-center space-x-1 text-xs">
                            <input
                              type="checkbox"
                              checked={measure.dim}
                              onChange={(e) =>
                                updateMeasure(measureIndex, {
                                  dim: e.target.checked,
                                })
                              }
                            />
                            <span className="ml-1">dim</span>
                          </label>
                        </div>
                      </div>
                    );
                  })}

                  {/* ---------- GHOST MEASURE ---------- */}
                  {measures.length > 0 && (
                    <div
                      id="ghost-measure"
                      className="
              w-1/2 md:w-auto max-w-xs p-2 border border-gray-300 rounded bg-white 
              flex flex-col items-center shadow-sm
              opacity-50 hover:opacity-75 transition-opacity
            "
                    >
                      {/* Heading for the ghost measure */}
                      <div className="text-xs flex font-bold mb-1">
                        Measure{" "}
                        {measures[measures.length - 1].measureNumber + 1}
                      </div>

                      {/* Time signature block with plus/minus */}
                      <div className="flex items-center mb-2">
                        {/* Numerator +/- */}
                        <div
                          className="
                  flex flex-col items-center justify-center 
                  mr-1 text-xs font-medium bg-gray-100 
                  border border-gray-300 px-2 py-1 rounded
                "
                        >
                          <div className="flex items-center space-x-1">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setGhostNumer((prev) => Math.max(1, prev - 1));
                              }}
                              className="px-1 bg-gray-200 rounded text-[10px]"
                            >
                              –
                            </button>
                            <span>{ghostNumer}</span>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setGhostNumer((prev) => Math.min(32, prev + 1));
                              }}
                              className="px-1 bg-gray-200 rounded text-[10px]"
                            >
                              +
                            </button>
                          </div>

                          {/* Slash and denominator */}
                          <span className="border-t border-gray-400 w-full text-center mt-1 pt-1">
                            <div className="flex items-center justify-center space-x-1">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setGhostDenom((prev) => {
                                    const valid = [1, 2, 4, 8, 16, 32];
                                    const idx = valid.indexOf(prev);
                                    return idx > 0 ? valid[idx - 1] : prev;
                                  });
                                }}
                                className="px-1 bg-gray-200 rounded text-[10px]"
                              >
                                –
                              </button>
                              <span>{ghostDenom}</span>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setGhostDenom((prev) => {
                                    const valid = [1, 2, 4, 8, 16, 32];
                                    const idx = valid.indexOf(prev);
                                    return idx < valid.length - 1
                                      ? valid[idx + 1]
                                      : prev;
                                  });
                                }}
                                className="px-1 bg-gray-200 rounded text-[10px]"
                              >
                                +
                              </button>
                            </div>
                          </span>
                        </div>

                        {/* Beat circles (based on ghostNumer) */}
                        <div className="flex flex-wrap">
                          {Array.from({ length: ghostNumer }).map((_, i) => (
                            <div
                              key={i}
                              className="
                    w-5 h-5 mx-0.5 rounded-full 
                    bg-gray-200 text-gray-700 
                    flex items-center justify-center text-[10px]
                  "
                            >
                              {i + 1}
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* "Add measure" action */}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAddCustomMeasure(ghostNumer, ghostDenom);
                        }}
                        className="px-2 py-1 bg-[#059dd9] text-white text-xs rounded"
                      >
                        Add Measure
                      </button>
                    </div>
                  )}
                </div>

                {/* 3) Conditionally render the BeatHighlighter 
    after the measure list, only if isPlaying. 
    (You might also have a separate showHighlighter 
     or something if you prefer. 
     Below we assume you're passing in 
     scheduledBeatsRef from your useLookaheadScheduler 
     and a reference to audioContextRef. */}
                {isPlaying && (
                  <BeatHighlighter
                    audioCtx={audioContextRef.current}
                    scheduledBeatsRef={scheduledBeatsRef}
                    leadTimeMs={20}
                  />
                )}

                <div className="mt-4 flex items-center">
                  <label className="text-sm font-medium mr-2">
                    Loop Behavior
                  </label>

                  <select
                    value={loopMode}
                    onChange={(e) => setLoopMode(e.target.value)}
                    className="p-2 border rounded"
                    disabled={isPlaying}
                  >
                    <option value="loop">Loop to Beginning</option>
                    <option value="continue">Continue Last Measure</option>
                    {/* NEW OPTION: */}
                    <option value="specific">Loop Specific Measures</option>
                  </select>
                </div>

                {/* Only show these inputs if loopMode === 'specific' */}
                {loopMode === "specific" && (
                  <div className="mt-2 flex space-x-2">
                    <label>Loop start measure:</label>
                    <input
                      type="number"
                      value={loopStartMeasure}
                      onChange={(e) => {
                        // parse user input as integer
                        const raw = parseInt(e.target.value, 10);
                        // if invalid or zero, default to 1
                        const val = Number.isNaN(raw) || raw < 1 ? 1 : raw;
                        setLoopStartMeasure(val);
                      }}
                      min="1"
                      className="border p-1 rounded w-16"
                      disabled={isPlaying}
                    />

                    <label>Loop end measure:</label>
                    <input
                      type="number"
                      value={loopEndMeasure}
                      onChange={(e) => {
                        const raw = parseInt(e.target.value, 10);
                        const val = Number.isNaN(raw) || raw < 1 ? 1 : raw;
                        setLoopEndMeasure(val);
                      }}
                      min="1"
                      className="border p-1 rounded w-16"
                      disabled={isPlaying}
                    />
                  </div>
                )}

                <div className="mt-4 border-t pt-3">
                  <label className="block text-sm font-medium mb-1">
                    Highlight Measures for Practice
                  </label>

                  <div className="flex space-x-2">
                    <input
                      type="text"
                      value={highlightInput}
                      onChange={(e) => setHighlightInput(e.target.value)}
                      placeholder='e.g. "2,4-5,10"'
                      className="border p-1 rounded flex-1"
                    />

                    <button
                      onClick={handleAddHighlights}
                      className="px-3 py-1 bg-[#059DD9] text-white rounded hover:bg-[#346CBF]"
                    >
                      Add / Toggle
                    </button>
                  </div>

                  {/* If you want to show the user the currently highlighted measures as "chips": */}
                  {highlightedMeasures.length > 0 && (
                    <div className="flex items-center flex-wrap mt-3">
                      {highlightedMeasures.map((num) => (
                        <div
                          key={num}
                          className="flex items-center 
                          bg-red-100 text-red-700 
                          px-1 py-0.5 
                          rounded text-xs 
                          mr-1 mb-1"
                        >
                          <span>Measure {num}</span>
                          <button
                            onClick={() => removeSingleHighlight(num)}
                            className="ml-2 text-red-600 hover:text-red-800 font-bold"
                          >
                            x
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <>
            {/* Some button that triggers the Bulk Editor */}
            <button
              onClick={() => setBulkEditorOpen(true)}
              className="px-2 py-1 bg-[#059DD9] text-white rounded shadow-md hover:bg-[#346CBF]"
            >
              Bulk Editor
            </button>

            <BulkEditorModal
              key={bulkEditorResetCount}
              isOpen={bulkEditorOpen}
              onClose={() => setBulkEditorOpen(false)}
              onApplyChanges={handleApplyBulkEditorChanges}
            />

            {/* the rest of your Metronome UI */}
          </>

          {/* Tuning, tempo, volume, random mute, tempo changes, etc. */}
          <div className="mb-6">
            <div id="tempo-controls" className="mt-4">
              <div className="flex items-center space-x-2">
                <input
                  type="number"
                  min="10"
                  max="340"
                  value={tempo}
                  onChange={(e) => setTempo(Number(e.target.value))}
                  disabled={isTempoLocked}
                  className="p-2 border rounded w-24"
                />

                <label htmlFor="noteValueSelect" style={{ marginLeft: "1rem" }}>
                  = (choose note)
                </label>
                <select
                  id="noteValueSelect"
                  value={selectedNoteValue?.label || ""} // or some unique key
                  onChange={(e) => {
                    // find the chosen option by label
                    const newChoice = NOTE_VALUE_OPTIONS.find(
                      (opt) => opt.label === e.target.value
                    );
                    setSelectedNoteValue(newChoice);
                    console.log("selectedNoteValue (parent):", newChoice);
                  }}
                >
                  {NOTE_VALUE_OPTIONS.map((opt) => (
                    <option key={opt.label} value={opt.label}>
                      {opt.label}
                    </option>
                  ))}
                </select>

                {/* Lock/Unlock Icon */}
                <button
                  onClick={() => setIsTempoLocked((prev) => !prev)}
                  className="text-gray-600 hover:text-gray-800"
                  title={isTempoLocked ? "Unlock tempo" : "Lock tempo"}
                >
                  {isTempoLocked ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      {/* Locked icon */}
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 11c1.657 0 3-1.343 3-3V5a3 3 0 10-6 0v3c0 1.657 1.343 3 3 3zm6 0H6a2 2 0 00-2 2v5a2 2 0 002 2h12a2 2 0 002-2v-5a2 2 0 00-2-2z"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      {/* Unlocked icon */}
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 11V7a3 3 0 10-6 0m6 0a3 3 0 00-6 0m6 4H6a2 2 0 00-2 2v5a2 2 0 002 2h12a2 2 0 002-2v-5a2 2 0 00-2-2z"
                      />
                    </svg>
                  )}
                </button>
              </div>
              <input
                type="range"
                min="10"
                max="340"
                value={tempo}
                onChange={(e) => setTempo(Number(e.target.value))}
                disabled={isTempoLocked}
                className="w-full max-w-md mt-2"
                style={{ accentColor: "#307BBF" }}
              />
              {/* Tap Tempo Button(s) */}
              <div className="flex items-center space-x-2">
                <button
                  onClick={handleTapTempo}
                  disabled={isPlaying}
                  className="px-2 py-1 bg-blue-100 border rounded"
                >
                  Tap Tempo
                </button>
                <button
                  onClick={handleResetTap}
                  disabled={isPlaying}
                  className="px-2 py-1 bg-gray-200 border rounded"
                >
                  Reset Tap
                </button>
              </div>
            </div>

            <label className="block text-sm font-medium mb-2 mt-4">
              Master Gain: {masterGain.toFixed(2)}
            </label>
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={masterGain}
              onChange={(e) => setMasterGain(Number(e.target.value))}
              className="w-full max-w-md"
              style={{ accentColor: "#307BBF" }}
            />

            {/* Mute checkboxes */}
            <div className="flex items-center mt-2">
              <span className="text-sm font-medium mr-2">Mute:</span>

              <label className="inline-flex items-center mr-3">
                <input
                  type="checkbox"
                  checked={muteBeats}
                  onChange={(e) => setMuteBeats(e.target.checked)}
                />
                <span className="ml-1 text-sm">Beat</span>
              </label>

              <label className="inline-flex items-center mr-3">
                <input
                  type="checkbox"
                  checked={muteSubs}
                  onChange={(e) => setMuteSubs(e.target.checked)}
                />
                <span className="ml-1 text-sm">Subdivisions</span>
              </label>

              <label className="inline-flex items-center mr-3">
                <input
                  type="checkbox"
                  checked={muteDrones}
                  onChange={(e) => setMuteDrones(e.target.checked)}
                />
                <span className="ml-1 text-sm">Drones</span>
              </label>

              <label className="inline-flex items-center mr-3">
                <input
                  type="checkbox"
                  checked={muteTuplets}
                  onChange={(e) => setMuteTuplets(e.target.checked)}
                />
                <span className="ml-1 text-sm">Tuplets</span>
              </label>
            </div>

            <div className="mt-4">
              <label className="flex items-center space-x-2 mb-2">
                <input
                  type="checkbox"
                  checked={muteRandomBeats}
                  onChange={(e) => {
                    setMuteRandomBeats(e.target.checked);
                    if (!e.target.checked) {
                      globalMeasureCountRef.current = 0;
                    }
                  }}
                  disabled={isPlaying}
                />
                <span className="text-sm">Mute Random Beats</span>
              </label>
              {muteRandomBeats && (
                <div>
                  <label className="block text-sm font-medium mb-2">
                    Mute Percentage: {mutePercentage}%
                  </label>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={mutePercentage}
                    onChange={(e) => setMutePercentage(Number(e.target.value))}
                    className="w-full max-w-md"
                    disabled={isPlaying}
                    style={{ accentColor: "#307BBF" }}
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    Randomization begins gradually after 3 measures
                  </p>
                </div>
              )}
            </div>

            <div className="mt-4">
              <label className="flex items-center space-x-2 mb-2">
                <input
                  type="checkbox"
                  checked={muteRandomDrones}
                  onChange={(e) => updateMuteRandomDrones(e.target.checked)}
                  disabled={isPlaying}
                />
                <span className="text-sm">Mute Random Drones/Chords</span>
              </label>
              {muteRandomDrones && (
                <div>
                  <label className="block text-sm font-medium mb-2">
                    Drone Mute Percentage: {muteRandomDronePercentage}%
                  </label>
                  <input
                    type="range"
                    min="0"
                    max="100"
                    value={muteRandomDronePercentage}
                    onChange={(e) =>
                      updateMuteRandomDronePercentage(Number(e.target.value))
                    }
                    className="w-full max-w-md"
                    disabled={isPlaying}
                    style={{ accentColor: "#307BBF" }}
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    Randomization begins gradually after 3 measures
                  </p>
                </div>
              )}
            </div>

            {/* tempo changes toggle */}
            <div id="tempochanges" className="position: relative mt-4">
              {subscriptionTier === "pro" ? (
                <label className="flex items-center space-x-2 mb-2">
                  <input
                    type="checkbox"
                    checked={useTempoChanges}
                    onChange={(e) => {
                      setUseTempoChanges(e.target.checked);
                      if (!e.target.checked) {
                        setEnableAdditionalTempoChanges(false);
                        setTempoChanges([{ ...DEFAULT_TEMPO_CHANGE }]);
                      }
                    }}
                    disabled={isPlaying}
                  />
                  <span className="text-sm font-medium">
                    Enable Tempo Changes
                  </span>
                  <button
                    type="button"
                    className="flex items-center justify-center w-5 h-5 rounded-full bg-gray-200 text-gray-700 
                 hover:bg-gray-300 text-xs font-bold"
                    onClick={() => setShowInfo(!showInfo)}
                  >
                    i
                  </button>
                </label>
              ) : (
                <p className="text-xs text-gray-500 mt-2">
                  <strong>Enable Tempo Changes</strong> is a Basic feature.
                  <button
                    onClick={() => handleUpgrade(currentUser)}
                    className="ml-1 underline text-blue-600"
                  >
                    Upgrade
                  </button>
                </p>
              )}

              {/* Info Pop-up (renders only if showInfo is true) */}
              {showInfo && (
                <div
                  className="absolute z-10 bg-white border border-gray-300 
                     p-2 rounded shadow-md text-sm w-64"
                  style={{ top: "3rem", left: 0 }}
                >
                  <p>
                    Tempo changes scale proportionally to the base BPM. For
                    example, if the tempo change goes from 60 to 120, and the
                    base BPM is set to 30, the tempo change will go from 30 to
                    60.
                  </p>
                  {/* Close button inside the pop-up */}
                  <div className="text-right mt-2">
                    <button
                      onClick={() => setShowInfo(false)}
                      className="text-xs text-blue-500 hover:underline"
                    >
                      Close
                    </button>
                  </div>
                </div>
              )}

              {useTempoChanges && (
                <div className="border p-3 rounded space-y-4">
                  {tempoChanges.map((tc, i) => (
                    <div
                      key={i}
                      className="border border-gray-300 rounded p-2 bg-white"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className="text-sm font-semibold">
                          Tempo Change {i + 1}
                        </span>
                        <button
                          onClick={() =>
                            setTempoChanges((prev) =>
                              prev.filter((_, idx) => idx !== i)
                            )
                          }
                          className="text-red-500 hover:text-red-700"
                          disabled={isPlaying}
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      </div>
                      {/* Type (linear, exponential, sudden, incremental) */}
                      <label className="block text-sm font-medium mt-2 mb-1">
                        Type
                      </label>
                      <select
                        value={tc.type}
                        onChange={(e) => {
                          const newType = e.target.value;
                          setTempoChanges((prev) =>
                            prev.map((item, idx) =>
                              idx === i ? { ...item, type: newType } : item
                            )
                          );
                        }}
                        disabled={isPlaying}
                        className="border p-1 rounded w-full"
                      >
                        <option value="linear">Linear</option>
                        <option value="exponential">Exponential</option>
                        <option value="sudden">Sudden</option>
                        <option value="incremental">Incremental</option>
                      </select>
                      {tc.type === "sudden" ? (
                        <>
                          {/* If type = "sudden", only show measure + end tempo */}
                          <label className="block text-sm font-medium mb-1 mt-2">
                            Measure of Change
                          </label>
                          <input
                            type="number"
                            className="border p-1 rounded w-full"
                            // Reuse either tc.startMeasure or create a new field, e.g. tc.measureOfChange
                            value={tc.startMeasure}
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              setTempoChanges((prev) =>
                                prev.map((item, idx) =>
                                  idx === i
                                    ? { ...item, startMeasure: val }
                                    : item
                                )
                              );
                            }}
                            disabled={isPlaying}
                          />

                          <label className="block text-sm font-medium mt-2 mb-1">
                            End Tempo
                          </label>
                          <input
                            type="number"
                            className="border p-1 rounded w-full"
                            value={tc.endTempo}
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              setTempoChanges((prev) =>
                                prev.map((item, idx) =>
                                  idx === i ? { ...item, endTempo: val } : item
                                )
                              );
                            }}
                            disabled={isPlaying}
                          />
                        </>
                      ) : tc.type === "incremental" ? (
                        <>
                          {/* Increment Scope: Whole vs Specific Bars */}
                          <div className="my-2">
                            <span className="block text-sm font-medium mb-1">
                              Increment
                            </span>
                            <label className="flex items-center mb-1">
                              <input
                                type="radio"
                                name={`incScope-${i}`}
                                checked={tc.scope === "whole"}
                                onChange={() => {
                                  setTempoChanges((prev) =>
                                    prev.map((item, idx) =>
                                      idx === i
                                        ? {
                                            ...item,
                                            scope: "whole",
                                            specificBars: "",
                                          }
                                        : item
                                    )
                                  );
                                }}
                                disabled={isPlaying}
                              />
                              <span className="ml-1 text-sm">
                                Whole Pattern
                              </span>
                            </label>

                            <label className="flex items-center">
                              <input
                                type="radio"
                                name={`incScope-${i}`}
                                checked={tc.scope === "specific"}
                                onChange={() => {
                                  setTempoChanges((prev) =>
                                    prev.map((item, idx) =>
                                      idx === i
                                        ? { ...item, scope: "specific" }
                                        : item
                                    )
                                  );
                                }}
                                disabled={isPlaying}
                              />
                              <span className="ml-1 text-sm">
                                Specific Bars
                              </span>
                            </label>
                          </div>

                          {tc.scope === "specific" && (
                            <div className="mb-2">
                              <label className="block text-sm font-medium mb-1">
                                Which Bars to Loop? (e.g., "4-6")
                              </label>
                              <input
                                type="text"
                                className="border p-1 rounded w-full"
                                value={tc.specificBars || ""}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  setTempoChanges((prev) =>
                                    prev.map((item, idx) =>
                                      idx === i
                                        ? { ...item, specificBars: val }
                                        : item
                                    )
                                  );
                                }}
                                disabled={isPlaying}
                              />
                              <p className="text-xs text-gray-500 mt-1">
                                Enter 1-based bars, e.g., "2-3,5".
                              </p>
                            </div>
                          )}

                          {tc.scope && (
                            <>
                              <div className="grid grid-cols-2 gap-2 mt-2">
                                <div>
                                  <label className="block text-sm font-medium mb-1">
                                    Step Size (BPM)
                                  </label>
                                  <input
                                    type="number"
                                    className="border p-1 rounded w-full"
                                    value={Math.round(tc.stepSize || 0)}
                                    onChange={(e) => {
                                      const val = Number(e.target.value);
                                      setTempoChanges((prev) =>
                                        prev.map((item, idx) =>
                                          idx === i
                                            ? { ...item, stepSize: val }
                                            : item
                                        )
                                      );
                                    }}
                                    disabled={isPlaying}
                                  />
                                </div>

                                <div>
                                  <label className="block text-sm font-medium mb-1">
                                    End Tempo
                                  </label>
                                  <input
                                    type="number"
                                    className="border p-1 rounded w-full"
                                    value={tc.endTempo || ""}
                                    onChange={(e) => {
                                      const val = Number(e.target.value);
                                      setTempoChanges((prev) =>
                                        prev.map((item, idx) =>
                                          idx === i
                                            ? { ...item, endTempo: val }
                                            : item
                                        )
                                      );
                                    }}
                                    disabled={isPlaying}
                                  />
                                </div>
                              </div>

                              <div className="mt-2">
                                <label className="flex items-center mb-1">
                                  <input
                                    type="checkbox"
                                    checked={tc.useStepEvery || false}
                                    onChange={(e) => {
                                      const checked = e.target.checked;
                                      setTempoChanges((prev) =>
                                        prev.map((item, idx) =>
                                          idx === i
                                            ? { ...item, useStepEvery: checked }
                                            : item
                                        )
                                      );
                                    }}
                                    disabled={isPlaying}
                                  />

                                  <span className="ml-1 text-sm">
                                    Step every (measures)
                                  </span>
                                </label>

                                {tc.useStepEvery && (
                                  <input
                                    type="number"
                                    className="border p-1 rounded w-full"
                                    value={tc.stepEvery || ""}
                                    onChange={(e) => {
                                      const val = Number(e.target.value);
                                      setTempoChanges((prev) =>
                                        prev.map((item, idx) =>
                                          idx === i
                                            ? { ...item, stepEvery: val }
                                            : item
                                        )
                                      );
                                    }}
                                    disabled={isPlaying}
                                  />
                                )}
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {/* Show normal linear/exponential UI: start measure, end measure, start tempo, end tempo */}
                          <label className="block text-sm font-medium mb-1 mt-2">
                            Start Measure
                          </label>
                          <input
                            type="number"
                            className="border p-1 rounded w-full"
                            value={tc.startMeasure}
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              setTempoChanges((prev) =>
                                prev.map((item, idx) =>
                                  idx === i
                                    ? { ...item, startMeasure: val }
                                    : item
                                )
                              );
                            }}
                            disabled={isPlaying}
                          />

                          <label className="block text-sm font-medium mt-2 mb-1">
                            End Measure
                          </label>
                          <input
                            type="number"
                            className="border p-1 rounded w-full"
                            value={tc.endMeasure}
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              setTempoChanges((prev) =>
                                prev.map((item, idx) =>
                                  idx === i
                                    ? { ...item, endMeasure: val }
                                    : item
                                )
                              );
                            }}
                            disabled={isPlaying}
                          />

                          <label className="block text-sm font-medium mt-2 mb-1">
                            Start Tempo
                          </label>
                          <input
                            type="number"
                            className="border p-1 rounded w-full"
                            value={tc.startTempo}
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              setTempoChanges((prev) =>
                                prev.map((item, idx) =>
                                  idx === i
                                    ? { ...item, startTempo: val }
                                    : item
                                )
                              );
                            }}
                            disabled={isPlaying}
                          />

                          <label className="block text-sm font-medium mt-2 mb-1">
                            End Tempo
                          </label>
                          <input
                            type="number"
                            className="border p-1 rounded w-full"
                            value={tc.endTempo}
                            onChange={(e) => {
                              const val = Number(e.target.value);
                              setTempoChanges((prev) =>
                                prev.map((item, idx) =>
                                  idx === i ? { ...item, endTempo: val } : item
                                )
                              );
                            }}
                            disabled={isPlaying}
                          />
                        </>
                      )}
                    </div>
                  ))}

                  <button
                    onClick={() =>
                      setTempoChanges((prev) => [
                        ...prev,
                        { ...DEFAULT_TEMPO_CHANGE },
                      ])
                    }
                    className="px-2 py-1 text-xs bg-[#B71E4B] text-white text-xs rounded flex items-center hover:[#CC235B]"
                    disabled={isPlaying}
                  >
                    <Plus className="w-3 h-3 mr-1" />
                    Add Tempo Change
                  </button>
                </div>
              )}
            </div>

            <div id="modulateloop" className="mt-4">
              {subscriptionTier === "pro" ? (
                <label className="flex items-center space-x-2 mb-2">
                  <input
                    type="checkbox"
                    checked={modulateOnLoop}
                    onChange={(e) => setModulateOnLoop(e.target.checked)}
                    disabled={isPlaying}
                  />
                  <span className="text-sm">Modulate on loop</span>
                </label>
              ) : (
                <p className="text-xs text-gray-500">
                  <strong>Modulate on loop</strong> is a Pro feature.
                  {/* Optional: Add an upgrade button or link */}
                  <button
                    onClick={() => handleUpgrade(currentUser)}
                    className="text-blue-600 underline ml-1"
                  >
                    Upgrade
                  </button>
                </p>
              )}

              {/* Show dropdowns only if checkbox is checked */}
              {modulateOnLoop && (
                <div className="flex items-center space-x-4">
                  {/* Interval dropdown */}
                  <div>
                    <label className="block text-xs">Interval</label>
                    <select
                      value={modLoopInterval}
                      onChange={(e) =>
                        setModLoopInterval(Number(e.target.value))
                      }
                      className="border rounded px-1 py-0.5"
                      disabled={isPlaying}
                    >
                      <option value={1}>m2 (1 semitone)</option>
                      <option value={2}>M2 (2 semitones)</option>
                      <option value={3}>m3 (3 semitones)</option>
                      <option value={4}>M3 (4 semitones)</option>
                      <option value={5}>P4 (5 semitones)</option>
                      <option value={6}>TT (6 semitones)</option>
                      <option value={7}>P5 (7 semitones)</option>
                      <option value={8}>m6 (8 semitones)</option>
                      <option value={9}>M6 (9 semitones)</option>
                      <option value={10}>m7 (10 semitones)</option>
                      <option value={11}>M7 (11 semitones)</option>
                    </select>
                  </div>

                  {/* Direction dropdown */}
                  <div>
                    <label className="block text-xs">Direction</label>
                    <select
                      value={modLoopDirection}
                      onChange={(e) => setModLoopDirection(e.target.value)}
                      className="border rounded px-1 py-0.5"
                      disabled={isPlaying}
                    >
                      <option value="up">Up</option>
                      <option value="down">Down</option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>

          {modulationDisplay.isActive && (
            <div className="p-3 mb-4 bg-blue-50 border border-blue-200 rounded text-center">
              <span className="font-medium text-blue-600">
                Active Modulation:
              </span>
              <span className="ml-2">
                {modulationDisplay.fromName} → {modulationDisplay.toName}
              </span>
            </div>
          )}

          {/* Measures list & editor */}
          <div className="space-y-4">
            {measures.map((measure, idx) => {
              const isLast = idx === measures.length - 1;
              const insertLabel = isLast ? "Add measure" : "Insert measure";

              return (
                <div
                  key={idx}
                  ref={(el) => {
                    // Store a reference to this measure's container
                    measureDetailRefs.current[idx] = el;
                  }}
                  className="p-4 border rounded-lg bg-gray-50"
                >
                  <div className="flex items-center justify-between mb-2">
                    {/* Updated to display the user-defined measure number instead of idx+1 */}

                    <h3 className="font-bold">
                      Measure {measure.measureNumber}
                    </h3>

                    <div className="flex items-center space-x-2">
                      {measures.length > 1 && (
                        <button
                          onClick={() =>
                            setMeasures((prev) =>
                              prev.filter((_, i2) => i2 !== idx)
                            )
                          }
                          className="text-red-500 hover:text-red-700"
                          disabled={isPlaying}
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Beats per measure
                      </label>
                      <select
                        value={measure.numerator}
                        onChange={(e) =>
                          updateMeasure(idx, {
                            numerator: Number(e.target.value),
                          })
                        }
                        className="p-2 border rounded w-full"
                        disabled={isPlaying}
                      >
                        {Array.from({ length: 24 }, (_, i2) => i2 + 1).map(
                          (num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium mb-1">
                        Beat unit
                      </label>
                      <select
                        value={measure.denominator}
                        onChange={(e) =>
                          updateMeasure(idx, {
                            denominator: Number(e.target.value),
                          })
                        }
                        className="p-2 border rounded w-full"
                        disabled={isPlaying}
                      >
                        {[1, 2, 4, 8, 16, 32].map((den) => (
                          <option key={den} value={den}>
                            1/{den}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {
                    // SHOW 2 checkboxes if 5/4, 5/8, 5/16 or 5/32
                    measure.numerator === 5 &&
                      [4, 8, 16, 32].includes(measure.denominator) && (
                        <div className="mt-2">
                          <div className="font-medium mb-1 text-sm">
                            Groupings for {measure.numerator}/
                            {measure.denominator}
                          </div>

                          <label className="inline-flex items-center mr-3">
                            <input
                              type="checkbox"
                              checked={!!measure.g2plus3}
                              onChange={(e) =>
                                handleGroupingCheckbox(
                                  idx,
                                  "g2plus3",
                                  e.target.checked
                                )
                              }
                            />
                            <span className="ml-1 text-xs">2+3</span>
                          </label>

                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              checked={!!measure.g3plus2}
                              onChange={(e) =>
                                handleGroupingCheckbox(
                                  idx,
                                  "g3plus2",
                                  e.target.checked
                                )
                              }
                            />
                            <span className="ml-1 text-xs">3+2</span>
                          </label>
                        </div>
                      )
                  }

                  {
                    // SHOW 3 checkboxes if 7/4, 7/8, 7/16 or 7/32
                    measure.numerator === 7 &&
                      [4, 8, 16, 32].includes(measure.denominator) && (
                        <div className="mt-2">
                          <div className="font-medium mb-1 text-sm">
                            Groupings for {measure.numerator}/
                            {measure.denominator}
                          </div>

                          <label className="inline-flex items-center mr-3">
                            <input
                              type="checkbox"
                              checked={!!measure.g2p2p3}
                              onChange={(e) =>
                                handleGroupingCheckbox(
                                  idx,
                                  "g2p2p3",
                                  e.target.checked
                                )
                              }
                            />
                            <span className="ml-1 text-xs">2+2+3</span>
                          </label>

                          <label className="inline-flex items-center mr-3">
                            <input
                              type="checkbox"
                              checked={!!measure.g3p2p2}
                              onChange={(e) =>
                                handleGroupingCheckbox(
                                  idx,
                                  "g3p2p2",
                                  e.target.checked
                                )
                              }
                            />
                            <span className="ml-1 text-xs">3+2+2</span>
                          </label>

                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              checked={!!measure.g2p3p2}
                              onChange={(e) =>
                                handleGroupingCheckbox(
                                  idx,
                                  "g2p3p2",
                                  e.target.checked
                                )
                              }
                            />
                            <span className="ml-1 text-xs">2+3+2</span>
                          </label>
                        </div>
                      )
                  }

                  <div className="flex items-center justify-between mb-2">
                    <h4 className="font-bold text-sm">Beats</h4>
                    <button
                      type="button"
                      onClick={() => toggleBeatsCollapse(idx)} // <-- toggles the current measure's beats
                      className="flex items-center space-x-1 text-sm font-bold"
                    >
                      {collapsedBeats[idx] ? (
                        <ChevronDown className="w-4 h-4" />
                      ) : (
                        <ChevronUp className="w-4 h-4" />
                      )}
                    </button>
                  </div>

                  {/* Conditionally render the beats grid if NOT collapsed */}
                  {!collapsedBeats[idx] && (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 mb-4">
                      {Array.from({ length: measure.numerator }).map(
                        (_, bIndex) => {
                          const beatSound =
                            measure.beatSounds[bIndex] || createNewBeat();

                          return (
                            <div
                              key={bIndex}
                              className="flex flex-col p-2 border rounded bg-white text-xs"
                            >
                              <div className="font-semibold mb-1">
                                Beat {bIndex + 1}
                              </div>

                              {/* pick a “sound” for the click */}
                              <div className="flex w-full items-center mb-2">
                                <select
                                  value={beatSound.id}
                                  onChange={(e) => {
                                    const chosen =
                                      SOUND_OPTIONS.find(
                                        (s) => s.id === e.target.value
                                      ) || SOUND_OPTIONS[0];
                                    updateBeatSound(idx, bIndex, {
                                      ...beatSound,
                                      ...chosen,
                                    });
                                  }}
                                  className="flex-1 p-1 text-xs border rounded"
                                  disabled={isPlaying}
                                >
                                  {SOUND_OPTIONS.map((sOpt) => (
                                    <option key={sOpt.id} value={sOpt.id}>
                                      {sOpt.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <label className="block mb-1">
                                Click Volume: {beatSound.userVolume.toFixed(2)}
                              </label>
                              <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={beatSound.userVolume}
                                style={{ accentColor: "#307BBF" }}
                                onChange={(e) =>
                                  updateBeatSound(idx, bIndex, {
                                    ...beatSound,
                                    userVolume: Number(e.target.value),
                                  })
                                }
                                disabled={isPlaying}
                                className="w-full mb-2"
                              />

                              {/* Subdivisions */}
                              {beatSound.subdivisions.map((sub, subIndex) => (
                                <div
                                  id="subdivisions"
                                  key={subIndex}
                                  className="p-2 border rounded bg-white text-xs mb-2"
                                >
                                  <label className="block mb-1 text-sm font-medium">
                                    Subdivision {subIndex + 1}
                                  </label>

                                  <select
                                    value={sub.subCount}
                                    onChange={(e) => {
                                      const newSubCount = Number(
                                        e.target.value
                                      );
                                      updateSubdivision(idx, bIndex, subIndex, {
                                        subCount: newSubCount,
                                      });
                                    }}
                                    className="p-1 border rounded w-full mb-2"
                                  >
                                    {Array.from(
                                      { length: 12 },
                                      (_, i) => i + 1
                                    ).map((n) => (
                                      <option key={n} value={n}>
                                        {n === 1 ? "No subdiv" : n}
                                      </option>
                                    ))}
                                  </select>

                                  <label className="block mb-1 text-sm font-medium">
                                    Accent these subdivisions
                                  </label>
                                  <AccentInput
                                    defaultValue={sub.accentedSubs.join(",")}
                                    onAccentsChange={(parsedArray) => {
                                      updateSubdivision(idx, bIndex, subIndex, {
                                        accentedSubs: parsedArray,
                                      });
                                    }}
                                  />

                                  {subscriptionTier === "pro" ? (
                                    <>
                                      <label className="block mb-1 text-sm font-medium mt-2">
                                        Mute these subdivisions
                                      </label>
                                      <AccentInput
                                        defaultValue={sub.mutedSubs.join(",")}
                                        onAccentsChange={(parsedArray) => {
                                          updateSubdivision(
                                            idx,
                                            bIndex,
                                            subIndex,
                                            {
                                              mutedSubs: parsedArray,
                                            }
                                          );
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <p className="text-xs text-gray-400 mt-2">
                                      <strong>Mute subdivisions</strong> is a
                                      Pro feature.
                                      <button
                                        onClick={() =>
                                          handleUpgrade(currentUser)
                                        }
                                        className="text-blue-600 underline ml-1"
                                      >
                                        Upgrade
                                      </button>
                                    </p>
                                  )}

                                  {/* Remove Button */}
                                  {beatSound.subdivisions.length > 1 && (
                                    <button
                                      onClick={() =>
                                        removeSubdivision(idx, bIndex, subIndex)
                                      }
                                      className="mt-1 px-2 py-1 text-xs bg-[#9F2C07] text-white rounded"
                                    >
                                      Remove This Subdivision
                                    </button>
                                  )}
                                </div>
                              ))}
                              {
                                // 1) Still limit total subdivisions to < 5
                                beatSound.subdivisions.length < 5 &&
                                  // 2) Check that the first subdivision entry actually exists
                                  beatSound.subdivisions[0] &&
                                  // 3) And that the first subdivision’s subCount > 1
                                  beatSound.subdivisions[0].subCount > 1 && (
                                    <button
                                      onClick={() =>
                                        addNewSubdivision(idx, bIndex)
                                      }
                                      className="px-2 py-1 bg-[#B71E4B] text-white text-xs rounded"
                                    >
                                      + Add Another Subdivision
                                    </button>
                                  )
                              }

                              {/* Drone pitch */}
                              <label className="block mb-1 text-xs font-medium mt-2">
                                Drone Pitch:
                              </label>
                              <div className="flex space-x-2">
                                <select
                                  // Convert null -> "null" so the "Off" option is selected in the UI
                                  value={
                                    beatSound.dronePitchNote === null
                                      ? "null"
                                      : beatSound.dronePitchNote
                                  }
                                  onChange={(e) =>
                                    updateBeatSound(idx, bIndex, {
                                      ...beatSound,
                                      dronePitchNote:
                                        e.target.value === "null"
                                          ? null
                                          : Number(e.target.value),
                                    })
                                  }
                                  disabled={isPlaying}
                                  className="p-1 border rounded"
                                >
                                  {NOTE_OPTIONS.map((opt) => (
                                    <option
                                      key={opt.value}
                                      // Pass "null" if opt.value is null, otherwise numeric
                                      value={
                                        opt.value === null ? "null" : opt.value
                                      }
                                    >
                                      {opt.label}
                                    </option>
                                  ))}
                                </select>

                                <select
                                  value={beatSound.dronePitchOctave}
                                  onChange={(e) =>
                                    updateBeatSound(idx, bIndex, {
                                      ...beatSound,
                                      dronePitchOctave: Number(e.target.value),
                                    })
                                  }
                                  disabled={
                                    isPlaying ||
                                    beatSound.dronePitchNote === null
                                  }
                                  className="p-1 border rounded"
                                >
                                  {OCTAVE_OPTIONS.map((opt) => (
                                    <option key={opt.value} value={opt.value}>
                                      {opt.label}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {/* Drone volume / chord booleans */}
                              <label className="block mt-2 text-xs font-medium">
                                Drone Volume:
                              </label>
                              <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={beatSound.droneVolume}
                                style={{ accentColor: "#307BBF" }}
                                onChange={(e) =>
                                  updateBeatSound(idx, bIndex, {
                                    ...beatSound,
                                    droneVolume: Number(e.target.value),
                                  })
                                }
                                disabled={isPlaying}
                                className="w-full mb-2"
                              />

                              <div className="flex items-center space-x-2 mb-1">
                                <input
                                  type="checkbox"
                                  checked={beatSound.hasHarmony}
                                  onChange={(e) =>
                                    updateBeatSound(idx, bIndex, {
                                      ...beatSound,
                                      hasHarmony: e.target.checked,
                                    })
                                  }
                                  disabled={
                                    isPlaying ||
                                    beatSound.dronePitchNote === null
                                  }
                                />
                                <span>Harmony</span>
                              </div>
                              {beatSound.hasHarmony && (
                                <div className="mb-2">
                                  <label className="block mb-1 text-xs font-medium">
                                    Chord Type:
                                  </label>
                                  <select
                                    value={beatSound.selectedHarmony}
                                    onChange={(e) =>
                                      updateBeatSound(idx, bIndex, {
                                        ...beatSound,
                                        selectedHarmony: e.target.value,
                                      })
                                    }
                                    disabled={isPlaying}
                                    className="p-1 border rounded w-full"
                                  >
                                    {Object.keys(CHORDS_ET).map((chName) => (
                                      <option key={chName} value={chName}>
                                        {chName}
                                      </option>
                                    ))}
                                  </select>

                                  <label className="flex items-center space-x-2 mt-2">
                                    <input
                                      type="checkbox"
                                      checked={beatSound.justIntonation}
                                      onChange={(e) =>
                                        updateBeatSound(idx, bIndex, {
                                          ...beatSound,
                                          justIntonation: e.target.checked,
                                        })
                                      }
                                      disabled={isPlaying}
                                    />
                                    <span>Just Intonation</span>
                                  </label>
                                </div>
                              )}
                              <div className="my-2">
                                {/* “Any notes” toggle (always visible) */}
                                <label className="flex items-center space-x-2 mb-1">
                                  <input
                                    type="checkbox"
                                    checked={beatSound.useCustomNotes}
                                    onChange={(e) =>
                                      updateBeatSound(idx, bIndex, {
                                        ...beatSound,
                                        useCustomNotes: e.target.checked,
                                      })
                                    }
                                    disabled={
                                      isPlaying ||
                                      beatSound.dronePitchNote === null
                                    }
                                  />
                                  <span>Any notes</span>
                                </label>

                                {/* If user wants “Any notes,” show checkboxes for the 12 semitones */}
                                {beatSound.useCustomNotes && (
                                  <div className="flex flex-wrap">
                                    {NOTE_OPTIONS_12.map((opt) => {
                                      const isSelected =
                                        beatSound.selectedCustomNotes.includes(
                                          opt.value
                                        );
                                      return (
                                        <label
                                          key={opt.value}
                                          className="flex items-center m-1"
                                        >
                                          <input
                                            type="checkbox"
                                            checked={isSelected}
                                            onChange={() =>
                                              toggleCustomNote(
                                                idx,
                                                bIndex,
                                                opt.value
                                              )
                                            }
                                            disabled={
                                              isPlaying ||
                                              beatSound.dronePitchNote === null
                                            }
                                          />
                                          <span className="ml-1">
                                            {opt.label}
                                          </span>
                                        </label>
                                      );
                                    })}
                                  </div>
                                )}
                              </div>

                              {/* Partials */}
                              <div className="block">
                                <label className="flex items-center space-x-2 mb-2">
                                  <input
                                    type="checkbox"
                                    checked={beatSound.usePartials}
                                    onChange={(e) =>
                                      updateBeatSound(idx, bIndex, {
                                        ...beatSound,
                                        usePartials: e.target.checked,
                                      })
                                    }
                                    disabled={
                                      isPlaying ||
                                      beatSound.dronePitchNote === null
                                    }
                                  />
                                  <span>Partials</span>
                                </label>

                                {beatSound.usePartials && (
                                  <div className="flex flex-wrap">
                                    {Array.from(
                                      { length: 14 },
                                      (_, i) => i + 1
                                    ).map((partial) => (
                                      <div key={partial} className="mr-2">
                                        <label className="flex items-center space-x-1">
                                          <input
                                            type="checkbox"
                                            checked={beatSound.selectedPartials.includes(
                                              partial
                                            )}
                                            onChange={() =>
                                              togglePartial(
                                                idx,
                                                bIndex,
                                                partial
                                              )
                                            }
                                            disabled={isPlaying}
                                          />
                                          <span>{partial}</span>
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                )}

                                {subscriptionTier === "pro" ? (
                                  <div className="mt-2">
                                    {/* Quarter Tones Checkbox */}
                                    <label className="flex items-center space-x-2 mb-4">
                                      <input
                                        type="checkbox"
                                        checked={beatSound.useQuarterTones}
                                        onChange={(e) =>
                                          updateBeatSound(idx, bIndex, {
                                            ...beatSound,
                                            useQuarterTones: e.target.checked,
                                          })
                                        }
                                      />
                                      <span>Quarter Tones</span>
                                    </label>

                                    {/* Only show the octave selector & quarter-tone checkboxes if useQuarterTones is true */}
                                    {beatSound.useQuarterTones && (
                                      <>
                                        {/* 1) Octave Selector */}
                                        <div className="mb-2">
                                          <label className="block text-xs font-semibold mb-1">
                                            Quarter Tone Octave
                                          </label>
                                          <select
                                            value={
                                              beatSound.quarterToneOctave ?? 4
                                            }
                                            onChange={(e) =>
                                              updateBeatSound(idx, bIndex, {
                                                ...beatSound,
                                                quarterToneOctave: Number(
                                                  e.target.value
                                                ),
                                              })
                                            }
                                            disabled={isPlaying}
                                            className="border p-1 rounded"
                                          >
                                            {[3, 4, 5, 6].map((oct) => (
                                              <option key={oct} value={oct}>
                                                Octave {oct}
                                              </option>
                                            ))}
                                          </select>
                                        </div>

                                        {/* 2) Quarter-Sharp Note Checkboxes */}
                                        <div className="flex flex-wrap">
                                          {QUARTER_SHARP_OPTIONS.map((qt) => {
                                            const isSelected =
                                              beatSound.selectedQuarterTones.includes(
                                                qt.value
                                              );
                                            return (
                                              <label
                                                key={qt.value}
                                                className="flex items-center mr-2 mb-4"
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={isSelected}
                                                  onChange={() => {
                                                    let newSelected = [
                                                      ...beatSound.selectedQuarterTones,
                                                    ];
                                                    if (isSelected) {
                                                      newSelected =
                                                        newSelected.filter(
                                                          (val) =>
                                                            val !== qt.value
                                                        );
                                                    } else {
                                                      newSelected.push(
                                                        qt.value
                                                      );
                                                    }
                                                    updateBeatSound(
                                                      idx,
                                                      bIndex,
                                                      {
                                                        ...beatSound,
                                                        useQuarterTones: true,
                                                        selectedQuarterTones:
                                                          newSelected,
                                                      }
                                                    );
                                                  }}
                                                />
                                                <span className="ml-1">
                                                  {qt.label}
                                                </span>
                                              </label>
                                            );
                                          })}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : (
                                  <p className="text-xs text-gray-500 mb-4">
                                    <strong>Quarter tones</strong> are a Pro
                                    feature.
                                    {/* Optional: Add an upgrade button or link */}
                                    <button
                                      onClick={() => handleUpgrade(currentUser)}
                                      className="text-blue-600 underline ml-1"
                                    >
                                      Upgrade
                                    </button>
                                  </p>
                                )}

                                {/* For Beat 1 only, add a "Match Pitch" checkbox */}
                                {bIndex === 0 && (
                                  <div className="flex items-center space-x-2 mb-2">
                                    <input
                                      type="checkbox"
                                      checked={beatSound.noPitchAccent}
                                      onChange={(e) =>
                                        updateBeatSound(idx, bIndex, {
                                          ...beatSound,
                                          noPitchAccent: e.target.checked,
                                        })
                                      }
                                      disabled={isPlaying}
                                    />
                                    <span>Remove Beat 1 Emphasis</span>
                                  </div>
                                )}

                                {bIndex === 0 && (
                                  <label className="flex items-center space-x-2 mb-2">
                                    <input
                                      type="checkbox"
                                      checked={
                                        measure.applyBeatOneDroneToAllBeats
                                      }
                                      onChange={(e) =>
                                        updateMeasure(idx, {
                                          applyBeatOneDroneToAllBeats:
                                            e.target.checked,
                                        })
                                      }
                                      disabled={isPlaying}
                                    />
                                    <span className="text-xs">
                                      Apply Harmony to All Beats
                                    </span>
                                  </label>
                                )}
                              </div>

                              {/* ALWAYS VISIBLE: Copy to next beat */}
                              <label className="flex items-center space-x-2 mb-2">
                                <input
                                  type="checkbox"
                                  checked={
                                    beatSound.copyDroneHarmonyPartialsToNext
                                  }
                                  onChange={(e) =>
                                    updateBeatSound(idx, bIndex, {
                                      ...beatSound,
                                      copyDroneHarmonyPartialsToNext:
                                        e.target.checked,
                                    })
                                  }
                                  disabled={
                                    isPlaying ||
                                    bIndex === measure.numerator - 1
                                  }
                                />
                                <span className="text-xs">
                                  Copy all settings to next beat
                                </span>
                              </label>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}

                  {/* Metric Modulation */}
                  {/* Metric Modulation Box */}
                  <div className="p-2 border rounded bg-gray-50 mt-2 mb-2">
                    <div className="flex items-center justify-between mb-2">
                      <div
                        id="metricmodulation"
                        className="font-medium text-sm"
                      >
                        Metric Modulation
                      </div>

                      {subscriptionTier === "basic" ||
                      subscriptionTier === "pro" ? (
                        /* Render the checkbox if user has Basic (or Pro) */
                        <label className="flex items-center">
                          <input
                            type="checkbox"
                            checked={measure.hasModulation}
                            onChange={(e) =>
                              updateMeasure(idx, {
                                hasModulation: e.target.checked,
                              })
                            }
                            disabled={isPlaying}
                            className="mr-2"
                          />
                          <span className="text-sm font-medium">
                            Enable metric modulation to next measure
                          </span>
                        </label>
                      ) : (
                        /* Otherwise, user is below Basic tier; show upgrade notice */
                        <p className="text-xs text-gray-500">
                          <strong>Metric Modulation</strong> is a Basic feature.
                          <button
                            onClick={() => handleUpgrade(currentUser)}
                            className="text-blue-600 underline ml-1"
                          >
                            Upgrade
                          </button>
                        </p>
                      )}
                    </div>

                    {subscriptionTier === "basic" || subscriptionTier === "pro"
                      ? /* Render the Subdivision inputs when user has Basic (or Pro)
       AND the checkbox is checked */
                        measure.hasModulation && (
                          <div className="grid grid-cols-2 gap-4 mt-2">
                            {/* From Subdivision (this measure) */}
                            <div>
                              <label className="block text-sm font-medium mb-1">
                                From Subdivision (this measure)
                              </label>
                              <select
                                value={measure.fromSubdivision.value}
                                onChange={(e) => {
                                  const newVal = parseFloat(e.target.value);
                                  const found = ALL_SUBDIVISIONS.find(
                                    (sub) => sub.value === newVal
                                  );
                                  if (found) {
                                    updateMeasure(idx, {
                                      fromSubdivision: found,
                                    });
                                  }
                                }}
                                className="p-1 border rounded w-full"
                                disabled={isPlaying}
                              >
                                {ALL_SUBDIVISIONS.map((sub) => (
                                  <option key={sub.value} value={sub.value}>
                                    {sub.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            {/* To Subdivision (next measure) */}
                            <div>
                              <label className="block text-sm font-medium mb-1">
                                To Subdivision (next measure)
                              </label>
                              <select
                                value={measure.toSubdivision.value}
                                onChange={(e) => {
                                  const newVal = parseFloat(e.target.value);
                                  const found = ALL_SUBDIVISIONS.find(
                                    (sub) => sub.value === newVal
                                  );
                                  if (found) {
                                    updateMeasure(idx, {
                                      toSubdivision: found,
                                    });
                                  }
                                }}
                                className="p-1 border rounded w-full"
                                disabled={isPlaying}
                              >
                                {ALL_SUBDIVISIONS.map((sub) => (
                                  <option key={sub.value} value={sub.value}>
                                    {sub.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )
                      : null}
                  </div>

                  {/* Tuplets */}
                  <div className="p-2 border rounded bg-gray-50">
                    <div className="flex items-center justify-between mb-2">
                      <div id="tuplets" className="font-medium text-sm">
                        Tuplets
                      </div>

                      {subscriptionTier === "pro" ? (
                        <button
                          onClick={() => {
                            if (!isPlaying) {
                              setTupletsUI({
                                measureIndex: idx,
                                beatsInput: "",
                                subCount: "",
                                soundId: "clicker",
                                addNested: false,
                                nestedBeatsInput: "",
                                nestedSubCount: "",
                                nestedSoundId: "clicker",
                                mutedSubdivisions: [],
                              });
                            }
                          }}
                          disabled={isPlaying}
                          className="px-2 py-1 text-xs bg-[#B71E4B] text-white rounded flex hover:[#CC235B] items-center"
                        >
                          <Plus className="w-3 h-3 mr-1" />
                          Add Tuplet
                        </button>
                      ) : (
                        <p className="text-xs text-gray-500">
                          <strong>Tuplets</strong> are a Pro feature.
                          <button
                            onClick={() => handleUpgrade(currentUser)}
                            className="text-blue-600 underline ml-1"
                          >
                            Upgrade
                          </button>
                        </p>
                      )}
                    </div>

                    {measure.tuplets && measure.tuplets.length > 0 && (
                      <ul className="ml-4 list-disc text-sm space-y-2">
                        {measure.tuplets.map((tup, pIdx) => {
                          // Look up the parent's sound name
                          const parentSoundOption = SOUND_OPTIONS.find(
                            (o) => o.id === tup.soundId
                          );
                          const parentSoundName = parentSoundOption
                            ? parentSoundOption.name
                            : tup.soundId;

                          return (
                            <li key={pIdx} className="mb-1">
                              {/* Display the parent tuplet row */}
                              <div className="flex items-center justify-between">
                                <span>
                                  Beats [{tup.selectedBeats.join(", ")}],
                                  subCount: {tup.subCount}, sound:{" "}
                                  {parentSoundName}
                                </span>
                                <div className="flex items-center space-x-2 ml-2">
                                  {/* Mute checkbox */}
                                  <label className="inline-flex items-center">
                                    <input
                                      type="checkbox"
                                      checked={tup.muted ?? false}
                                      onChange={(e) => {
                                        setMeasures((prev) =>
                                          prev.map((mm, i2) => {
                                            if (i2 !== idx) return mm;
                                            const newTuplets = mm.tuplets.map(
                                              (oldTup, t2) => {
                                                if (t2 !== pIdx) return oldTup;
                                                return {
                                                  ...oldTup,
                                                  muted: e.target.checked,
                                                };
                                              }
                                            );
                                            return {
                                              ...mm,
                                              tuplets: newTuplets,
                                            };
                                          })
                                        );
                                      }}
                                      disabled={isPlaying}
                                    />
                                    <span className="ml-1">Mute</span>
                                  </label>

                                  {/* Edit button */}
                                  <button
                                    onClick={() => startEditTuplet(idx, pIdx)}
                                    disabled={isPlaying}
                                    className="text-blue-500 hover:text-blue-700"
                                  >
                                    Edit
                                  </button>

                                  {/* Delete button */}
                                  <button
                                    onClick={() => {
                                      // remove this parent tuplet entirely
                                      setMeasures((prev) =>
                                        prev.map((mm, i2) => {
                                          if (i2 !== idx) return mm;
                                          return {
                                            ...mm,
                                            tuplets: mm.tuplets.filter(
                                              (_, i3) => i3 !== pIdx
                                            ),
                                          };
                                        })
                                      );
                                    }}
                                    className="text-red-500 hover:text-red-700"
                                    disabled={isPlaying}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>

                              {/* NESTED TUPLETS: Indented UI */}
                              {tup.nestedTuplets &&
                                tup.nestedTuplets.length > 0 && (
                                  <ul className="ml-6 list-disc text-sm space-y-1 mt-1">
                                    {tup.nestedTuplets.map((nest, nIdx) => {
                                      // find the nested tuplet's sound name
                                      const nestedSoundOption =
                                        SOUND_OPTIONS.find(
                                          (o) => o.id === nest.soundId
                                        );
                                      const nestedSoundName = nestedSoundOption
                                        ? nestedSoundOption.name
                                        : nest.soundId;

                                      return (
                                        <li
                                          key={nIdx}
                                          className="flex items-center justify-between"
                                        >
                                          <span>
                                            <em>Nested</em> Beats [
                                            {nest.selectedBeats.join(", ")}],
                                            subCount: {nest.subCount}, sound:{" "}
                                            {nestedSoundName}
                                          </span>
                                          <div className="flex items-center space-x-2 ml-2">
                                            {/* Mute checkbox for nested */}
                                            <label className="inline-flex items-center">
                                              <input
                                                type="checkbox"
                                                checked={nest.muted ?? false}
                                                onChange={(e) => {
                                                  setMeasures((prev) =>
                                                    prev.map((mm, i2) => {
                                                      if (i2 !== idx) return mm;
                                                      const newTuplets =
                                                        mm.tuplets.map(
                                                          (oldTup, t2) => {
                                                            if (t2 !== pIdx)
                                                              return oldTup;
                                                            const updatedNested =
                                                              oldTup.nestedTuplets.map(
                                                                (
                                                                  oldNest,
                                                                  n2
                                                                ) => {
                                                                  if (
                                                                    n2 !== nIdx
                                                                  )
                                                                    return oldNest;
                                                                  return {
                                                                    ...oldNest,
                                                                    muted:
                                                                      e.target
                                                                        .checked,
                                                                  };
                                                                }
                                                              );
                                                            return {
                                                              ...oldTup,
                                                              nestedTuplets:
                                                                updatedNested,
                                                            };
                                                          }
                                                        );
                                                      return {
                                                        ...mm,
                                                        tuplets: newTuplets,
                                                      };
                                                    })
                                                  );
                                                }}
                                                disabled={isPlaying}
                                              />
                                              <span className="ml-1">Mute</span>
                                            </label>

                                            {/* If you want an Edit Nested button */}
                                            <button
                                              onClick={() =>
                                                startEditNestedTuplet(
                                                  idx,
                                                  pIdx,
                                                  nIdx
                                                )
                                              }
                                              disabled={isPlaying}
                                              className="text-blue-500 hover:text-blue-700"
                                            >
                                              Edit
                                            </button>

                                            {/* Delete Nested */}
                                            <button
                                              onClick={() => {
                                                setMeasures((prev) =>
                                                  prev.map((mm, i2) => {
                                                    if (i2 !== idx) return mm;
                                                    const newTuplets =
                                                      mm.tuplets.map(
                                                        (oldTup, t2) => {
                                                          if (t2 !== pIdx)
                                                            return oldTup;
                                                          return {
                                                            ...oldTup,
                                                            nestedTuplets:
                                                              oldTup.nestedTuplets.filter(
                                                                (_, i3) =>
                                                                  i3 !== nIdx
                                                              ),
                                                          };
                                                        }
                                                      );
                                                    return {
                                                      ...mm,
                                                      tuplets: newTuplets,
                                                    };
                                                  })
                                                );
                                              }}
                                              className="text-red-500 hover:text-red-700"
                                              disabled={isPlaying}
                                            >
                                              Delete
                                            </button>
                                          </div>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}

                              {/* Button to ADD a new nested tuplet */}
                              <button
                                onClick={() => {
                                  if (!isPlaying) {
                                    setNestedTupletsUI({
                                      measureIndex: idx,
                                      parentTupletIndex: pIdx,
                                      beatsInput: "",
                                      subCount: "",
                                      soundId: "clicker",
                                      mutedSubdivisions: [],
                                    });
                                  }
                                }}
                                className="ml-6 mt-1 px-2 py-1 text-xs bg-[#B71E4B] text-white rounded hover:[#CC235B]"
                                disabled={isPlaying}
                              >
                                + Add Nested Tuplet
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    )}

                    {/* Add new tuplet UI */}
                    {tupletsUI && tupletsUI.measureIndex === idx && (
                      <div className="mt-3 border border-gray-300 p-2 rounded text-xs bg-white">
                        <div>
                          <label className="block font-medium mb-1">
                            Over which beats? (e.g. "2,3" or "3-5")
                          </label>
                          <AccentInput
                            defaultValue={tupletsUI.beatsInput}
                            onAccentsChange={(parsedArray) =>
                              setTupletsUI((prev) => ({
                                ...prev,
                                beatsInput: parsedArray.join(","),
                              }))
                            }
                          />
                        </div>
                        <div>
                          <label className="block font-medium mt-1 mb-1">
                            Tuplet (e.g. "3" is a triplet)
                          </label>
                          <AccentInput
                            defaultValue={String(tupletsUI.subCount || "")}
                            onAccentsChange={(parsedArray) =>
                              setTupletsUI((prev) => ({
                                ...prev,
                                subCount:
                                  parsedArray.length > 0
                                    ? String(parsedArray[0])
                                    : "",
                              }))
                            }
                          />
                        </div>
                        <div>
                          <label className="block font-medium mb-1 mt-2">
                            Mute these tuplets
                          </label>
                          <AccentInput
                            defaultValue={tupletsUI.mutedSubdivisions || ""} // Change from editingTupletUI to tupletsUI
                            onAccentsChange={(parsedArray) =>
                              setTupletsUI((prev) => ({
                                // Change from setEditingTupletUI to setTupletsUI
                                ...prev,
                                mutedSubdivisions: parsedArray,
                              }))
                            }
                          />
                          <p className="text-xs text-gray-500 mt-1">
                            Enter subdivision numbers to mute (e.g. "2,4" or
                            "1-3")
                          </p>
                        </div>

                        <div>
                          <label className="block font-medium mb-1">
                            Tuplet Sound
                          </label>
                          <select
                            value={tupletsUI.soundId}
                            onChange={(e) =>
                              setTupletsUI((prev) => ({
                                ...prev,
                                soundId: e.target.value,
                              }))
                            }
                            className="p-1 border rounded w-full"
                          >
                            {SOUND_OPTIONS.map((opt) => (
                              <option key={opt.id} value={opt.id}>
                                {opt.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="flex space-x-2 mt-2">
                          <button
                            onClick={() => {
                              const beatsArr = parseBeatsInput(
                                tupletsUI.beatsInput
                              );
                              const sc = parseInt(tupletsUI.subCount, 10);
                              if (!beatsArr.length || !sc) {
                                setTupletsUI(null);
                                return;
                              }
                              const newTuplet = {
                                selectedBeats: beatsArr,
                                subCount: sc,
                                soundId: tupletsUI.soundId || "clicker",
                                nestedTuplets: [],
                                mutedSubdivisions:
                                  tupletsUI.mutedSubdivisions || [],
                              };
                              if (tupletsUI.addNested) {
                                const nestedArr = parseBeatsInput(
                                  tupletsUI.nestedBeatsInput
                                );
                                const nsc = parseInt(
                                  tupletsUI.nestedSubCount,
                                  10
                                );
                                if (nestedArr.length && nsc) {
                                  newTuplet.nestedTuplets.push({
                                    selectedBeats: nestedArr,
                                    subCount: nsc,
                                    soundId:
                                      tupletsUI.nestedSoundId || "clicker",
                                    nestedTuplets: [],
                                    mutedSubdivisions:
                                      tupletsUI.mutedSubdivisions || [],
                                  });
                                }
                              }
                              setMeasures((prev) =>
                                prev.map((mm, i2) => {
                                  if (i2 !== idx) return mm;
                                  return {
                                    ...mm,
                                    tuplets: [...mm.tuplets, newTuplet],
                                  };
                                })
                              );
                              setTupletsUI(null);
                            }}
                            className="px-2 py-1 text-xs bg-[#059dd9] text-white rounded hover:bg-[#346CBF]"
                          >
                            Save
                          </button>
                          <button
                            onClick={() => setTupletsUI(null)}
                            className="px-2 py-1 text-xs bg-gray-400 text-white rounded hover:bg-gray-500"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Editing existing tuplet UI */}
                    {editingTupletUI &&
                      editingTupletUI.measureIndex === idx && (
                        <div className="mt-3 border p-2 rounded text-xs bg-white">
                          <div>
                            <label className="block font-medium mb-1">
                              Over which beats? (e.g. "2,3" or "3-5")
                            </label>
                            {/* Replace standard input with AccentInput */}
                            <AccentInput
                              defaultValue={editingTupletUI.beatsInput}
                              onAccentsChange={(parsedArray) =>
                                setEditingTupletUI((prev) => ({
                                  ...prev,
                                  beatsInput: parsedArray.join(","), // Store as comma string for consistency
                                }))
                              }
                            />
                          </div>
                          <div>
                            <label className="block font-medium mb-1">
                              Tuplet (e.g. "3" is a triplet)
                            </label>
                            <AccentInput
                              defaultValue={String(
                                editingTupletUI.subCount || ""
                              )}
                              onAccentsChange={(parsedArray) =>
                                setEditingTupletUI((prev) => ({
                                  ...prev,
                                  subCount:
                                    parsedArray.length > 0
                                      ? String(parsedArray[0])
                                      : "",
                                }))
                              }
                            />
                          </div>
                          <div>
                            <label className="block font-medium mb-1 mt-2">
                              Mute these tuplets
                            </label>
                            <AccentInput
                              defaultValue={
                                editingTupletUI.mutedSubdivisions || ""
                              }
                              onAccentsChange={(parsedArray) =>
                                setEditingTupletUI((prev) => ({
                                  ...prev,
                                  mutedSubdivisions: parsedArray,
                                }))
                              }
                            />
                            <p className="text-xs text-gray-500 mt-1">
                              Enter subdivision numbers to mute (e.g. "2,4" or
                              "1-3")
                            </p>
                          </div>
                          <div>
                            <label className="block font-medium mb-1">
                              Tuplet Sound
                            </label>
                            <select
                              value={editingTupletUI.soundId}
                              onChange={(e) =>
                                setEditingTupletUI((prev) => ({
                                  ...prev,
                                  soundId: e.target.value,
                                }))
                              }
                              className="p-1 border rounded w-full"
                            >
                              {SOUND_OPTIONS.map((opt) => (
                                <option key={opt.id} value={opt.id}>
                                  {opt.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="flex space-x-2 mt-2">
                            <button
                              onClick={saveEditedTuplet}
                              className="px-2 py-1 text-xs bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                              Save
                            </button>
                            <button
                              onClick={() => setEditingTupletUI(null)}
                              className="px-2 py-1 text-xs bg-gray-400 text-white rounded hover:bg-gray-500"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}

                    {nestedTupletsUI &&
                      nestedTupletsUI.measureIndex === idx && (
                        <div className="mt-2 ml-6 border p-2 rounded text-xs bg-white">
                          <div>
                            <label className="block font-medium mb-1">
                              Nested Beats (e.g. "2,3" or "2-4")
                            </label>
                            <AccentInput
                              defaultValue={nestedTupletsUI.beatsInput}
                              onAccentsChange={(parsedArray) =>
                                setNestedTupletsUI((prev) => ({
                                  ...prev,
                                  beatsInput: parsedArray.join(","),
                                }))
                              }
                            />
                          </div>
                          <div>
                            <label className="block font-medium mb-1">
                              Nested Tuplet
                            </label>
                            <AccentInput
                              defaultValue={String(
                                nestedTupletsUI.subCount || ""
                              )}
                              onAccentsChange={(parsedArray) =>
                                setNestedTupletsUI((prev) => ({
                                  ...prev,
                                  subCount:
                                    parsedArray.length > 0
                                      ? String(parsedArray[0])
                                      : "",
                                }))
                              }
                            />
                          </div>

                          <div>
                            <label className="block font-medium mb-1 mt-2">
                              Mute these subdivisions
                            </label>
                            <AccentInput
                              defaultValue={
                                nestedTupletsUI.mutedSubdivisions || ""
                              } // Change from editingNestedTupletUI to nestedTupletsUI
                              onAccentsChange={(parsedArray) =>
                                setNestedTupletsUI((prev) => ({
                                  // Change from setEditingNestedTupletUI to setNestedTupletsUI
                                  ...prev,
                                  mutedSubdivisions: parsedArray,
                                }))
                              }
                            />
                            <p className="text-xs text-gray-500 mt-1">
                              Enter subdivision numbers to mute (e.g. "2,4" or
                              "1-3")
                            </p>
                          </div>
                          <div>
                            <label className="block font-medium mb-1">
                              Nested Sound
                            </label>
                            <select
                              value={nestedTupletsUI.soundId}
                              onChange={(e) =>
                                setNestedTupletsUI((prev) => ({
                                  ...prev,
                                  soundId: e.target.value,
                                }))
                              }
                              className="p-1 border rounded w-full"
                            >
                              {SOUND_OPTIONS.map((opt) => (
                                <option key={opt.id} value={opt.id}>
                                  {opt.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="flex space-x-2 mt-2">
                            <button
                              onClick={() => {
                                const beatsArr = parseBeatsInput(
                                  nestedTupletsUI.beatsInput
                                );
                                const sc = parseInt(
                                  nestedTupletsUI.subCount,
                                  10
                                );
                                if (!beatsArr.length || !sc) {
                                  setNestedTupletsUI(null);
                                  return;
                                }
                                // Build the new nested tuplet object
                                const newNested = {
                                  selectedBeats: beatsArr,
                                  subCount: sc,
                                  soundId: nestedTupletsUI.soundId || "clicker",
                                  nestedTuplets: [],
                                  mutedSubdivisions:
                                    nestedTupletsUI.mutedSubdivisions || [],
                                };
                                // Insert into the parent's nestedTuplets
                                setMeasures((prev) =>
                                  prev.map((mm, i2) => {
                                    if (i2 !== idx) return mm;
                                    const newTuplets = mm.tuplets.map(
                                      (oldTup, t2) => {
                                        if (
                                          t2 !==
                                          nestedTupletsUI.parentTupletIndex
                                        )
                                          return oldTup;
                                        return {
                                          ...oldTup,
                                          nestedTuplets: [
                                            ...(oldTup.nestedTuplets || []),
                                            newNested,
                                          ],
                                        };
                                      }
                                    );
                                    return { ...mm, tuplets: newTuplets };
                                  })
                                );
                                // Close the UI
                                setNestedTupletsUI(null);
                              }}
                              className="px-2 py-1 text-xs bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                              Save
                            </button>
                            <button
                              onClick={() => setNestedTupletsUI(null)}
                              className="px-2 py-1 text-xs bg-gray-400 text-white rounded hover:bg-gray-500"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}

                    {editingNestedTupletUI &&
                      editingNestedTupletUI.measureIndex === idx && (
                        <div className="mt-2 ml-6 border p-2 rounded text-xs bg-white">
                          <div>
                            <label className="block font-medium mb-1">
                              Nested Beats (e.g. "2,3" or "2-4")
                            </label>
                            <AccentInput
                              defaultValue={editingNestedTupletUI.beatsInput}
                              onAccentsChange={(parsedArray) =>
                                setEditingNestedTupletUI((prev) => ({
                                  ...prev,
                                  beatsInput: parsedArray.join(","),
                                }))
                              }
                            />
                          </div>
                          <div>
                            <label className="block font-medium mb-1">
                              Nested Tuplet
                            </label>
                            <AccentInput
                              defaultValue={String(
                                editingNestedTupletUI.subCount || ""
                              )}
                              onAccentsChange={(parsedArray) =>
                                setEditingNestedTupletUI((prev) => ({
                                  ...prev,
                                  subCount:
                                    parsedArray.length > 0
                                      ? String(parsedArray[0])
                                      : "",
                                }))
                              }
                            />
                          </div>

                          <div>
                            <label className="block font-medium mb-1 mt-2">
                              Mute these subdivisions
                            </label>
                            <AccentInput
                              defaultValue={
                                editingNestedTupletUI.mutedSubdivisions || ""
                              }
                              onAccentsChange={(parsedArray) =>
                                setEditingNestedTupletUI((prev) => ({
                                  ...prev,
                                  mutedSubdivisions: parsedArray,
                                }))
                              }
                            />
                            <p className="text-xs text-gray-500 mt-1">
                              Enter subdivision numbers to mute (e.g. "2,4" or
                              "1-3")
                            </p>
                          </div>
                          <div>
                            <label className="block font-medium mb-1">
                              Nested Sound
                            </label>
                            <select
                              value={editingNestedTupletUI.soundId}
                              onChange={(e) =>
                                setEditingNestedTupletUI((prev) => ({
                                  ...prev,
                                  soundId: e.target.value,
                                }))
                              }
                              className="p-1 border rounded w-full"
                            >
                              {SOUND_OPTIONS.map((opt) => (
                                <option key={opt.id} value={opt.id}>
                                  {opt.name}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="flex space-x-2 mt-2">
                            <button
                              onClick={saveEditedNestedTuplet}
                              className="px-2 py-1 text-xs bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                              Save
                            </button>
                            <button
                              onClick={() => setEditingNestedTupletUI(null)}
                              className="px-2 py-1 text-xs bg-gray-400 text-white rounded hover:bg-gray-500"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                  </div>

                  {/* Insert / Copy measure */}
                  <div className="mt-4 flex justify-center space-x-2">
                    <button
                      onClick={() => insertMeasureBelow(idx)}
                      className="px-2 py-1 text-xs bg-[#059dd9] text-white rounded hover:bg-[#307bbf] shadow-md"
                      disabled={isPlaying}
                    >
                      {insertLabel}
                    </button>
                    <button
                      onClick={() => copyMeasureBelow(idx)}
                      className="px-2 py-1 text-xs bg-[#059dd9] text-white rounded hover:bg-[#307bbf] shadow-md"
                      disabled={isPlaying}
                    >
                      Copy measure
                    </button>
                    <button
                      onClick={returnToOverview}
                      className="px-2 py-1 text-xs bg-[#307BBF] text-white rounded hover:bg-[#C64940] shadow-md"
                    >
                      Return to Overview
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      {activeTab === "about" && <AboutTab />}
      {activeTab === "practiceTracker" && <PracticeTrackerTab />}
      {activeTab === "practiceRoom" && <PracticeRoomTab />}
    </div>
  );
}

export function AboutTab() {
  // State for Part 1 collapsible sections
  const [openSectionsPart1, setOpenSectionsPart1] = useState(
    Array(8).fill(false)
  );

  // Toggle function for Part 1
  function toggleSectionPart1(idx: number) {
    setOpenSectionsPart1((prev) =>
      prev.map((isOpen, i) => (i === idx ? !isOpen : isOpen))
    );
  }

  // State for Part 2 collapsible sections
  const [openSectionsPart2, setOpenSectionsPart2] = useState(
    Array(6).fill(false)
  );

  // Toggle function for Part 2
  function toggleSectionPart2(idx: number) {
    setOpenSectionsPart2((prev) =>
      prev.map((isOpen, i) => (i === idx ? !isOpen : isOpen))
    );
  }

  // Part 1 panel data (after the first paragraph)
  const panelDataPart1 = [
    {
      title: "FlowFrame: A Groundbreaking Metronome",
      content: (
        <p className="mb-4">
          FlowFrame is a groundbreaking metronome and practice environment
          designed to meet the evolving needs of today’s musicians—students,
          professionals, instructors, and performers of every genre. By blending
          intuitive controls with deep customization, FlowFrame goes far beyond
          the traditional “tick-tock” to support the most demanding practice
          goals, helping you focus on the music rather than the mechanics.
        </p>
      ),
    },
    {
      title: "1. Layered Time & Polyrhythms",
      content: (
        <p className="mb-4">
          Tackle complex rhythms head-on with layered click tracks that
          independently manage tempo changes, odd meters, and polyrhythms. This
          lets you rehearse nuanced passages in everything from classical
          ensemble repertoire to jazz improvisation and progressive rock—all
          within one streamlined tool.
        </p>
      ),
    },
    {
      title: "2. Adaptive Tempo & Dynamic Accents",
      content: (
        <p className="mb-4">
          FlowFrame can gradually speed up or slow down a passage to help you
          build muscle memory and accuracy over time. Define accent patterns
          within a bar—perfect for emphasizing the swing in a jazz line or
          shaping a phrase in a string quartet. These “tempo ramps” and
          fine-tuned accents give you more control than ever over your tempo
          map.
        </p>
      ),
    },
    {
      title: "3. Practice Tools & Progress Tracking",
      content: (
        <p className="mb-4">
          Keep track of your daily practice sessions with built-in session
          logging. Mark tricky sections, measure your progress, and store custom
          presets so you can revisit or modify them later. This level of detail
          helps you or your students focus on consistent, mindful improvement.
        </p>
      ),
    },
    {
      title: "4. Sound Customization & Multi-Device Support",
      content: (
        <p className="mb-4">
          Swap out the click sound for something that suits your instrument or
          musical style. Create distinct tracks for different sections of your
          piece, or use multiple devices synced together—ideal for rehearsals
          and group practice in large ensembles or workshop settings.
        </p>
      ),
    },
    {
      title: "5. Collaborative Features",
      content: (
        <p className="mb-4">
          Share tempo patterns, piece-specific presets, or complex metrical
          studies with colleagues and students. Instructors can push real-time
          tempo changes or new polyrhythm challenges to their entire class,
          ensuring everyone is on the same page—literally and figuratively.
        </p>
      ),
    },
    {
      title: "6. Ease of Use & Versatility",
      content: (
        <p className="mb-4">
          FlowFrame’s interface is accessible enough for a middle school band
          student while remaining powerful for advanced university-level
          practice rooms and professional orchestras. Jazz improvisers love the
          ability to manage shifting harmonies, while classical musicians
          appreciate the precision and reliability during solo and ensemble
          work.
        </p>
      ),
    },
    {
      title: "Conclusion",
      content: (
        <p>
          Whether you’re an aspiring vocalist, a seasoned orchestral player, a
          gigging jazz artist, or someone exploring new rhythmic landscapes,
          FlowFrame is built to be the ultimate tempo and pitch partner—capable
          of evolving with your needs and supporting your personal approach to
          music-making. Let FlowFrame handle the mechanics of tempo, so you can
          focus on bringing your musical ideas to life.
        </p>
      ),
    },
  ];

  // Part 2 panel data (instructions)
  const panelDataPart2 = [
    {
      title: "1) Basic Use: Just Press Start",
      content: (
        <div>
          <p className="mb-2">
            For a simple metronome, set your desired BPM and press Start. The
            metronome will click on each beat according to your chosen time
            signature.
          </p>
          <p className="text-sm text-gray-600">
            Ideal for: Basic tempo practice, counting out loud, or warming up on
            scales.
          </p>
        </div>
      ),
    },
    {
      title: "2) Incremental Tempo Change",
      content: (
        <div>
          <p className="mb-2">
            Enable <strong>Tempo Changes</strong> and choose{" "}
            <em>Incremental</em> mode. Set your start and end tempo (e.g., 60 →
            120 BPM), and decide how many measures or loops you want before
            stepping up the tempo. This helps you gradually practice faster
            passages while building muscle memory.
          </p>
          <p className="mb-2">
            <strong>Tip:</strong> Use “whole pattern” increments for global
            stepping, or choose “specific bars” for local loops on a tricky
            section.
          </p>
        </div>
      ),
    },
    {
      title: "3) Mute Random Beats to Internalize Pulse",
      content: (
        <div>
          <p className="mb-2">
            Toggle <strong>Mute Random Beats</strong> and set the percentage.
            After a few measures, FlowFrame randomly drops clicks so you have to
            maintain the beat internally.
          </p>
          <p className="text-sm text-gray-600">
            This technique is great for developing strong internal time, even
            when the beat isn’t always audible.
          </p>
        </div>
      ),
    },
    {
      title: "4) Practicing Phrasing with Stress Patterns & Dynamics",
      content: (
        <div>
          <p className="mb-2">
            Use <strong>Stress Patterns</strong> (toggle in the hamburger menu)
            to automatically accent downbeats. You can also manually set{" "}
            <em>pp, mf, ff</em> in the <em>Dynamic Markings</em> for each
            measure in the Pattern Overview. Add cresc. or dim. to shape your
            phrase across measures.
          </p>
          <p className="text-sm text-gray-600">
            Perfect for shaping musical lines, not just keeping a mechanical
            beat.
          </p>
        </div>
      ),
    },
    {
      title: "5) Use Chord & Drone Features to Practice Pitch",
      content: (
        <div>
          <p className="mb-2">
            Assign a pitch (C, D, E, etc.) or chord (Major, Minor, etc.) to
            certain beats. FlowFrame will sustain them, helping you check
            intonation or practice melody lines against a constant drone.
          </p>
          <p className="text-sm text-gray-600">
            Use “Just Intonation” to train your ear to pure intervals, or keep
            it in equal temperament. Great for working on pitch accuracy.
          </p>
        </div>
      ),
    },
    {
      title: "6) Create Patterns That Modulate by Half-Step",
      content: (
        <div>
          <p className="mb-2">
            Build a loop of measures that automatically moves your chord root up
            a semitone each cycle. This will let you practice scales or
            arpeggios in every key while gradually ascending into higher
            registers—perfect for building range and key flexibility.
          </p>
          <p className="text-sm text-gray-600">
            Combine this with incremental tempo changes to get faster each time
            you go up a half step.
          </p>
        </div>
      ),
    },
  ];

  return (
    <div style={{ padding: "1rem" }}>
      {/* Part 1: Title & First Paragraph */}
      <div className="text-center mb-3">
        <img
          src={flowFrameLogo}
          alt="FlowFrame Logo"
          className="inline-block w-48"
        />
      </div>

      <h2 className="text-2 mb-4 text-center">Empowering Confident Artistry</h2>
      <h1 className="text-2xl font-bold mb-2 text-center">About FlowFrame</h1>
      <p className="mb-4">
        Musical confidence starts with two vital pillars: pitch and pulse.
        FlowFrame is a tool that helps you hone your sense of rhythm and improve
        your intonation, giving you the freedom to express yourself
        authentically. Let FlowFrame guide you as you grow, thrive, and truly
        flow with the music. Enjoy your journey!
      </p>

      {/* Link to jump to Part 2 Instructions */}
      <div className="text-center mb-6">
        <a
          href="#part2-instructions"
          className="underline text-blue-600 hover:text-blue-800"
        >
          Jump to Instructions
        </a>
      </div>

      {/* Collapsible sections for the rest of Part 1 */}
      {panelDataPart1.map((panel, idx) => (
        <div key={idx} className="mb-4 border border-gray-300 rounded">
          <button
            type="button"
            onClick={() => toggleSectionPart1(idx)}
            className="w-full flex items-center justify-between p-2 bg-gray-100 hover:bg-gray-200"
          >
            <span className="font-medium">{panel.title}</span>
            {openSectionsPart1[idx] ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
          </button>
          {openSectionsPart1[idx] && (
            <div className="p-3 text-sm bg-white text-gray-800">
              {panel.content}
            </div>
          )}
        </div>
      ))}

      {/* Part 2 Instructions at the bottom */}
      <div id="part2-instructions" className="mt-12">
        <h1 className="text-2xl font-bold mb-4 text-center">
          Instructions for Use
        </h1>
        <p className="mb-6 text-center text-gray-700">
          These quick tips will guide you from basic usage to advanced practice
          techniques.
        </p>

        {panelDataPart2.map((panel, idx) => (
          <div key={idx} className="mb-4 border border-gray-300 rounded">
            <button
              type="button"
              onClick={() => toggleSectionPart2(idx)}
              className="w-full flex items-center justify-between p-2 bg-gray-100 hover:bg-gray-200"
            >
              <span className="font-medium">{panel.title}</span>
              {openSectionsPart2[idx] ? (
                <ChevronUp className="w-4 h-4" />
              ) : (
                <ChevronDown className="w-4 h-4" />
              )}
            </button>
            {openSectionsPart2[idx] && (
              <div className="p-3 text-sm bg-white text-gray-800">
                {panel.content}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
